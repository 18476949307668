import SOFAScore from "../../components/SOFAScore/SOFAScore";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { updateClinicalScore } from "../../helper/clinical-checks";

function SOFAScorePage(props) {
    const params = useParams();
    const [bedId, setBedId] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [confirmScreen, setConfirmScreen] = useState(false);
    const [score, setScore] = useState(null);



    useEffect(() => {
        if (params.id) {
            setBedId(params.id);
            if (props.patientData[params.id]) setSessionId(props.patientData[params.id].session_id);
        }
    }, [])

    const handleSubmitClick = (score) => {
        setConfirmScreen(true);
        setScore(score);
    }
    const handleCancelClick = () => {
        setConfirmScreen(false)
    }
    const handleConfirmClick = () => {
        let dataObj = {
            bedId: bedId,
            patientSessionId: sessionId,
            date: "2022/08/23",
            gcs: null,
            sofa: score
        }
        updateClinicalScore(dataObj).then((res) => {
            console.log("the result of updation gcs score", res);

        }).catch((err) => {
            console.log("some error occured", err)
        })
        setConfirmScreen(false)
    }
    return (
        <>
            <SOFAScore handleSubmitClick={(score) => handleSubmitClick(score)} />
            {confirmScreen &&
                <div className="confirm-modal-container">
                    <div className="confirm-message">Are you sure you want to submit the scores?</div>
                    <div className="confirm-btn-container">
                        <div className="common-btn" onClick={handleCancelClick}>Cancel</div>
                        <div className="common-btn default-btn" onClick={handleConfirmClick}>Confirm</div>
                    </div>

                </div>}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {

    let patientData = state.patientDataReducer.patientData;
    return {
        patientData: patientData,
        config: state.configReducer.config
    };
};
export default connect(mapStateToProps)(SOFAScorePage);
