import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import TimePicker from 'react-time-picker';
import trendGraphSvg from "../../common/icons/svg/trend-graph.svg";
import trendGraphSelectedSvg from "../../common/icons/svg/trend-graph-selected.svg";
import trendTableSvg from "../../common/icons/svg/trend-table.svg";
import trendTableSelectedSvg from "../../common/icons/svg/trend-table-selected.svg";
import { Line } from "react-chartjs-2";
import "./TrendContainer.scss";
import { connect } from "react-redux";
import { CHART_HEADER_CONST, VITAL_OPTION, parameterSorting, parameterOptionList, durationOptionList } from "../../common/constant/common-constants";
import { ChartTable } from "../../components/Table/Chart/ChartTable";
import moment from "moment";
import { getTrendData } from "../../helper/report-helper";
import TrendChart from "../../components/Table/TrendChart/TrendChart";

function TrendContainer(props) {

    const [selectedParamList, setSelectedParamList] = useState([]);
    const [duration, setDuration] = useState(null);
    const [trendStartDate, setTrendStartDate] = useState(new Date());
    const [trendEndDate, setTrendEndDate] = useState(new Date());
    const [trendStartTime, setTrendStartTime] = useState(moment(new Date()).format("HH:mm"));
    const [trendEndTime, setTrendEndTime] = useState(moment(new Date()).format("HH:mm"));
    const [interval, setInterval] = useState(null);
    const [data, setData] = useState(null);
    const [trendOption, setTrendOption] = useState(VITAL_OPTION.VITAL_TABLE);
    const [isGenerateReportVisible, setIsGenerateReportVisible] = useState(false);
    const [headerList, setHeaderList] = useState(
        [CHART_HEADER_CONST.PARAMETERS, "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00"]
    );
    const [bedId, setBedId] = useState(null);

    useEffect(() => {

        if (bedId !== props.bedId) {
            setData(null)
            setSelectedParamList([]);
            setDuration([]);
            setTrendStartDate(new Date());
            setTrendEndDate(new Date());
            setTrendStartTime(moment(new Date()).format("HH:mm"));
            setTrendEndTime(moment(new Date()).format("HH:mm"));
            setInterval(null);
            setIsGenerateReportVisible(false)

            setBedId(props.bedId);
        }
        console.log("new bed Id !!!");
    }, [props.bedId])

    const setParameter = (data) => {

        data.sort(function (a, b) {
            return parameterSorting.indexOf(a.id) - parameterSorting.indexOf(b.id);
        });

        console.log("trend container param select :: " + JSON.stringify(data));
        setSelectedParamList(data);
    }

    const handleTrendShow = (option) => {
        setTrendOption(option);
    }

    useEffect(() => {

        setIsGenerateReportVisible(false);
        if (trendStartDate && trendStartTime && trendEndDate && trendEndTime && interval && selectedParamList) {
            setIsGenerateReportVisible(true);
        }

    }, [trendStartDate, trendStartTime, trendEndDate, trendEndTime, interval, selectedParamList])

    const onGenerateReport = () => {

        let data = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            startDate: moment(trendStartDate).format("YYYY-MM-DD"),
            startTime: trendStartTime,
            endDate: moment(trendEndDate).format("YYYY-MM-DD"),
            endTime: trendEndTime,
            timeInterval: interval && interval.name
        }

        setIsGenerateReportVisible(false);
        getTrendData(data)
            .then((res) => {
                setIsGenerateReportVisible(true);
                console.log(res);
                setData(res);

                let headerList = res.headerList || [];
                headerList.unshift(CHART_HEADER_CONST.PARAMETERS)

                setHeaderList(headerList);

            })
            .catch((err) => {
                setIsGenerateReportVisible(true);
                console.log(err);
            })
    }

    return (
        <div className="trend-container">
            <div className="generate-report-form">
                {/* <div className="report-heading">
                    Generate Report
                </div> */}
                <div className="report-input-fields">
                    <div className="parameter-select-container form-input">
                        <div className="parameter label">Parameters</div>
                        <CustomSelect
                            className="parameter-name trend-input"
                            handleChange={(data) => setParameter(data)}
                            value={selectedParamList}
                            label='label'
                            options={parameterOptionList}
                            placeholder='Parameter Name'
                            isMulti={true}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    {/* <div className="duration-select-container form-input">
                        <div className="duration label">Duration</div>
                        <CustomSelect
                            className="duration trend-input"
                            handleChange={(item) => setDuration(item)}
                            label='duration'
                            options={durationOptionList}
                            placeholder="Duration"
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div> */}
                    <div className="trend-date-container form-input">
                        <div className="date label">Start Date</div>
                        <div className="date-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <DatePicker
                                className="trend-date"
                                onChange={(date) => setTrendStartDate(date)}
                                value={trendStartDate}
                                clearIcon={false}
                            />

                        </div>
                    </div>
                    <div className="trend-time-container start-time form-input">
                        <div className="date label">Start Time</div>
                        <div className="time-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <TimePicker
                                className="trend-time"
                                onChange={(date) => setTrendStartTime(date)}
                                value={trendStartTime}
                                clearIcon={false}
                                disableClock={true}
                            />
                        </div>
                    </div>
                    <div className="interval-select-container form-input">
                        <div className="interval label">Time Interval</div>
                        <CustomSelect
                            className="interval trend-input"
                            handleChange={(item) => setInterval(item)}
                            label='label'
                            options={durationOptionList}
                            placeholder="Interval"
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="trend-date-container form-input">
                        <div className="date label">End Date</div>
                        <div className="date-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <DatePicker
                                className="trend-date"
                                onChange={(date) => setTrendEndDate(date)}
                                value={trendEndDate}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div className="trend-time-container form-input">
                        <div className="date label">End Time</div>
                        <div className="time-container trend-input">
                            {/* <div className="date-text">Date: </div> */}
                            <TimePicker
                                className="trend-time"
                                onChange={(date) => setTrendEndTime(date)}
                                value={trendEndTime}
                                clearIcon={false}
                                disableClock={true}
                            />
                        </div>
                    </div>
                    <div className="submit-button-container">
                        <div
                            className={isGenerateReportVisible ? "submit-button" : "submit-button button-disabled"}
                            onClick={() => onGenerateReport()}>Generate Report</div>
                    </div>
                </div>
            </div>
            <div className="trend-data-container">
                <div className="trend-data-header">
                    <div className="report-text">Report</div>
                    <div className="change-trend-container">
                        <img
                            src={trendOption === VITAL_OPTION.VITAL_TABLE ? trendTableSelectedSvg : trendTableSvg}
                            className="trend-change-icon"
                            onClick={() => handleTrendShow("VITAL_TABLE")} />
                        <img
                            src={trendOption === VITAL_OPTION.VITAL_GRAPH ? trendGraphSelectedSvg : trendGraphSvg}
                            className="trend-change-icon"
                            onClick={() => handleTrendShow("VITAL_GRAPH")} />
                    </div>
                </div>
                {(trendOption === VITAL_OPTION.VITAL_TABLE) && data && <div className="trend-table-container">
                    {selectedParamList && selectedParamList.length > 0 && <TrendChart
                        headerList={headerList}
                        parameterList={selectedParamList}
                        data={data}
                    />}
                </div>}
                {(trendOption === VITAL_OPTION.VITAL_GRAPH) && data && <div className="trend-graph-container">

                    {selectedParamList && (selectedParamList.length > 0) && selectedParamList.map((graphParam) => {
                        let xgraphData = [data.data.map((ele) => {
                            return ele["time"] && moment(ele["time"]).format("YYYY-MM-DD, HH:mm");
                        })]
                        let ygraphData = [data.data.map((ele) => {
                            return ele[graphParam.name]
                        })]
                        return (
                            <Line
                                className="line-graph-vital"
                                key={graphParam.name}
                                data={{
                                    labels: xgraphData[0],
                                    datasets: [{
                                        data: ygraphData[0],
                                        fill: false,
                                        borderColor: 'white',
                                        borderWidth: 2,
                                        tension: 0,
                                        pointRadius: 1.5,
                                    }],
                                }}
                                options={{
                                    // maintainAspectRatio: false,
                                    plugins: {
                                        tooltip: {
                                            enabled: true,
                                            position: 'nearest',
                                        },
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: graphParam.label,
                                            align: 'start',
                                            color: "white",
                                            font: {
                                                size: 16,
                                                weight: 600,
                                            },
                                            padding: {
                                                bottom: 20,
                                            }
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                borderColor: '#4D4D4D'
                                            }
                                        },
                                        y: {
                                            grid: {
                                                borderColor: '#4D4D4D'
                                            }
                                        }
                                    }
                                }}
                                height={"80px"}
                            />
                        )
                    })}
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {

    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];
    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;

    return {
        patientData: pData,
        patientSessionId: patientSessionId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendContainer);