import "./GCSScore.scss";
import { Header } from "../Header/Header";
import { SideBar } from "../SideBar/Sidebar";
import redRating from "../../common/icons/svg/red-rating.svg";
import yellowRating from "../../common/icons/svg/yellow-rating.svg";
import greenRating from "../../common/icons/svg/green-rating.svg";
import React, { useState, useEffect } from 'react';

function GCSScore(props) {
    const [eyeResponseRating, setEyeResponseRating] = useState(0);
    const [verbalResponseRating, setVerbalResponseRating] = useState(0);
    const [motorResponseRating, setMotorResponseRating] = useState(0);
    const [totalScore, setTotalScore] = useState(0);

    const handleMotorResponseRadio = (rating) => {
        console.log("this is the current motor response rating", rating)
        setMotorResponseRating(rating);
    }
    const handleVerbalResponseRadio = (rating) => {
        console.log("this is the current verbal response rating", rating)
        setVerbalResponseRating(rating);
    }
    const handleEyeResponseRadio = (rating) => {
        console.log("this is the current eye response rating", rating);
        setEyeResponseRating(rating);
    }
    useEffect(() => {
        setTotalScore(eyeResponseRating + verbalResponseRating + motorResponseRating)

    }, [motorResponseRating, eyeResponseRating, verbalResponseRating])

    return (
        <div className="gcs-score-container">
            <Header
            // handleWardChange={(item) => setSelectedWard(item)}
            // wardOption={wardOption}
            // selectedWard={selectedWard} 
            />
            <SideBar />
            <div className="gcs-score-body">
                <div className="gcs-heading-container">
                    <span className="heading">GCS</span>
                    <div className={`show-score
                                        ${totalScore <= 3 ? "red-score" : ""} 
                                        ${(totalScore <= 8) && (totalScore > 3) ? "yellow-score" : ""}
                                        ${totalScore > 8 ? "green-score" : ""}
                                         `}>Score: {totalScore}</div>
                </div>
                <div className="gcs-rating-container">
                    <div className="common-rating-cls">
                        <img className="rating-color" src={redRating} />
                        <span>Totally unresponsive: 3</span>
                    </div>
                    <div className="common-rating-cls">
                        <img className="rating-color" src={yellowRating} />
                        <span>Comatose Client: 8 or less</span>
                    </div>
                    <div className="common-rating-cls">
                        <img className="rating-color" src={greenRating} />
                        <span>Best Responsive: 15</span>
                    </div>
                </div>
                <div className="gcs-mcq-container">
                    <div className="common-mcq">
                        <div className="mcq-heading">Best Eye Response</div>
                        <div className="mcq-score">{eyeResponseRating}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="spontaneous" name="eye-response" onClick={() => handleEyeResponseRadio(4)} />
                                <label htmlFor="spontaneous">Spontaneously (+4)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="speech" name="eye-response" onClick={() => handleEyeResponseRadio(3)} />
                                <label htmlFor="speech">To Speech (+3)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="pain" name="eye-response" onClick={() => handleEyeResponseRadio(2)} />
                                <label htmlFor="pain">To Pain (+2)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="no-response-1" name="eye-response" onClick={() => handleEyeResponseRadio(1)} />
                                <label htmlFor="no-response-1">No response (+1)</label>
                            </div>

                        </div>
                    </div>
                    <div className="common-mcq">
                        <div className="mcq-heading">Best Verbal Response</div>
                        <div className="mcq-score">{verbalResponseRating}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="orientation" name="verbal-response" onClick={() => handleVerbalResponseRadio(5)} />
                                <label htmlFor="orientation">Oriented to time, place, person(+5)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="confused" name="verbal-response" onClick={() => handleVerbalResponseRadio(4)} />
                                <label htmlFor="confused">Confused(+4)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="inappropriate-words" name="verbal-response" onClick={() => handleVerbalResponseRadio(3)} />
                                <label htmlFor="inappropriate-words">Inappropriate Words (+3)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="inappropriate-sounds" name="verbal-response" onClick={() => handleVerbalResponseRadio(2)} />
                                <label htmlFor="inappropriate-sounds">Inappropiate Sounds (+2)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="no-response-2" name="verbal-response" onClick={() => handleVerbalResponseRadio(1)} />
                                <label htmlFor="no-response-2" >No response (+1)</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-mcq">
                        <div className="mcq-heading">Best Motor Response</div>
                        <div className="mcq-score">{motorResponseRating}</div>
                        <div className="mcq-options-common">
                            <div className="radio-opt">
                                <input type="radio" id="obeys-command" name="motor-response" onClick={() => handleMotorResponseRadio(6)} />
                                <label htmlFor="obeys-command">Obeys Commands (+6)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="localize-pain" name="motor-response" onClick={() => handleMotorResponseRadio(5)} />
                                <label htmlFor="localize-pain">Moves to localize pain (+5)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="pain-withdrawal" name="motor-response" onClick={() => handleMotorResponseRadio(4)} />
                                <label htmlFor="pain-withdrawal">Flexion withdrawel from pain (+4)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="abnormal-flex" name="motor-response" onClick={() => handleMotorResponseRadio(3)} />
                                <label htmlFor="abnormal-flex">Abnormal Flexion (Decorticate) (+3)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="abnormal-ext" name="motor-response" onClick={() => handleMotorResponseRadio(2)} />
                                <label htmlFor="abnormal-ext">Abnormal Extension (Decerebrate) (+2)</label>
                            </div>
                            <div className="radio-opt">
                                <input type="radio" id="no-response-3" name="motor-response" onClick={() => handleMotorResponseRadio(1)} />
                                <label htmlFor="no-response-3">No response (+1)</label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="gcs-total-rating-container">
                    <span>Total GCS Score</span>
                    <div className="total-rating">{totalScore}</div>
                </div>
                <div className="submit-btn-container">
                    <div className="submit-btn" onClick={() => props.handleSubmitClick(totalScore)}>Submit</div>
                </div>


            </div>
        </div >
    )
}
export default GCSScore;
