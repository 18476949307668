import moment from "moment";
import React from "react";
import { PRESCRIPTION_OPTIONS } from "../../../common/constant/common-constants";
import "./PrescriptionTable.scss";
export function PrescriptionTable(props) {

    const HEADER_LIST = ["ACTION", "DRUG NAME", "DOSAGE", "ROUTE", "FREQUENCY", "REMARKS"];
    const ACTIONS = {
        DEFAULT: "DEFAULT",
        STOP: "STOP",
        CONTINUE: "CONTINUE"
    }

    const handleChange = (parameter, data, index, newData) => {
        // console.log("row: " + JSON.stringify(row) + " ,data: " + data + " ,index: " + index + " ,newData: " + newData);

        props.handleChange(parameter, data, index, newData);
    }

    const handleActionClick = (id, action) => {

        let data = {
            id: id,
            action: action
        }

        props.handleUpdatePrescription(data);
    }

    return (
        <div className="prescription-table-container">
            <div className="prescription-table-header">
                <div className="prescription-heading-text">
                    {props.date ? `Medication for ${moment(props.date).format("MM/DD/YYYY")}` :
                        'Please select a date to view Medications'}
                </div>
            </div>
            {props.data && props.data.length > 0 && <table className="prescription-table">
                <thead >
                    <tr>
                        {[PRESCRIPTION_OPTIONS.DRUGS, PRESCRIPTION_OPTIONS.STAT_ORDERS].includes(props.type)
                            && HEADER_LIST.map((header, count) => {
                                return <th key={count} className="prescription-drug-header">
                                    {header}
                                </th>
                            })}
                    </tr>
                    {
                        PRESCRIPTION_OPTIONS.INFUSION === props.type &&
                        <>
                            <tr>
                                <th rowSpan="2" >ACTION</th>
                                <th rowSpan="2" >INFUSION NAME</th>
                                <th rowSpan="2" >VOLUME</th>
                                <th colSpan="2" >ADDITIVES</th>
                                <th rowSpan="2" >DURATION</th>
                                <th rowSpan="2" >FREQUENCY</th>
                                <th rowSpan="2" >RATE</th>
                                <th rowSpan="2" >REMARKS</th>
                            </tr>
                            <tr>
                                <th scope="col">NAME</th>
                                <th scope="col">DOSAGE</th>
                            </tr>
                        </>
                    }
                </thead >
                <tbody >
                    {
                        [PRESCRIPTION_OPTIONS.DRUGS, PRESCRIPTION_OPTIONS.STAT_ORDERS].includes(props.type) &&
                        props.data.map((row, count) => {
                            return (
                                <tr key={count}>
                                    <td className="start-stop-container">
                                        {[ACTIONS.CONTINUE, ACTIONS.DEFAULT].includes(row.action) &&
                                            <div className="stop-button action-button"
                                                onClick={() => handleActionClick(row.id, ACTIONS.STOP)}>
                                                STOP
                                            </div>}
                                        {[ACTIONS.STOP, ACTIONS.DEFAULT].includes(row.action) &&
                                            <div className="start-button action-button"
                                                onClick={() => handleActionClick(row.id, ACTIONS.CONTINUE)}>
                                                CONTINUE
                                            </div>}
                                    </td>
                                    {
                                        <>
                                            <td>
                                                {row.name}
                                            </td>
                                            <td>
                                                {row.dosage}
                                            </td>
                                            <td>
                                                {row.route}
                                            </td>
                                            <td>
                                                {row.frequency}
                                            </td>
                                            <td>
                                                {row.remarks}
                                            </td>
                                        </>
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        PRESCRIPTION_OPTIONS.INFUSION === props.type &&
                        props.data.map((row, count) => {

                            let rowSpan = row.additives && row.additives.length;
                            return (
                                <>
                                    <tr key={count}>
                                        {
                                            <>
                                                <td
                                                    rowSpan={rowSpan || 1} >
                                                    <div className="start-stop-container">
                                                        {[ACTIONS.CONTINUE, ACTIONS.DEFAULT].includes(row.action) &&
                                                            <div className="stop-button action-button"
                                                                onClick={() => handleActionClick(row.id, ACTIONS.STOP)}>
                                                                STOP
                                                            </div>}
                                                        {[ACTIONS.STOP, ACTIONS.DEFAULT].includes(row.action) &&
                                                            <div className="start-button action-button"
                                                                onClick={() => handleActionClick(row.id, ACTIONS.CONTINUE)}>
                                                                CONTINUE
                                                            </div>}
                                                    </div>
                                                </td>
                                                <td rowSpan={rowSpan || 1}>
                                                    {row.name}
                                                </td>
                                                <td rowSpan={rowSpan || 1}>
                                                    {row.volume}
                                                </td>
                                                <td >
                                                    {
                                                        (rowSpan && row.additives[0].name) || "-"
                                                    }
                                                </td>
                                                <td >
                                                    {
                                                        (rowSpan && row.additives[0].dosage) || "-"
                                                    }
                                                </td>
                                                <td rowSpan={rowSpan || 1}>
                                                    {row.duration}
                                                </td>
                                                <td rowSpan={rowSpan || 1}>
                                                    {row.frequency}
                                                </td>
                                                <td rowSpan={rowSpan || 1}>
                                                    {row.rate}
                                                </td>
                                                <td rowSpan={rowSpan || 1}>
                                                    {row.remarks}
                                                </td>
                                            </>
                                        }
                                    </tr>
                                    {
                                        <>
                                            {row.additives.map((additive, count) => {
                                                if (count > 0) {
                                                    return <tr>
                                                        <td >
                                                            {additive.name}
                                                        </td>
                                                        <td >
                                                            {additive.dosage}
                                                        </td>
                                                    </tr>
                                                }
                                            })}
                                        </>
                                    }
                                </>
                            )
                        })
                    }
                </tbody>
            </table>}
            {
                props.data && props.data.length <= 0 && <div className="empty-data-message">No Medication added for the selected Date</div>
            }
        </div >
    );
}