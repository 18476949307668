
import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

// let initialState = {
//     wardData: {}
// }

export const wardDetailsReducer = (state = initialState.wardDetailsReducer, action) => {
    switch (action.type) {
        case Types.WARD_DATA:
            {
                let wardId = action.payload.id;
                let data = action.payload.data;

                let wData = state.wardData;
                wData[wardId] = data;

                return {
                    ...state,
                    wardData: { ...wData }
                };
            }
        case Types.SELECTED_WARD:
            {
                const selectedWard = action.payload;
                return {
                    ...state,
                    selectedWard: selectedWard,
                }
            }
        default:
            return state;
    }
}