import { BED_DETAILS_MODULE, DEVICE_TYPES, FILTER_TYPES, VIEW_TYPES } from "../../common/constant/common-constants";
import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

export const configReducer = (state = initialState.configReducer, action) => {
    switch (action.type) {
        case Types.CONFIG_VIEW_TYPE:
            {
                const viewType = action.payload.viewType;
                return {
                    ...state,
                    config: {
                        ...state.config,
                        viewType: viewType
                    }
                };
            }
        case Types.CONFIG_FILTER_TYPE:
            {
                const filterType = action.payload.filterType;
                return {
                    ...state,
                    config: {
                        ...state.config,
                        filterType: filterType
                    }
                };
            }
        case Types.CONFIG_MODAL:
            {
                // const isOpen = action.payload.isOpen;
                // const optionType = action.payload.optionType;

                return {
                    ...state,
                    config: {
                        ...state.config,
                        modalConfig: {
                            ...state.config.modalConfig,
                            ...action.payload
                        }
                    }
                }
            }
        case Types.CONFIG_BED_DETAILS:
            {
                return {
                    ...state,
                    config: {
                        ...state.config,
                        bedDetailConfig: {
                            ...state.config.bedDetailConfig,
                            ...action.payload
                        }
                    }
                }
            }
        case Types.CONFIG_ADD_COLUMN:
            {
                let column = action.payload;
                let listViewParameters = state.config.listViewParameters || [];

                if (column) {
                    const index = listViewParameters.findIndex(param => {
                        return (param.name === column.name)
                    });

                    if (index < 0) listViewParameters.push(column);
                }

                return {
                    ...state,
                    config: {
                        ...state.config,
                        listViewParameters: [
                            ...listViewParameters
                        ]
                    }
                }
            }
        case Types.CONFIG_REMOVE_COLUMN:
            {
                let column = action.payload;
                let listViewParameters = state.config.listViewParameters || [];

                if (column) {
                    const index = listViewParameters.findIndex(param => {
                        return (param.name === column.name)
                    });

                    if (index > -1) {
                        listViewParameters.splice(index, 1);
                    }
                }

                return {
                    ...state,
                    config: {
                        ...state.config,
                        listViewParameters: [
                            ...listViewParameters
                        ]
                    }
                }
            }


        default:
            return state;
    }
}