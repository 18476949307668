import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import ApexCharts from 'apexcharts'
import { getApexChartOptions } from "../../common/utils/apex-chart-options";

const tempData = [{ "name": "xx", "data": [[{ "x": '1632738489051', "y": "881" }, { "x": '1632738492028', "y": "891" }]] }];

function ApexChart(props) {
    const [lastTime, setLastTime] = useState(null);

    let chart;

    const [graphData, setGraphData] = useState(() => {
        return {
            name: props.name,
            data: []
        }
    });

    let timeout = null;

    useEffect(() => {
        if (props && props.dataList
            //  && props.dataList.length > 0
        ) {

            let updatedData = [...props.dataList];
            setGraphData({
                name: props.name,
                data: updatedData
            })
        }
    }, [props.dataList]);

    return (
        <ReactApexChart type="line" id={props.name} key={props.name} options={getApexChartOptions(props)} series={[graphData]} width={props.width} height={props.height} />
    );
};

export default ApexChart;

