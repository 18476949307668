import React, { useEffect, useState } from "react";

import { CHART_OPTIONS, CHART_TABLE_OPTIONS, getChartHeader, getData, getParameterList, PAGE_PARENT } from "../../common/constant/common-constants";

import clockSvg from "../../common/icons/svg/clock.svg";
import crossSvg from "../../common/icons/svg/cross.svg";
import downloadSvg from "../../common/icons/svg/download.svg";
// import down_arrow_svg from "../../common/icons/svg/down-arrow.svg";
import { useNavigate } from "react-router-dom";
import { redirect } from "../../common/utils/RedirectionUtils";

import "./ChartContainer.scss";
import DatePicker from "react-date-picker";
import { getChartData, updateChartData, getChartTimeline } from "../../helper/chart-helper";
import { ChartTable } from "../../components/Table/Chart/ChartTable";
import moment from "moment";
import { getDateForChart } from "../../common/utils/DateUtils";
import { connect } from "react-redux";
import { CHART_CONST, PAGE_CONST, CLINICAL_SCORE } from "../../common/constant/page-constants";
import { DrugAdminstration } from "../../components/Table/DrugAdministration/DrugAdministration";
import { IoBalance } from "../../components/Table/IoBalance/IoBalance";
import { ClinicalScores } from "../../components/Table/ClinicalScores/ClinicalScores";

function ChartContainer(props) {
    // console.log("props", props)

    const navigate = useNavigate();

    const [activeOption, setActiveOption] = useState(CHART_OPTIONS.VITALS);
    const [tableData, setTableData] = useState({});
    const [prescriptionDate, setPrescriptionDate] = useState(getDateForChart());
    const [headerList, setHeaderList] = useState(getChartHeader(activeOption));
    const [parameterList, setParameterList] = useState(getParameterList(activeOption));
    const [isEditable, setIsEditable] = useState(false);
    const [isPrevTimelineVisible, setIsPrevTimelineVisible] = useState(false);
    const [prescriptionData, setPrescriptionData] = useState([]);
    const [timelineData, setTimelineData] = useState([]);
    const [ioData, setIoData] = useState({})
    const [clinicalScore, setClinicalScore] = useState({});
    const [clinicalScoreModal, setClinicalScoreModal] = useState(false);

    useEffect(() => {
        // getChartDataHandler(activeOption);
        // chartTimelineHandler();
    }, []);

    useEffect(() => {
        getChartDataHandler(activeOption);
        chartTimelineHandler();
    }, [prescriptionDate])

    const handleOptionClick = (option) => {
        // console.log("option is set to", option);
        setActiveOption(option);
        getChartDataHandler(option);
    }

    // console.log("tableData", tableData);

    const handleDataChange = (id, header, key, value) => {
        let dataList = tableData.data;
        const col = dataList.filter((obj) => obj.id === id)[0];

        value = (value === "") ? null : value;
        if (col) {
            col[key] = value;
        } else {
            let change = {
                [key]: value
            }
            let tempData = {
                "id": id,
                "time": header,
                "isUpdated": false,
            }

            parameterList.map((param) => {
                tempData[param.name] = null
            })

            if (activeOption === CHART_OPTIONS.NURSING_CHECKS) tempData["shift"] = header;
            else tempData["time"] = header;

            tempData = {
                ...tempData,
                ...change
            }

            dataList.push(tempData);
        }

        setTableData({ ...tableData });
    }

    const handleDownloadClick = () => {

        console.log("download clicked !!");
    }

    const getChartingOptionHeading = () => {

        const html = Object.keys(CHART_OPTIONS).map(key =>

            <div
                className={activeOption === CHART_OPTIONS[key] ?
                    "charting-option selected-chart-option" : "charting-option"}
                onClick={() => {
                    handleOptionClick(CHART_OPTIONS[key])
                    // console.log("chart option", CHART_OPTIONS[key])
                    // console.log("key", key)

                }}
                key={key}
            >
                {CHART_OPTIONS[key]}
            </div>
        )
        return html;
    }

    const getChartDataHandler = (chartType) => {

        let hl = getChartHeader(chartType)
        setHeaderList([...hl]);

        let pl = getParameterList(chartType);
        setParameterList([...pl]);

        if (!prescriptionDate) return;
        let data = {
            chartType: chartType,
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            date: moment(prescriptionDate).format("YYYY/MM/DD")
        }

        getChartData(data).then((res) => {

            if (chartType === CHART_OPTIONS.DRUG_ADMINISTRATION) setPrescriptionData([...res]);
            else if (chartType === CHART_OPTIONS.I_O_BAL) setIoData({ ...res });
            else if (chartType === CHART_OPTIONS.CLINICAL_SCORES) setClinicalScore({ ...res })
            else setTableData({ ...res });
        }).catch((err) => {
            console.log("error occured in fetching chart data");
        });
    }

    const chartTimelineHandler = () => {

        let data = {
            date: moment(prescriptionDate).format("YYYY/MM/DD"),
            bedId: props.bedId,
            patientSessionId: props.patientSessionId
        }

        getChartTimeline(data)
            .then((res) => {
                if (res.timeline) setTimelineData(res.timeline);
            })
            .catch((err) => {
                console.log("timeline error :: " + err);
            })
    }

    const handleSubmitClick = () => {

        // if (activeOption === CHART_OPTIONS.I_O_BAL) {
        //     handleIOSubmit();
        //     return;
        // }

        console.log("on submit --->")
        console.log(tableData);
        console.log("chart submit button clicked !!!!! ");
        setIsEditable(false);

        let data = {
            chartType: activeOption,
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            date: moment(prescriptionDate).format("YYYY/MM/DD"),
            data: tableData.data
        }

        // removing id from the new data
        if (activeOption === CHART_OPTIONS.DRUG_ADMINISTRATION) {
            prescriptionData.map((prescription) => {
                let drugAdministrations = prescription.drugAdministrations;

                drugAdministrations.map((administration) => {

                    if (administration.id && administration.id.toString().indexOf(CHART_CONST.NEW_ID_PRED)) {
                        administration.id = null
                    }
                })
            })

            data.data = prescriptionData;
        } else if (activeOption === CHART_OPTIONS.I_O_BAL) {
            data = {
                chartType: activeOption,
                bedId: props.bedId,
                patientSessionId: props.patientSessionId,
                date: moment(prescriptionDate).format("YYYY/MM/DD"),
                data: ioData
            }
        } else {
            data.data && data.data.map((col) => {
                // console.log(col);
                if (col.id && col.id.toString().indexOf(CHART_CONST.NEW_ID_PRED) > -1) {
                    col.id = null;
                }
            });
        }

        console.log(data);

        updateChartData(data).then((res) => {

            console.log("getChartData res:: " + res);
            // if (activeOption === CHART_OPTIONS.DRUG_ADMINISTRATION) setPrescriptionData(res);

            setTableData({ ...res });

            getChartDataHandler(activeOption);
            chartTimelineHandler();
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleCancelClick = () => {

        getChartDataHandler(activeOption);
        setIsEditable(false);
    }

    const handleDateChange = (date) => {

        let nd = moment(date);
        setPrescriptionDate(date);
    }

    const handleChangeData = (data) => {

        setPrescriptionData([...data]);
    }

    const updateIoData = (data) => {

        setIoData({ ...data });
    }

    const handleIOSubmit = () => {

        console.log(ioData);
    }

    const handleClinicalScoreCalculate = () => {
        console.log("calculate was clicked");
        setClinicalScoreModal(!clinicalScoreModal);
    }

    const handleClinicalScoreOption = (option) => {
        const data = {
            bedId: props.bedId
        }
        redirect(navigate, option, data);
    }
    return (
        <div className="chart-container">
            {props.parent === PAGE_PARENT.PATIENT_MODAL && <div className="chart-header-container">
                <div className="charting-text">Charting</div>
                <div className="chart-download-date-container">
                    <div className="download-container">
                        <img src={downloadSvg} alt="download icon" className="download-icon" />
                        <div className="download-text"> Download</div>
                    </div>
                    <div className="date-container" onClick={() => handleDownloadClick()}>
                        {/* <div className="date-text">Date: </div> */}
                        <DatePicker
                            className="charting-date"
                            onChange={(pd) => handleDateChange(pd)}
                            value={prescriptionDate}
                            clearIcon={null}
                            openCalendarOnFocus={true}
                            format="dd-MM-yyyy"
                            showLeadingZeros={false}
                        />
                    </div>
                </div>
            </div>}
            <div className="charting-option-download-container">
                <div className="charting-option-container">
                    {
                        getChartingOptionHeading()
                    }
                </div>
                {props.parent === PAGE_PARENT.BED_DETAIL && <div className="chart-option-download-date-container">
                    <div className="download-container" onClick={() => handleDownloadClick()}>
                        <img src={downloadSvg} alt="download icon" className="download-icon" />
                        <div className="download-text"> Download</div>
                    </div>
                    <div className="date-container">
                        {/* <div className="date-text">Date: </div> */}
                        <DatePicker
                            format="YYYY-MM-DD"
                            className="charting-date"
                            clearIcon={null}
                            closeCalendar={false}
                            showLeadingZeros={false}
                            onChange={(pd) => handleDateChange(pd)}
                            value={prescriptionDate}
                        />
                    </div>
                </div>}
            </div>
            <div className="chart-data-container">
                {
                    clinicalScoreModal &&
                    <div className="clinical-modal-container">
                        <div className="clinical-modal-options">
                            <div className="common-clinical-cls default-select" onClick={() => handleClinicalScoreOption(CLINICAL_SCORE.GCS_SCORE)}>GCS</div>
                            <div className="common-clinical-cls" onClick={() => handleClinicalScoreOption(CLINICAL_SCORE.SOFA_SCORE)}>SOFA</div>
                        </div>
                    </div>
                }
                <div className="chart-vital-container">
                    <div className="data-table-container">
                        {CHART_TABLE_OPTIONS.includes(activeOption) && tableData &&
                            <ChartTable
                                headerList={headerList}
                                parameterList={parameterList}
                                isEditable={isEditable}
                                data={tableData}
                                chartType={activeOption}
                                handleDataChange={(id, header, key, value) => handleDataChange(id, header, key, value)}
                            />
                        }
                        {activeOption === CHART_OPTIONS.DRUG_ADMINISTRATION &&
                            <DrugAdminstration
                                headerList={headerList}
                                data={prescriptionData}
                                isEditable={isEditable}
                                handleChangeData={(data) => handleChangeData(data)}
                            />}
                        {activeOption === CHART_OPTIONS.I_O_BAL &&
                            <IoBalance
                                isEditable={isEditable}
                                data={ioData}
                                updateData={(data) => updateIoData(data)}
                            />
                        }
                        {activeOption === CHART_OPTIONS.CLINICAL_SCORES &&
                            <ClinicalScores
                                headerList={headerList}
                                parameterList={parameterList}
                                data={tableData}
                            />
                        }
                    </div>
                    <div className="footer-container">

                        <div className="timeline-container">
                            {timelineData && timelineData.length > 0 &&
                                <>
                                    <div className="prev-timeline-link">
                                        {isPrevTimelineVisible && <div className="prev-timeline">
                                            <div className="timeline-header">
                                                <div className="time">
                                                    Timeline: {moment(prescriptionDate).format("MM/DD/YYYY")}
                                                </div>
                                                <div className="cross">
                                                    <img
                                                        className="cross"
                                                        src={crossSvg}
                                                        alt="cross"
                                                        onClick={() => setIsPrevTimelineVisible(false)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="timeline-body">
                                                {
                                                    timelineData.map((timeline, count) => {
                                                        if (count > 2) return;
                                                        return (
                                                            <div className="timeline" key={count}>
                                                                <div className="time">
                                                                    {moment(timeline.dateOfCharting).format("HH:mm")}
                                                                </div>
                                                                <div className="body">
                                                                    <div className="user">
                                                                        {`${timeline.userRole}:
                                                                        ${timeline.userName} (${timeline.userId})`}
                                                                    </div>
                                                                    <div className="chart-type">
                                                                        {`Updated the ${timeline.type} in Charting.`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>}
                                        <div
                                            className="link"
                                            onClick={() => setIsPrevTimelineVisible(!isPrevTimelineVisible)}
                                        >Prev. Updated</div>
                                    </div>
                                    <div className="last-timeline">
                                        {`Last Updated by ${timelineData[0].userRole}: 
                                    ${timelineData[0].userName} (${timelineData[0].userId}) at 
                                    ${moment(timelineData[0].dateOfCharting).format("HH:mm")}`}
                                    </div>
                                </>}
                        </div>
                        <div className="button-container">
                            {isEditable ?
                                (
                                    <>
                                        <div className="chart-cancel chart-button" onClick={() => handleCancelClick()}>
                                            Cancel
                                        </div>
                                        <div className="chart-submit chart-button" onClick={() => handleSubmitClick()}>
                                            Submit
                                        </div>
                                    </>
                                )
                                : (<>
                                    {activeOption !== CHART_OPTIONS.CLINICAL_SCORES &&
                                        <div className="chart-edit chart-button" onClick={() => setIsEditable(true)}>
                                            Edit
                                        </div>

                                    }

                                    {activeOption === CHART_OPTIONS.CLINICAL_SCORES &&
                                        <div className="chart-edit chart-button" onClick={handleClinicalScoreCalculate}>
                                            Calculate
                                        </div>
                                    }

                                </>)
                            }
                        </div>
                    </div>
                </div>

            </div >


        </div>

    );
}

const mapStateToProps = (state, ownProps) => {

    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;
    return {
        patientSessionId: patientSessionId
    };
};

export default connect(mapStateToProps, null)(ChartContainer);
