import React from "react";

import dashboard_svg from "../../common/icons/svg/dashboard.svg";
import signout_svg from "../../common/icons/svg/signout.svg";
import alarm_svg from "../../common/icons/svg/alarm.svg";
import setting_svg from "../../common/icons/svg/setting.svg";
import info_svg from "../../common/icons/svg/info.svg";
import "./Sidebar.scss";

export function SideBar(props) {

    return (
        <div className="side-bar">
            <div className="sidebar-icon-container">
                <div className="top-icon-container">
                    <div className="dashboard-icon-container icon-container">
                        <img src={dashboard_svg} alt="dashboard svg" onClick={() => console.log("dashboard clicked !! ")} />
                    </div>
                    {/* <div className="alarm-icon-container icon-container">
                        <img src={alarm_svg} alt="alarm svg" onClick={() => console.log("alarm clicked !! ")} />
                    </div>
                    <div className="setting-icon-container icon-container">
                        <img src={setting_svg} alt="setting svg" onClick={() => console.log("setting clicked !! ")} />
                    </div>
                    <div className="help-icon-container icon-container">
                        <img src={info_svg} alt="help svg" onClick={() => console.log("help clicked !! ")} />
                    </div> */}
                </div>
                <div className="bottom-icon-container">
                    <div className="help-icon-container icon-container">
                        <img
                            src={signout_svg}
                            alt="signout svg"
                            onClickCapture={(event) => props.handleHelpClick(event)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}