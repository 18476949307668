import "./PatientRightPanel.scss";
import crossSvg from "../../common/icons/svg/cross.svg";
import { BED_DETAILS_MODULE, DEVICE_TYPES } from "../../common/constant/common-constants";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import { VitalData } from "../../components/VitalData/VitalData";
import TrendContainer from "../TrendContainer/TrendContainer";
import AlarmHistoryContainer from "../AlarmHistoryContainer/AlarmHistoryContainer";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReportContainer from "../ReportContainer/ReportContainer";

function PatientRightPanel(props) {
    const [activeSubHeader, setActiveSubHeader] = useState(props.config.activeModule);
    const [deviceData, setDeviceData] = useState(null);
    const [bedId, setBedId] = useState(null);

    useEffect(() => {

        let aSubHeader = props.config.activeModule ? props.config.activeModule : BED_DETAILS_MODULE.VITAL_DETAILS;
        setActiveSubHeader(aSubHeader);
    }, [props.config])

    useEffect(() => {
        setBedId(props.bedId);
    }, [props.bedId])

    const handleSubHeaderClick = (item) => {
        setActiveSubHeader(item)
    }

    useEffect(() => {
        setDeviceData(props.deviceData);

    }, [props.bedDetails, props.bedId])

    return (
        <div className="patient-right-panel-container">
            <div className="bed-info-container">
                <div className="bed-header">
                    <div className="bed-number">Bed: {props.bedDetails && props.bedDetails.bedName}</div>
                    <img src={crossSvg} className="cross-container" onClick={props.handleCrossClick} />
                </div>
                <div className="patient-details">
                    <div className="patient-name">
                        {(props.patientData && props.patientData.name) || 'Name'}
                    </div>
                    <div className="pipe">
                        |
                    </div>
                    <div className="patient-age">
                        {(props.patientData && props.patientData.age) || 'Age'}
                    </div>
                    <div className="pipe">
                        |
                    </div>
                    <div className="patient-gender">
                        {(props.patientData && props.patientData.gender) || 'Gender'}
                    </div>
                </div>
                <div className="sub-header-switch">
                    <div className={activeSubHeader === BED_DETAILS_MODULE.PATIENT_DETAILS
                        ? "common-header active-header" : "common-header"} onClick={() => handleSubHeaderClick(BED_DETAILS_MODULE.PATIENT_DETAILS)}>
                        Patient Details
                    </div>
                    <div className={activeSubHeader === BED_DETAILS_MODULE.VITAL_DETAILS
                        ? "common-header active-header" : "common-header"} onClick={() => handleSubHeaderClick(BED_DETAILS_MODULE.VITAL_DETAILS)}>
                        Vital Details
                    </div>
                    <div className={activeSubHeader === BED_DETAILS_MODULE.TRENDS_REPORTS
                        ? "common-header active-header" : "common-header"} onClick={() => handleSubHeaderClick(BED_DETAILS_MODULE.TRENDS_REPORTS)}>
                        Trends
                    </div>
                    <div className={activeSubHeader === BED_DETAILS_MODULE.REPORT
                        ? "common-header active-header" : "common-header"} onClick={() => handleSubHeaderClick(BED_DETAILS_MODULE.REPORT)}>
                        Report
                    </div>

                    <div className={activeSubHeader === BED_DETAILS_MODULE.ALARMS
                        ? "common-header active-header" : "common-header"} onClick={() => handleSubHeaderClick(BED_DETAILS_MODULE.ALARMS)}>
                        Alarm History
                    </div>
                </div>
            </div>
            {/* <div
                className={activeModule === BED_DETAILS_MODULE.PATIENT_DETAILS ?
                    "patient-details module-heading selected" : "patient-details module-heading"}
                onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.PATIENT_DETAILS)}
            >
                {BED_DETAILS_MODULE.PATIENT_DETAILS}
            </div> */}
            <div className="active-subheader-body-container">
                {activeSubHeader === BED_DETAILS_MODULE.PATIENT_DETAILS &&
                    <PatientDetails
                        bedId={bedId}
                    />
                }
                {activeSubHeader === BED_DETAILS_MODULE.VITAL_DETAILS &&
                    <VitalData
                        bedId={bedId}
                        monitorEcgWaveFormData={props.monitorEcgWaveFormData}
                        monitorHrWaveFormData={props.monitorHrWaveFormData}
                        vitalData={props.monitorData && props.monitorData.vitals}
                    />
                }
                {activeSubHeader === BED_DETAILS_MODULE.REPORT &&
                    <ReportContainer
                        bedId={bedId}
                    />
                }
                {activeSubHeader === BED_DETAILS_MODULE.TRENDS_REPORTS &&
                    <TrendContainer
                        bedId={bedId}
                    />
                }
                {activeSubHeader === BED_DETAILS_MODULE.ALARMS &&
                    <AlarmHistoryContainer
                        bedId={bedId}
                    />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {

    let bds = state.bedDetailsReducer.bedDetails
        && state.bedDetailsReducer.bedDetails[ownProps.bedId];

    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];

    let mData = state.deviceVitalDataReducer.deviceVitalData
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId][DEVICE_TYPES.MONITOR];

    let mEcgwData = state.deviceVitalDataReducer.deviceEcgWaveform
        && state.deviceVitalDataReducer.deviceEcgWaveform[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceEcgWaveform[ownProps.bedId][DEVICE_TYPES.MONITOR];

    let mHrwData = state.deviceVitalDataReducer.deviceHrWaveform
        && state.deviceVitalDataReducer.deviceHrWaveform[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceHrWaveform[ownProps.bedId][DEVICE_TYPES.MONITOR];

    return {
        bedDetails: bds,
        patientData: pData,
        monitorData: mData,
        monitorEcgWaveFormData: mEcgwData,
        monitorHrWaveFormData: mHrwData,
        config: state.configReducer.config.bedDetailConfig
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // modalAction: (payload) => dispatch(modalAction(payload)),
        // bedDetailConfigAction: (payload) => dispatch(bedDetailConfigAction(payload))
    }
}

export default connect(mapStateToProps, null)(PatientRightPanel);
