
import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

// let initialState = {
//     hospitalData: {}
// }

export const hospitalDataReducer = (state = initialState.hospitalDataReducer, action) => {
    switch (action.type) {
        case Types.HOSPITAL_DATA:
            {
                let hospitalId = action.payload.hospitalId;
                let hospitalName = action.payload.hospitalName;
                let username = action.payload.username;

                let hData = state.hospitalData;

                if (hospitalId) hData.hospitalId = hospitalId;
                if (hospitalName) hData.hospitalName = hospitalName;
                if (username) hData.username = username;
                return {
                    ...state,
                    hospitalData: { ...hData }
                };
            }
        default:
            return state;
    }
}