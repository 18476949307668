import { updateClinicalScoreAPI } from "../adapter/cms-adapter";
export const updateClinicalScore = (data) => {

    let promise = new Promise(function (resolve, reject) {

        updateClinicalScoreAPI(data).then((res) => {

            if (res && res.data && res.data.data) resolve(res.data.data);
            else reject("some error message getAlarmHistory");
        }).catch((err) => {
            console.log("error occured in update API");
        })
    })

    return promise;
}