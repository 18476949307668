import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

// const initialState = {
//     notificationData: {}
// }

// action.payload = {
//     "monitorId": "m-1234",
//     "bedId": "b-1234",
//     "deviceId": "d-35678",
//     "notifications": [{
//         "notificationId": "a-21345",
//         "notificationName": "SPO2 exceeded",
//         "time": "987654326543"
//     }]
// }

// let stateData = {
//     "bed1": {
//         bedId: "bedId",
//         dongleId: "dongleId",
//         notifications: [
//             {

//             }
//         ]
//     }
// }

export const notificationDataReducer = (state = initialState.notificationDataReducer, action) => {

    switch (action.type) {
        case Types.NOTIFICATION_DATA: {
            let bedId = action.payload.bedId;
            let deviceId = action.payload.deviceId;
            let dongleId = action.payload.dongleId;
            let deviceType = action.payload.deviceType;
            let newNotifications = action.payload.notifications;

            if (!bedId || !deviceId || !newNotifications) return;
            let notificationData = state.notificationData;

            let notifications = (state.notificationData &&
                state.notificationData[bedId]
                && state.notificationData[bedId].notifications
            ) || [];

            notifications = notifications.push(newNotifications);

            notificationData[bedId] = {
                ...notificationData[bedId],
                dongleId: dongleId,
                bedId: bedId,
                notifications: notifications
            }

            return {
                ...state,
                notificationData: { ...notificationData }
            }
        }
        default:
            return state;
    }
}