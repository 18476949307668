import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { DEVICE_TYPES, TIMELINE } from "../../common/constant/common-constants";
import { VitalData } from "../../components/VitalData/VitalData";

import "./MonitorDetail.scss";

function MonitorDetail(props) {
    const PATIENT_DETAILS = "PATIENT_DETAILS";
    const VITAL_DETAILS = "VITAL DETAILS";

    const [activeNav, setActiveNav] = useState(VITAL_DETAILS);
    const [activeTimeline, setActiveTimeline] = useState(TIMELINE.LIVE);
    const [deviceData, setDeviceData] = useState(null);

    useEffect(() => {
        setDeviceData(props.deviceData);
    }, [props.bedDetails])

    const handleActiveNav = (type) => {

        if (type) setActiveNav(type);
    }

    const handleActiveTimeline = (type) => {

        if (type) setActiveTimeline(type);
    }

    return (
        <div className="graph-detail">
            {console.log(`graph details rerendering!!!! ${props.bedId}`)}
            {/* Graph Detail */}
            {/* <div className="graph-header">
                <div className="bed-name">
                    Bed No. {(props.bedId) || "DUMMY"}
                </div>
                <div className="ews-score">
                    Mild Risk
                </div>
                <div className="view-details">
                    View Details
                </div>
            </div>
            <div className="sub-header">
                <div className="nav-container">
                    <div
                        className={activeNav === PATIENT_DETAILS ?
                            "graph-patient-details nav-data active-nav" : "graph-patient-details nav-data"}
                        onClick={() => handleActiveNav(PATIENT_DETAILS)}>
                        {PATIENT_DETAILS}
                    </div>
                    <div
                        className={activeNav === VITAL_DETAILS ?
                            "graph-vital-details nav-data active-nav" : "graph-vital-details nav-data"}
                        onClick={() => handleActiveNav(VITAL_DETAILS)}>
                        VITAL DETAILS
                    </div>
                </div>
                <div className="nav-timeline-container">
                    {TIMELINE.GRAPH_ARRAY.map((ga) => {
                        return <div
                            key={ga}
                            className={activeTimeline === ga ? `${ga} timeline-data active-timeline` :
                                `${ga} timeline-data`}
                            onClick={() => { handleActiveTimeline(ga) }}>
                            {ga}
                        </div>
                    })}
                </div>
            </div> */}
            {(deviceData && deviceData.vitals) &&
                <VitalData data={deviceData && deviceData.vitals} />}
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {

    let deviceData = state.deviceVitalDataReducer.deviceVitalData && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId];
    return {
        deviceData: deviceData && deviceData[DEVICE_TYPES.MONITOR]
    };
};

export default connect(mapStateToProps, null)(MonitorDetail);


