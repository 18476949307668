import React, { useEffect, useState } from "react";
import "./VitalData.scss";
import { ProgressBarCustom } from "../ProgressBarCustom/ProgressBarCustom";
import ChartCustom from "../Chart/Chart";
import ApexChart from "../../components/Chart/ApexChart";
import whiteUpArrow from "../../common/icons/svg/white-up-arrow.svg";
import whiteDownArrow from "../../common/icons/svg/white-down-arrow.svg";
import { GRAPH_TYPE, VITAL_ECG, MORE_GRAPH_OPTIONS_VITAL, RESP_OBJ, PLETH_OBJ, ETCO2_OBJ, IBP1_OBJ, IBP2_OBJ } from "../../common/constant/common-constants";
import moment from "moment";
import LChart from "../Chart/LCReact";
import { handleOutsideClick } from "../../common/utils/CommonUtils";

export function VitalData(props) {


    const TIME_RANGE_IN_MILLISECONDS = 6 * 1000;
    const [barPercen, setBarPercen] = useState(0);
    const [dataObj, setDataObj] = useState([]);
    const [secondEcgData, setSecondEcgData] = useState([]);
    const [plethObj, setPlethObj] = useState([]);
    const [respObj, setRespObj] = useState([]);
    const [etco2Obj, setEtco2Obj] = useState([]);
    const [ibp1Obj, setIbp1Obj] = useState([]);
    const [ibp2Obj, setIbp2Obj] = useState([]);
    const [vitalECG, setVitalECG] = useState(VITAL_ECG.I);
    const [moreVitalECG, setMoreVitalECG] = useState(false);
    const [secondMoreVitalECG, setSecondMoreVitalECG] = useState(false);
    const [vitalGraphName, setVitalGraphName] = useState(MORE_GRAPH_OPTIONS_VITAL.MONITOR[0]);
    const [secondvitalGraphName, setSecondVitalGraphName] = useState(MORE_GRAPH_OPTIONS_VITAL.MONITOR[1]);
    const [plethTime, setPlethTime] = useState(null);
    const [bedId, setBedId] = useState(null);
    const [lastPushedPlethTime, setLastPushedPlethTime] = useState(null);
    const [lastPushedRespTime, setLastPushedRespTime] = useState(null);
    const [lastPushedEcg1Time, setLastPushedEcg1Time] = useState(null);
    const [lastPushedEcg2Time, setLastPushedEcg2Time] = useState(null);
    const [lastPushedEtco2Time, setLastPushedEtco2Time] = useState(null);
    const [lastPushedIbp1Time, setLastPushedIbp1Time] = useState(null);
    const [lastPushedIbp2Time, setLastPushedIbp2Time] = useState(null);

    useEffect(() => {

        if (bedId !== props.bedId) {
            setDataObj([]);
            setSecondEcgData([]);
            setPlethObj([]);
            setRespObj([]);
            setEtco2Obj([]);
            setIbp1Obj([]);
            setIbp2Obj([]);

            setBedId(props.bedId);
        }
        // console.log("new bed Id !!!");
    }, [props.bedId])


    useEffect(() => {

        /**  first ecg graph  */
        let ecg1DataList;
        let ecg1WaveformData = props.monitorEcgWaveFormData
            && props.monitorEcgWaveFormData.waveform
            && props.monitorEcgWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (vitalGraphName && vitalGraphName.name)
                }
            )[0];

        if (ecg1WaveformData && ecg1WaveformData.time) {
            if (lastPushedEcg1Time ===
                ecg1WaveformData.time) {
                // console.log("last pushed ecg1 time  is same", ecg1WaveformData.time, lastPushedEcg1Time);
                return;
            } else {

                // console.log("pushing ecg1 for time :: ", lastPushedEcg1Time);
                setLastPushedEcg1Time(ecg1WaveformData.time);
            }

            let rawData = ecg1WaveformData.data;
            let time = ecg1WaveformData.time;
            let formattedData = rawData && rawData.split("^");

            // console.log("formatted time: ", time, " formattedData: ", formattedData);

            let ecg1GraphData = [];
            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    ecg1GraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            // console.log("ecg1GraphData : ", ecg1GraphData);
            ecg1DataList = (dataObj && dataObj.concat(ecg1GraphData)) || [];
            if (ecg1DataList && ecg1DataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                ecg1DataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setDataObj(ecg1DataList);
        }

        /**  second ecg graph  */
        let ecg2DataList;
        let ecg2WaveformData = props.monitorEcgWaveFormData
            && props.monitorEcgWaveFormData.waveform
            && props.monitorEcgWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() ===
                        (secondvitalGraphName && secondvitalGraphName.name)
                }
            )[0];

        if (ecg2WaveformData && ecg2WaveformData.time) {
            if (lastPushedEcg2Time ===
                ecg2WaveformData.time) {
                console.log("last pushed ecg2 time  is same");
                return;
            } else {
                setLastPushedEcg2Time(ecg2WaveformData.time);
            }

            let rawData = ecg2WaveformData.data;
            let time = ecg2WaveformData.time;
            let formattedData = rawData && rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let ecg2GraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    ecg2GraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            // console.log("ecg2GraphData : ", ecg2GraphData);

            ecg2DataList = (secondEcgData && secondEcgData.concat(ecg2GraphData)) || [];
            if (ecg2DataList && ecg2DataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                ecg2DataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setSecondEcgData(ecg2DataList);
        }

    }, [props.monitorEcgWaveFormData])

    useEffect(() => {

        let plethDataList;
        let plethWaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (PLETH_OBJ.name)
                }
            )[0];

        if (plethWaveformData && plethWaveformData.time) {
            if (lastPushedPlethTime ===
                plethWaveformData.time) {
                // console.log("last pushed pleth time  is same", plethWaveformData.time, lastPushedPlethTime);
                return;
            } else {

                // console.log("pushing pleth for time :: ", lastPushedEcg1Time);
                setLastPushedPlethTime(plethWaveformData.time);
            }

            let rawData = plethWaveformData.data;
            let time = plethWaveformData.time;
            let formattedData = rawData && rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let plethGraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    plethGraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            plethDataList = (plethObj && plethObj.concat(plethGraphData)) || [];
            if (plethDataList && plethDataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                plethDataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setPlethObj(plethDataList);
        }

        /** resp graph data !!! */
        let respDataList;
        let respWaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (RESP_OBJ.name)
                }
            )[0];

        if (respWaveformData && respWaveformData.time) {
            if (lastPushedRespTime ===
                respWaveformData.time) {
                // console.log("last pushed resp time  is same");
                return;
            } else {
                setLastPushedRespTime(respWaveformData.time);
            }

            let rawData = respWaveformData.data;
            let time = respWaveformData.time;
            let formattedData = rawData && rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let respGraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    respGraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            respDataList = (respObj && respObj.concat(respGraphData)) || [];
            if (respDataList && respDataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                respDataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setRespObj(respDataList);
        }
        // etco2 graph data
        let etco2DataList;
        let etco2WaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (ETCO2_OBJ.name)
                }
            )[0];

        if (etco2WaveformData && etco2WaveformData.time) {
            if (lastPushedEtco2Time ===
                etco2WaveformData.time) {
                // console.log("last pushed resp time  is same");
                return;
            } else {
                setLastPushedEtco2Time(etco2WaveformData.time);
            }

            let rawData = etco2WaveformData.data;
            let time = etco2WaveformData.time;
            let formattedData = rawData && rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let etco2GraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    etco2GraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            etco2DataList = (etco2Obj && etco2Obj.concat(etco2GraphData)) || [];
            if (etco2DataList && etco2DataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                etco2DataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setEtco2Obj(etco2DataList);
        }

        // ibp1 graph data
        let ibp1DataList;
        let ibp1WaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (IBP1_OBJ.name)
                }
            )[0];

        if (ibp1WaveformData && ibp1WaveformData.time) {
            if (lastPushedIbp1Time ===
                ibp1WaveformData.time) {
                // console.log("last pushed resp time  is same");
                return;
            } else {
                setLastPushedIbp1Time(ibp1WaveformData.time);
            }

            let rawData = ibp1WaveformData.data;
            let time = ibp1WaveformData.time;
            let formattedData = rawData && rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let ibp1GraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    ibp1GraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            ibp1DataList = (ibp1Obj && ibp1Obj.concat(ibp1GraphData)) || [];
            if (ibp1DataList && ibp1DataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                ibp1DataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setIbp1Obj(ibp1DataList);
        }

        // ibp2 graph data
        let ibp2DataList;
        let ibp2WaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (IBP2_OBJ.name)
                }
            )[0];

        if (ibp2WaveformData && ibp2WaveformData.time) {
            if (lastPushedIbp2Time ===
                ibp2WaveformData.time) {
                // console.log("last pushed resp time  is same");
                return;
            } else {
                setLastPushedIbp2Time(ibp2WaveformData.time);
            }

            let rawData = ibp2WaveformData.data;
            let time = ibp2WaveformData.time;
            let formattedData = rawData && rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let ibp2GraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    ibp2GraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            ibp2DataList = (ibp2Obj && ibp2Obj.concat(ibp2GraphData)) || [];
            if (ibp2DataList && ibp2DataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                ibp2DataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setIbp2Obj(ibp2DataList);
        }


    }, [props.monitorHrWaveFormData])

    const handleVitalECGSelect = (option) => {
        // setVitalECG(option)
        setVitalGraphName(option);
        setMoreVitalECG(false);
    }

    const handleSecondVitalECGSelect = (option) => {
        // setVitalECG(option)
        setSecondVitalGraphName(option);
        setSecondMoreVitalECG(false);
    }

    const handleMoreVitalECG = (event) => {
        if (!moreVitalECG) {
            handleOutsideClick(event, 'vital-ecg-graph-1', setMoreVitalECG);
        }

    }

    const handleSecondMoreVitalECG = (event) => {
        if (!secondMoreVitalECG) {
            handleOutsideClick(event, 'vital-ecg-graph-2', setSecondMoreVitalECG);
        }
    }

    return (
        <div className="graph-data">
            {/* {console.log('vital data rerendering!!')} */}
            <div className="graph-container">
                <div className="select-vital-ecg-container" onClick={(event) => handleMoreVitalECG(event)}>
                    <div className="selected-vital-ecg-name">
                        {vitalGraphName.label}
                    </div>
                    {moreVitalECG && <img src={whiteDownArrow} />}
                    {!moreVitalECG && <img src={whiteUpArrow} />}
                    {moreVitalECG && <div className="vital-graph-options" id="vital-ecg-graph-1">
                        {
                            MORE_GRAPH_OPTIONS_VITAL.MONITOR.map((option) => {
                                return (
                                    <div className={option.name === vitalGraphName.name ? `common-vital-ecg vital-ecg-active` : `common-vital-ecg`}
                                        onClick={(event) => handleVitalECGSelect(option)} key={option.name}>{option.label}</div>
                                )
                            })
                        }
                    </div>}
                </div>
                <div className="ecg-graph-1">
                    {dataObj && <ApexChart className="chart"
                        dataList={dataObj}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        showYAxis={false}
                        yTickPoint={6}
                        xTickPoint={10}
                        showXAXis={true}
                        width={"100%"}
                        height={"80%"}
                        lineColor={'#299842'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-ecg-1'
                    />}
                </div>
                <div className="ecg-graph-2">

                    <div className="select-vital-ecg-container" onClick={(event) => handleSecondMoreVitalECG(event)}>
                        <div className="selected-vital-ecg-name">
                            {secondvitalGraphName.label}
                        </div>
                        {secondMoreVitalECG && <img src={whiteDownArrow} />}
                        {!secondMoreVitalECG && <img src={whiteUpArrow} />}
                        {secondMoreVitalECG && <div className="vital-graph-options" id="vital-ecg-graph-2">
                            {
                                MORE_GRAPH_OPTIONS_VITAL.MONITOR.map((option) => {
                                    return (
                                        <div className={option.name === secondvitalGraphName.name ? `common-vital-ecg vital-ecg-active` : `common-vital-ecg`}
                                            onClick={(event) => handleSecondVitalECGSelect(option)} key={option.name}>{option.label}</div>
                                    )
                                })
                            }
                        </div>}
                    </div>
                    {secondEcgData && <ApexChart className="chart"
                        dataList={secondEcgData}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        yTickPoint={4}
                        xTickPoint={5}
                        showYAxis={false}
                        width={"100%"}
                        height={"80%"}
                        lineColor={'#299842'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-ecg-2'
                    />}
                </div>
                <div className="resp-graph">
                    {respObj && <ApexChart className="chart"
                        dataList={respObj}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        showYAxis={false}
                        yTickPoint={4}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#FFED47'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-resp-graph'
                        title="RESP"
                        titleColor='#FCC331'
                    />}
                </div>
                <div className="pleth-graph">
                    {plethObj && <ApexChart className="chart"
                        dataList={plethObj}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        showYAxis={false}
                        yTickPoint={4}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#47F4FF'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#20BBC1'}
                        name='apex-pleth-graph'
                        title="Pleth"
                        titleColor='#3ADAE5'
                    />}

                    {/* {plethObj &&
                        <LChart id='chart-1' className="chart" data={plethObj}
                        />} */}
                </div>
                <div className="etco2-graph">
                    {etco2Obj && <ApexChart className="chart"
                        dataList={etco2Obj}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        showYAxis={false}
                        yTickPoint={4}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#6D1DF0'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#6D1DF0'}
                        name='apex-etco2-graph'
                        title="EtCO2"
                        titleColor='#6D1DF0'
                    />}
                </div>
                <div className="ibp1-graph">
                    {ibp1Obj && <ApexChart className="chart"
                        dataList={ibp1Obj}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        showYAxis={false}
                        yTickPoint={4}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#F01DCE'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#F01DCE'}
                        name='apex-ibp1-graph'
                        title={`IBP 1 ${props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp1Label")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp1Label"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp1Label"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp1Label"))[0].data[0].value || ""}`}
                        titleColor='#F01DCE'
                    />}
                </div>
                <div className="ibp2-graph">
                    {ibp2Obj && <ApexChart className="chart"
                        dataList={ibp2Obj}
                        range={TIME_RANGE_IN_MILLISECONDS}
                        // yMin={0}
                        // yMax={80}
                        showYAxis={false}
                        yTickPoint={4}
                        width={"100%"}
                        height={"100%"}
                        lineColor={'#F01DCE'}
                        yTitle={['Pressure', '(cmH20)']}
                        yColor={'#F01DCE'}
                        name='apex-ibp2-graph'
                        title={`IBP 2 ${props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp2Label")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp2Label"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp2Label"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp2Label"))[0].data[0].value || ""}`}
                        titleColor='#F01DCE'
                    />}
                </div>
            </div>
            <div className="data-container">
                <div className="vital-data ecg">
                    <div className="vital-name-container">
                        <div className="vital-name">
                            ECG
                        </div>
                        <div className="vital-unit">
                            {/* {(props.data.ecg && props.data.ecg.unit) || 'b/min'} */}
                            b/min
                        </div>
                    </div>
                    <div className="vital-value-container">
                        {
                            /** filter ecg */
                            props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "hr")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "hr"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "hr"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "hr"))[0].data[0].value || "-"

                        }
                        {/* {console.log(props.vitalData)} */}
                        {/* {props.vitalData && props.vitalData[1].data && props.vitalData[1].data[0].value || "--"} */}
                    </div>
                    <div className="vital-lb-container">
                        <div className="vital-lb-high">
                            {/* {(props.data.ecg && props.data.ecg.conf && props.data.ecg.conf.high) || '160'} */}
                            {/* 160 */}
                        </div>
                        <div className="vital-lb-low">
                            {/* {props.data.ecg && props.data.ecg.conf && props.data.ecg.conf.low || '60'} */}
                            {/* 60 */}
                        </div>
                    </div>
                </div>
                <div className="vital-ecg-option ecg-option-height">
                    <div className="ecg-param-col">
                        <div className="ecg-entry">
                            <div className="ecg-param-name">I</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt1")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt1"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt1"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt1"))[0].data[0].value) || "-"

                                }
                            </div>
                        </div>
                        <div className="ecg-entry">
                            <div className="ecg-param-name">II</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt2")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt2"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt2"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt2"))[0].data[0].value) || "-"


                                }
                            </div>
                        </div>
                        <div className="ecg-entry">
                            <div className="ecg-param-name">III</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt3")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt3"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt3"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt3"))[0].data[0].value) || "-"

                                }
                            </div>
                        </div>
                    </div>
                    <div className="ecg-param-col">
                        <div className="ecg-entry">
                            <div className="ecg-param-name">AVR</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgAvr")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgAvr"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgAvr"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgAvr"))[0].data[0].value) || "-"

                                }
                            </div>
                        </div>
                        <div className="ecg-entry">
                            <div className="ecg-param-name">AVL</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgAvl")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgAvl"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgAvl"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgAvl"))[0].data[0].value) || "-"

                                }
                            </div>
                        </div>
                        <div className="ecg-entry">
                            <div className="ecg-param-name">AVF</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgAvf")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgAvf"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgAvf"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgAvf"))[0].data[0].value) || "-"

                                }
                            </div>
                        </div>
                    </div>
                    <div className="ecg-param-col">
                        <div className="ecg-entry">
                            <div className="ecg-param-name">V1</div>
                            <div className="ecg-param-val">
                                {
                                    /** filter ecg */
                                    (props.vitalData &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt5")).length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt5"))[0] &&
                                        (props.vitalData.filter((vital) => (vital.name === "ecgSt5"))[0].data.length > 0) &&
                                        props.vitalData.filter((vital) => (vital.name === "ecgSt5"))[0].data[0].value) || "-"
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vital-data resp">
                    <div className="vital-name-container">
                        <div className="vital-name">
                            RESP
                        </div>
                        <div className="vital-unit">
                            {/* {(props.data.resp && props.data.resp.unit) || 'b/min'} */}
                            b/min
                        </div>
                    </div>
                    <div className="vital-value-container">
                        {
                            /** filter ecg */
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "rr")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "rr"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "rr"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "rr"))[0].data[0].value) || "-"

                        }
                        {/* {(props.data.resp && props.data.resp.data && props.data.resp.data.value) || '61'} */}

                    </div>
                    <div className="vital-lb-container">
                        <div className="vital-lb-high">
                            {/* {(props.data.resp && props.data.resp.conf && props.data.spo2.resp.high) || '100'} */}
                            {/* 100 */}
                        </div>
                        <div className="vital-lb-low">
                            {/* {(props.data.resp && props.data.resp.conf && props.data.resp.conf.low) || '40'} */}
                            {/* 40 */}
                        </div>
                    </div>
                    <div className="vital-rb-container">
                        <div className="rb-vital-name source-ecg">
                            {/* {(props.data.resp && props.data.resp.source) || 'SOURCE ECG'} */}
                            SOURCE ECG
                        </div>
                    </div>
                </div>
                <div className="vital-data spo2">
                    <div className="vital-name-container">
                        <div className="vital-name">
                            SPO2
                        </div>
                    </div>
                    <div className="vital-value-container">
                        {/* {(props.data.spo2 && props.data.spo2.data && props.data.spo2.data.value) || '98'} */}
                        {
                            /** filter ecg */
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "spo2")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "spo2"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "spo2"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "spo2"))[0].data[0].value) || "-"

                        }
                    </div>
                    <div className="vital-lb-container">
                        <div className="vital-lb-high">
                            {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.high) || '100'} */}
                            {/* 100 */}
                        </div>
                        <div className="vital-lb-low">
                            {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.low) || '90'} */}
                            {/* 90 */}
                        </div>
                    </div>
                    <div className="vital-rt-container">
                        <div className="rt-vital-name">
                            PI
                        </div>
                        <div className="rt-vital-value">
                            {
                                (props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "pi")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "pi"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "pi"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "pi"))[0].data[0].value) || "-"

                            }
                        </div>
                    </div>
                    <div className="vital-rb-container">
                        <div className="rb-vital-name">
                            PR
                        </div>
                        <div className="rb-vital-value">
                            {
                                /** filter ecg */
                                (props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "pr")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "pr"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "pr"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "pr"))[0].data[0].value) || "-"
                            }
                        </div>
                    </div>
                </div>
                <div className="vital-data etco2">
                    <div className="vital-name-container">
                        <div className="vital-name">
                            EtCO₂
                        </div>
                        <div className="vital-unit">
                            {/* {(props.data.resp && props.data.resp.unit) || 'b/min'} */}
                            b/min
                        </div>
                    </div>
                    <div className="vital-value-container">
                        {/* {(props.data.spo2 && props.data.spo2.data && props.data.spo2.data.value) || '98'} */}
                        {
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "endTidalCo2")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "endTidalCo2"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "endTidalCo2"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "endTidalCo2"))[0].data[0].value) || "-"
                        }
                    </div>
                    <div className="vital-lb-container">
                        <div className="vital-lb-high">
                            {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.high) || '100'} */}
                            {/* 100 */}
                        </div>
                        <div className="vital-lb-low">
                            {/* {(props.data.spo2 && props.data.spo2.conf && props.data.spo2.conf.low) || '90'} */}
                            {/* 90 */}
                        </div>
                    </div>
                    <div className="vital-rt-container">
                        <div className="rt-vital-name">
                            FiCO₂
                        </div>
                        <div className="rt-vital-value">
                            {
                                (props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "inspiredMinimumCo2"))[0].data[0].value) || "-"
                            }
                        </div>
                    </div>
                    <div className="vital-rb-container">
                        <div className="rb-vital-name">
                            awRR
                        </div>
                        <div className="rb-vital-value">
                            {
                                (props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "airwayRespirationRate"))[0].data[0].value) || "-"

                            }
                        </div>
                    </div>
                </div>
                <div className="vital-data ibp1">
                    <div className="vital-name-container">
                        <div className="vital-name">
                            IBP1
                        </div>
                        <div className="vital-unit">
                            {/* {(props.data.resp && props.data.resp.unit) || 'b/min'} */}
                            b/min
                        </div>
                    </div>
                    <div className="vital-value-container ibp1-val-main">
                        {
                            /** filter ecg */
                            props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp1Systolic")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp1Systolic"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp1Systolic"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp1Systolic"))[0].data[0].value || "-"
                        }
                        /
                        {
                            props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp1Diastolic"))[0].data[0].value || "-"
                        }
                        {/* {(props.data.resp && props.data.resp.data && props.data.resp.data.value) || '61'} */}

                    </div>
                    <div className="vital-lb-container">
                        <div className="vital-lb-high">
                            {/* {(props.data.resp && props.data.resp.conf && props.data.spo2.resp.high) || '100'} */}
                            {/* 100 */}
                        </div>
                        <div className="vital-lb-low">
                            {/* {(props.data.resp && props.data.resp.conf && props.data.resp.conf.low) || '40'} */}
                            {/* 40 */}
                        </div>
                    </div>
                    <div className="vital-rb-container">
                        <div className="rb-vital-name">
                            {/* {(props.data.resp && props.data.resp.source) || 'SOURCE ECG'} */}
                            Mean
                            <div className="mean-ibp">
                                {
                                    props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "ibp1Mean")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "ibp1Mean"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "ibp1Mean"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "ibp1Mean"))[0].data[0].value || " -"
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vital-data ibp2">
                    <div className="vital-name-container">
                        <div className="vital-name">
                            IBP2
                        </div>
                        <div className="vital-unit">
                            {/* {(props.data.resp && props.data.resp.unit) || 'b/min'} */}
                            b/min
                        </div>
                    </div>
                    <div className="vital-value-container ibp2-val-main">
                        {
                            props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp2Systolic")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp2Systolic"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp2Systolic"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp2Systolic"))[0].data[0].value || "-"
                        }/
                        {
                            props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "ibp2Diastolic"))[0].data[0].value || "-"

                        }
                        {/* {(props.data.resp && props.data.resp.data && props.data.resp.data.value) || '61'} */}

                    </div>
                    <div className="vital-lb-container">
                        <div className="vital-lb-high">
                            {/* {(props.data.resp && props.data.resp.conf && props.data.spo2.resp.high) || '100'} */}
                            {/* 100 */}
                        </div>
                        <div className="vital-lb-low">
                            {/* {(props.data.resp && props.data.resp.conf && props.data.resp.conf.low) || '40'} */}
                            {/* 40 */}
                        </div>
                    </div>
                    <div className="vital-rb-container">
                        <div className="rb-vital-name ">
                            {/* {(props.data.resp && props.data.resp.source) || 'SOURCE ECG'} */}
                            Mean
                            <div className="mean-ibp">
                                {
                                    props.vitalData &&
                                    (props.vitalData.filter((vital) => (vital.name === "ibp2Mean")).length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "ibp2Mean"))[0] &&
                                    (props.vitalData.filter((vital) => (vital.name === "ibp2Mean"))[0].data.length > 0) &&
                                    props.vitalData.filter((vital) => (vital.name === "ibp2Mean"))[0].data[0].value || " -"
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="temp-container">
                <div className="temp-name-container">
                    <div className="temp-name">
                        Temp
                    </div>
                    <div className="temp-name">
                        &#x2103;
                    </div>
                </div>
                <div className="temp-hl-container">
                    <div className="temp-high">
                        {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.high) || '120'} */}
                        {/* 120 */}
                    </div>
                    <div className="temp-low">
                        {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.low) || '70'} */}
                        {/* 70 */}
                    </div>
                </div>
                <div className="temp-t1-container">
                    <div className="temp-name">
                        {`T1`}
                    </div>
                    <div className="temp-value">
                        {/* {(props.data.temp && props.data.temp.data && props.data.temp.data.T1_X5) || '32.4'} */}
                        {
                            /** filter ecg */
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "temperature1")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "temperature1"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "temperature1"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "temperature1"))[0].data[0].value) || "-"

                        }
                    </div>
                </div>
                <div className="temp-t2-container">
                    <div className="temp-name">
                        {`T2`}
                    </div>
                    <div className="temp-value">
                        {/* {(props.data.temp && props.data.temp.data && props.data.temp.data.T1_X6) || '33.4'} */}
                        {
                            /** filter ecg */
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "temperature2")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "temperature2"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "temperature2"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "temperature2"))[0].data[0].value) || "-"
                        }
                    </div>
                </div>
                <div className="temp-td-container">
                    <div className="temp-name">
                        {`TD`}
                    </div>
                    <div className="temp-value">
                        {/* {(props.data.temp && props.data.temp.data && props.data.temp.data.TD) || '34.4'} */}
                        {
                            /** filter ecg */
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "temperatureDelta")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "temperatureDelta"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "temperatureDelta"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "temperatureDelta"))[0].data[0].value) || "-"
                        }
                    </div>
                </div>
            </div>
            <div className="bp-container">
                {/* <div className="progress-bar-container">
                    <div className="nibp-name-container">
                        <div className="nibp-name">
                            NIBP
                        </div>
                        <div className="nibp-unit">
                            mmHg
                        </div>
                    </div>
                    <div className="progress-bar">
                        <ProgressBarCustom
                            completed={80}
                            isLabelVisible={true}
                            labelLeft={'@18.35'}
                            labelRight={'15 min'}
                        />
                    </div>
                </div> */}

                <div className="nibp-name-container">
                    <div className="nibp-name">
                        NIBP
                    </div>
                    <div className="nibp-unit">
                        mmHg
                    </div>
                </div>
                <div className="nibp-hl-container">
                    <div className="nibp-high">
                        {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.high) || '120'} */}
                        {/* 120 */}
                    </div>
                    <div className="nibp-low">
                        {/* {(props.data.temp && props.data.temp.conf && props.data.temp.conf.low) || '70'} */}
                        {/* 70 */}
                    </div>
                </div>
                <div className="nibp-data-container">
                    <div className="nibp-data">
                        {/* {(props.data.nibp && props.data.nibp.data && props.data.nibp.data.high) || '112'}/{(props.data.nibp && props.data.nibp.data && props.data.nibp.data.low) || '88'} */}
                        {
                            /** filter ecg */
                            (props.vitalData &&
                                (props.vitalData.filter((vital) => (vital.name === "bp")).length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "bp"))[0] &&
                                (props.vitalData.filter((vital) => (vital.name === "bp"))[0].data.length > 0) &&
                                props.vitalData.filter((vital) => (vital.name === "bp"))[0].data[0].value) || "-"
                        }
                    </div>
                </div>
                <div className="nibp-avg-data">
                    {/* ({(props.data.nibp && props.data.nibp.data && props.data.nibp.data.avg) || '93'}) */}
                    {
                        /** filter ecg */
                        (props.vitalData &&
                            (props.vitalData.filter((vital) => (vital.name === "bpMean")).length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "bpMean"))[0] &&
                            (props.vitalData.filter((vital) => (vital.name === "bpMean"))[0].data.length > 0) &&
                            props.vitalData.filter((vital) => (vital.name === "bpMean"))[0].data[0].value)
                        || "--"
                    }
                </div>
            </div>
        </div>
    );
}
