import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

// let initialState = {
//     patientData: {},
// }

export const patientDataReducer = (state = initialState.patientDataReducer, action) => {
    switch (action.type) {
        case Types.PATIENT_DATA:
            {
                let bedId = action.payload.bedId;
                let data = action.payload.data;

                if (!bedId) return;

                let pData = state.patientData;
                pData[bedId] = data;

                return {
                    ...state,
                    patientData: { ...pData }
                };
            }
        case Types.PATIENT_DISCHARGE:
            {
                let bedId = action.payload.bedId;
                let bedObj = state;

                delete bedObj.patientData[bedId];

                return {
                    ...state,
                    patientData: bedObj.patientData
                }
            }
        default:
            return state;
    }
}