import React from "react";

const calculateBoundingBoxes = children => {
    const boundingBoxes = {};

    React.Children.forEach(children, child => {
        if (!child || !child.ref) return;
        const domNode = child.ref && child.ref.current;
        // const domNode = child.props.myRef;
        const nodeBoundingBox = domNode && domNode.getBoundingClientRect();

        boundingBoxes[child.key] = nodeBoundingBox;
    });

    return boundingBoxes;
};

export default calculateBoundingBoxes;
