import moment from "moment";
import { useEffect, useState } from "react";
import { CHART_HEADER_CONST, CHART_OPTIONS } from "../../../common/constant/common-constants";
import "./DrugAdministration.scss";

export function DrugAdminstration(props) {
    const [headerList, setHeaderList] = useState(props.headerList);

    const timeFormat = "HH:mm";

    useEffect(() => {
        setHeaderList([...props.headerList]);
        // setData({ ...props.data })

    }, [props]);

    const handleChange = (prescriptionId, prescriptionType, administrationId, time, event) => {


        let value = event.target.checked;
        console.log(value);

        let tempData = props.data;

        let prescription = tempData.filter((pres) => (pres.id === prescriptionId) && (pres.type === prescriptionType))[0];

        if (prescription) {
            let drugAdministrations = prescription.drugAdministrations;
            let administration = drugAdministrations.filter((adm) => adm.id === administrationId)[0];

            if (!administration && value) {
                administration = {
                    time: time,
                    id: administrationId,
                }
                drugAdministrations.push(administration);
            } else {
                let isNew = (administration.id.indexOf("new-") > -1);

                if (isNew) {
                    if (!value) {
                        drugAdministrations
                            .splice(
                                drugAdministrations.findIndex(e => e.id === administration.id),
                                1
                            );
                    }
                }
            }

            prescription.drugAdministrations = drugAdministrations;

            props.handleChangeData(tempData);
        }
    }

    const getHtml = () => {

        let html = props.data && props.data.map((prescription, count) => {
            return (
                <tr>
                    {
                        headerList.map((header) => {

                            if (header === CHART_HEADER_CONST.PARAMETERS) {
                                return (
                                    <td className="drug-data-cell sticky">
                                        <div className="drug-detail">
                                            <div className="drug-name">
                                                {prescription.name}
                                            </div>
                                            <div className="drug-data">
                                                {prescription.dosage && <div className="dosage drug-box">
                                                    {
                                                        `${prescription.dosage} mg`
                                                    }
                                                </div>}
                                                {prescription.frequency && <div className="frequency drug-box">
                                                    {
                                                        prescription.frequency
                                                    }
                                                </div>}
                                                {prescription.route && <div className="route drug-box">
                                                    {
                                                        prescription.route
                                                    }
                                                </div>}
                                            </div>
                                            <div className="drug-user">
                                                {
                                                    prescription.user
                                                }
                                            </div>
                                        </div>
                                    </td>
                                );
                            }
                            else if (header === CHART_HEADER_CONST.TYPE) {
                                return (
                                    <td className="drug-data-cell">
                                        <div className="drug-type">
                                            {prescription.type}
                                        </div>
                                    </td>
                                );
                            }
                            else if (header === CHART_HEADER_CONST.REMARKS) {
                                return (
                                    <td className="drug-data-cell">
                                        <div className="drug-remark">
                                            {prescription.remarks}
                                        </div>
                                    </td>
                                );
                            } else {
                                let headerTime = moment(header, timeFormat);
                                let administration =
                                    prescription.drugAdministrations
                                        .filter((administration) =>
                                            moment(administration.time, timeFormat).isSame(headerTime)
                                        )[0];

                                // if (administration)
                                let ifExist = ((administration != undefined));
                                let administrationId = (administration && administration.id) ||
                                    (`new-${prescription.id}-${header}`);

                                let isNew = administrationId && administrationId.toString().indexOf("new-") > -1;

                                return (
                                    <td className="drug-data-cell">
                                        <div className="administration">
                                            <input
                                                className="drug-checkbox"
                                                checked={ifExist}
                                                type="checkbox"
                                                aria-disabled={(!isNew || !props.isEditable)}
                                                onChange={(event) =>
                                                    handleChange(prescription.id, prescription.type, administrationId, header, event)
                                                } />
                                            {administration && administration.userId && <div className="user">
                                                {administration.userId}

                                                <div className="user-id-hover">
                                                    <div className="id">
                                                        {administration.userId}
                                                    </div>
                                                    <div className="user-name">
                                                        {`Nurse. ${administration.user}`}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </td>
                                );
                            }

                        })
                    }
                </tr>
            )
        });

        return html;
    }

    return <>
        <div className="drug-administration-container">

            {

                // getHtml()
            }

            <table className="drug-administration-table">
                <thead>
                    <tr>
                        {headerList.map((header, count) => {
                            return (
                                <th
                                    className={count === 0 ?
                                        "heading cell sticky" : "heading cell"}
                                >
                                    {header}
                                </th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {getHtml()}
                </tbody>
            </table>
        </div>
    </>
}