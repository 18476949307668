import { getNotesAPI, postNotesAPI } from "../adapter/cms-adapter";

export const getNotes = (data) => {

    let reqData = {
        bedId: data.bedId,
        patientSessionId: data.patientSessionId,
        type: data.type
    }

    let promise = new Promise((resolve, reject) => {

        getNotesAPI(reqData).then((res) => {

            console.log(res);

            if (res && res.data && res.data.data) {
                resolve(res.data.data);
            } else {
                reject("Notes error");
            }
        }).catch((err) => {
            reject("Notes error");
        })
    });

    return promise;
}

export const postNotes = (data) => {

    let reqData = {
        bedId: data.bedId,
        patientSessionId: data.patientSessionId,
        message: data.message,
        type: data.type
    }

    let promise = new Promise((resolve, reject) => {

        postNotesAPI(reqData).then((res) => {

            console.log(res);

            if (res && res.data && res.data.data) {
                resolve(res.data.data);
            } else {
                reject("Notes error");
            }
        }).catch((err) => {
            reject("Notes error");
        })
    });

    return promise;
}