import {
    admitPatientAPI,
    dischargePatientAPI
} from "../adapter/cms-adapter";

export const admitPatient = (data) => {

    let reqData = data;

    let promise = new Promise(function (resolve, reject) {

        admitPatientAPI(reqData).then((res) => {

            if (res && res.data && res.data.data) resolve(res.data.data);
            else reject("some error message getAlarmHistory");
        })
    })

    return promise;
}

export const dischargePatient = (data) => {

    let reqData = data;

    let promise = new Promise(function (resolve, reject) {

        dischargePatientAPI(reqData).then((res) => {

            if (res) resolve();
            else reject("some error message getAlarmHistory");
        })
    })

    return promise;
}