import moment from "moment";
import React, { useEffect, useState } from "react";
import { CHART_OPTIONS, getChartHeader, getModeFromIOColumn, getParameterList, IO_DATA } from "../../../common/constant/common-constants";
import addSvg from "../../../common/icons/svg/add-filled.svg";

import "./IoBalance.scss";

export function IoBalance(props) {
    const [ivFluidColSpan, setIvFluidColSpan] = useState("");
    const [infusionColSpan, setInfusionColSpan] = useState("");
    const [oralRtColSpan, setOralRtColSpan] = useState("");
    const [otherColSpan, setOtherColSpan] = useState("");
    const [intakeTotalSpan, setIntakeTotalColSpan] = useState("");
    const [urineColSpan, setUrineColSpan] = useState("");
    const [drainColSpan, setDrainColSpan] = useState("");
    const [outputTotalColSpan, setOutputTotalColSpan] = useState("");
    const [intakeTotal, setIntakeTotal] = useState("");
    const [outputTotal, setOutputTotal] = useState("");

    useEffect(() => {
        // setUpdatedIoData(ioData);
        if (!props.data) return;

        let oTotal = 0;
        let iTotal = 0;
        for (let [key, value] of Object.entries(props.data)) {

            setColumnSpan(key, value.length);

            if (!value) return;
            value.map((paramData) => {
                paramData.metadata.map((timeData) => {
                    let number = (parseInt(timeData && timeData.value) || 0);

                    if (paramData.mode === IO_DATA.IO_COLUMNS.INTAKE) iTotal += number;
                    else if (paramData.mode === IO_DATA.IO_COLUMNS.OUTPUT) oTotal += number;
                });
            })
        }

        setIntakeTotal(iTotal);
        setOutputTotal(oTotal);
    }, [props.data]);

    const getColumnSpan = (type) => {

        switch (type) {
            case IO_DATA.IO_COLUMNS.IV_FLUID:
                return ivFluidColSpan;
            case IO_DATA.IO_COLUMNS.INTAKE:
                return (ivFluidColSpan || 1) +
                    (infusionColSpan || 1) +
                    (oralRtColSpan || 1) +
                    (otherColSpan || 1) +
                    (intakeTotalSpan || 1);
            case IO_DATA.IO_COLUMNS.INFUSION:
                return infusionColSpan;
            case IO_DATA.IO_COLUMNS.ORAL_RT:
                return oralRtColSpan;
            case IO_DATA.IO_COLUMNS.OTHERS:
                return otherColSpan;
            case IO_DATA.IO_COLUMNS.INTAKE_TOTAL:
                return intakeTotalSpan;
            case IO_DATA.IO_COLUMNS.URINE:
                return urineColSpan;
            case IO_DATA.IO_COLUMNS.DRAIN:
                return drainColSpan;
            case IO_DATA.IO_COLUMNS.OUTPUT_TOTAL:
                return outputTotalColSpan;
            case IO_DATA.IO_COLUMNS.OUTPUT:
                return (urineColSpan || 1) + (drainColSpan || 1) + (outputTotalColSpan || 1);
        }
    }

    const setColumnSpan = (type, number) => {
        switch (type) {
            case IO_DATA.IO_COLUMNS.IV_FLUID:
                setIvFluidColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.INFUSION:
                setInfusionColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.ORAL_RT:
                setOralRtColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.OTHERS:
                setOtherColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.INTAKE_TOTAL:
                setIntakeTotalColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.URINE:
                setUrineColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.DRAIN:
                setDrainColSpan(number);
                break;
            case IO_DATA.IO_COLUMNS.OUTPUT_TOTAL:
                setOutputTotalColSpan(number);
                break;
            default:
                return null;
        }
    }

    const updateData = (columnName, id, type, time, value) => {
        console.log("columnName:: ", columnName, " id: ", id, " type: ", type, " time: ", time, " value: ", value);

        let tempIoData = props.data;

        if (!tempIoData[columnName]) {
            tempIoData[columnName] = [{
                id: id,
                mode: getModeFromIOColumn(columnName),
                metadata: []
            }]
        }

        console.log(tempIoData);
        let filteredIo = tempIoData[columnName].filter((data) => ((data.key === id) || (data.id === id)))[0];

        if (type === IO_DATA.IO_ROWS.TIME) {
            let timeData = filteredIo.metadata.filter((timeData) => timeData.time === time)[0];
            if (timeData) {
                timeData.value = value;
            } else {
                filteredIo.metadata.push({
                    time: time,
                    value: value
                })
            }
        } else if (type === IO_DATA.IO_ROWS.NAME) {
            filteredIo.key = value;
        }

        props.updateData(tempIoData);
    }

    const addColumn = (columnName) => {
        let tempIoData = props.data;

        if (!tempIoData[columnName]) return;
        else {

            let isEmptyRowAvailable = tempIoData[columnName].filter((cData) => !cData.key)[0];
            if (!isEmptyRowAvailable) {
                tempIoData[columnName].push({
                    id: "new-" + Math.floor(Math.random() * 100),
                    mode: getModeFromIOColumn(columnName),
                    metadata: []
                })
            }

        }

        props.updateData({ ...tempIoData });
    }

    return (
        <div className="io-table-container">
            <table className="io-table" cellspacing="0">
                <thead>
                    {
                        getChartHeader(CHART_OPTIONS.I_O_BAL).map((row) => {

                            return (<tr>
                                {
                                    row.map((column) => {
                                        return (
                                            <th
                                                cellspacing="0"
                                                className={`${column.className}`}
                                                rowSpan={column.rowSpan}
                                                colSpan={getColumnSpan(column.name) || column.colSpan}>

                                                <div className="content">
                                                    <div className="text">
                                                        {column.label}
                                                    </div>
                                                    {column.isAddIconVisible && <div className="icon">
                                                        <img
                                                            className="add-icon"
                                                            src={addSvg}
                                                            onClick={() => addColumn(column.name)}
                                                        />
                                                    </div>}
                                                </div>

                                            </th>
                                        )
                                    })
                                }
                            </tr>)

                        })
                    }
                </thead>
                <tbody>
                    {
                        getParameterList(CHART_OPTIONS.I_O_BAL).map((row) => {
                            let rowIntakeTotal = 0;
                            let rowOutputTotal = 0;
                            return (<tr>
                                <td className="name sticky-left parameters">
                                    <div className="content">
                                        {row.label}
                                    </div>
                                </td>
                                {
                                    getChartHeader(CHART_OPTIONS.I_O_BAL).map((headerList, count) => {
                                        if (count === 0) return;

                                        return headerList.map((columnContainer, count) => {

                                            // console.log("header:: " + columnContainer.name);
                                            let columnData = props.data[columnContainer.name];
                                            let html;

                                            if (row.label === IO_DATA.IO_ROWS.NAME) {
                                                html = columnData && columnData.map((column) => {
                                                    return (
                                                        <td className="name parameters">
                                                            <div className="input-container content">
                                                                <input
                                                                    className="data-input"
                                                                    readOnly={!props.isEditable}
                                                                    onChange={
                                                                        (event) =>
                                                                            updateData(
                                                                                columnContainer.name,
                                                                                column.id,
                                                                                IO_DATA.IO_ROWS.NAME,
                                                                                null,
                                                                                event.target.value
                                                                            )
                                                                    }
                                                                    value={column.key}
                                                                />
                                                            </div>
                                                        </td>
                                                    )
                                                });

                                                if (!columnData) {
                                                    let id = "new-" + Math.floor(Math.random()) * 100;
                                                    html = (
                                                        <td
                                                            className=
                                                            {columnContainer.name === IO_DATA.IO_COLUMNS.TI_TO ? "sticky-right" : ""}>
                                                            <div className="input-container">
                                                                <input
                                                                    className="data-input"
                                                                    readOnly={!props.isEditable}
                                                                    onChange={
                                                                        (event) => updateData(
                                                                            columnContainer.name,
                                                                            id,
                                                                            IO_DATA.IO_ROWS.NAME,
                                                                            null,
                                                                            event.target.value
                                                                        )}
                                                                // readOnly={!props.isEditable}
                                                                />
                                                            </div>
                                                        </td>)
                                                }
                                            } else if (row.label === IO_DATA.IO_ROWS.TOTAL) {
                                                html = columnData && columnData.map((column) => {

                                                    let number = 0;
                                                    column.metadata.map((dt) => {
                                                        number += (parseInt(dt && dt.value) || 0);
                                                    })
                                                    return (
                                                        <td className="name parameters">
                                                            <div className="content">
                                                                {number}
                                                            </div>
                                                        </td>
                                                    )
                                                });

                                                if (columnContainer.name === IO_DATA.IO_COLUMNS.TI_TO) {
                                                    html = (<td className="sticky-right">
                                                        <div className="input-container">
                                                            {(intakeTotal - outputTotal) ? (intakeTotal - outputTotal) : ""}
                                                        </div>
                                                    </td>)
                                                } else if (columnContainer.name === IO_DATA.IO_COLUMNS.INTAKE_TOTAL) {
                                                    html = (<td className="sticky-right">
                                                        <div className="input-container">
                                                            {intakeTotal ? intakeTotal : ""}
                                                        </div>
                                                    </td>)
                                                } else if (columnContainer.name === IO_DATA.IO_COLUMNS.OUTPUT_TOTAL) {
                                                    html = (<td className="sticky-right">
                                                        <div className="input-container">
                                                            {outputTotal ? outputTotal : ""}
                                                        </div>
                                                    </td>)
                                                } else if (!html) {
                                                    html = (<td>
                                                        <div className="input-container">
                                                            <input
                                                                className="data-input"
                                                                readOnly={!props.isEditable}
                                                            />
                                                        </div>
                                                    </td>)
                                                }
                                            } else {
                                                let time = row.label;

                                                html = columnData && columnData.map((column) => {

                                                    let timeData = column.metadata.filter((dt) => moment(dt.time, "HH:mm").isSame(moment(time, "HH:mm")))[0];

                                                    if (timeData) {
                                                        if (column.mode === IO_DATA.IO_COLUMNS.INTAKE) {
                                                            rowIntakeTotal += (parseInt(timeData && timeData.value) || 0);
                                                        } else if (column.mode === IO_DATA.IO_COLUMNS.OUTPUT) {
                                                            rowOutputTotal += (parseInt(timeData && timeData.value) || 0);
                                                        }

                                                        return (<td className="name parameters">
                                                            <div className="content input-container">
                                                                <input
                                                                    className="data-input"
                                                                    readOnly={timeData.ioBalanceId || !props.isEditable}
                                                                    onChange={(event) => updateData(
                                                                        columnContainer.name,
                                                                        column.key,
                                                                        IO_DATA.IO_ROWS.TIME,
                                                                        time,
                                                                        event.target.value)}
                                                                    value={timeData.value}
                                                                // readOnly={!props.isEditable}
                                                                />
                                                            </div>
                                                        </td>)
                                                    } else {
                                                        return (
                                                            <td>
                                                                <div className="input-container">
                                                                    <input
                                                                        className="data-input"
                                                                        readOnly={!props.isEditable}
                                                                        onChange={(event) =>
                                                                            updateData(
                                                                                columnContainer.name,
                                                                                column.key,
                                                                                IO_DATA.IO_ROWS.TIME,
                                                                                time,
                                                                                event.target.value)}
                                                                    />
                                                                </div>
                                                            </td>
                                                        )
                                                    }
                                                });

                                                if (columnContainer.name === IO_DATA.IO_COLUMNS.TI_TO) {
                                                    // console.log("rowTotal: " + rowTotal, " time: ", time);
                                                    html = (<td className="sticky-right">
                                                        <div className="input-container">
                                                            {(rowIntakeTotal - rowOutputTotal) ? (rowIntakeTotal - rowOutputTotal) : ""}
                                                        </div>
                                                    </td>)
                                                } else if (columnContainer.name === IO_DATA.IO_COLUMNS.INTAKE_TOTAL) {
                                                    html = (<td className="sticky-right">
                                                        <div className="input-container">
                                                            {rowIntakeTotal ? rowIntakeTotal : ""}
                                                        </div>
                                                    </td>)
                                                } else if (columnContainer.name === IO_DATA.IO_COLUMNS.OUTPUT_TOTAL) {
                                                    html = (<td className="sticky-right">
                                                        <div className="input-container">
                                                            {rowOutputTotal ? rowOutputTotal : ""}
                                                        </div>
                                                    </td>)
                                                } else if (!html) {
                                                    html = (<td>
                                                        <div className="input-container">
                                                            <input
                                                                className="data-input"
                                                                readOnly={!props.isEditable}
                                                            />
                                                        </div>
                                                    </td>)
                                                }
                                            }

                                            return html;
                                        })
                                    })
                                }
                            </tr>)
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

