import React, { useState, useEffect } from 'react';

import { BedCard } from '../../components/BedCard/BedCard';
import { Header } from "../../components/Header/Header";
import { SideBar } from "../../components/SideBar/Sidebar";
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitchNew';
import {
    bedDetailAction,
    bedIdAction,
    patientDataAction,
    deviceVitalDataAction,
    alarmDataAction,
    notificationDataAction,
    filterAction,
    wardDetailsAction,
    addColumnBedListAction,
    removeColumnBedListAction,
    clearAlarms,
    deviceWaveformDataAction,
    deviceEcgWaveformDataAction,
    deviceHrWaveformDataAction,
    selectedWardAction,
    hospitalDataAction
} from '../../modules/actions/action';
import {
    openSocketConnection,
    closeSocketConnection,
    setBedDetailsAction,
    setBedIdAction,
    setPatientDataAction,
    setDeviceVitalDataAction,
    setAlarmDataAction,
    setNotificationDataAction,
    setWardDetailAction,
    setDeviceWaveformDataAction,
    setDeviceHrWaveformDataAction,
    setDeviceEcgWaveformDataAction
} from '../../socket/socket';

import filterSvg from "../../common/icons/svg/filter.svg";
import "./Homepage.scss";
import { connect } from 'react-redux';
import { SORT_TYPES, VIEW_TYPES, checkType } from '../../common/constant/common-constants';
import SubHeader from '../../components/SubHeader/SubHeader';
import BedContainer from '../../container/BedContainer/BedContainer';
import PatientModal from '../../container/PatientModal/PatientModal';
import { test } from '../../adapter/cms-adapter';
import { getHospitalDetails } from '../../helper/home-page-helper';
import { HOME_PAGE, PAGE_CONST } from '../../common/constant/page-constants';
import { IoBalance } from '../../components/Table/IoBalance/IoBalance';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { redirect } from '../../common/utils/RedirectionUtils';
import { deleteCookieCustom } from '../../common/utils/StorageUItils';
import { loginTrack } from '../../helper/login-helper';

function HomePage(props) {

    const [toggle, setToggle] = useState(props.config.viewType === VIEW_TYPES.GRID_VIEW ? false : true);
    const [sortType, setSortType] = useState(SORT_TYPES.FAVORITE);
    // const [viewType, setViewType] = useState(props.config.viewType);

    const [showAddColumnOption, setShowAddColumnOption] = useState(false);
    const [modalConfig, setModalConfig] = useState(null);
    const [selectedWard, setSelectedWard] = useState("");
    const [wardOption, setWardOption] = useState({});
    const [signoutModal, setSignoutModal] = useState(false);
    const [hospitalName, setHospitalName] = useState("");

    const navigate = useNavigate();

    /**
         * 
         * Need to set all actions for data processing before opening the socket connection
         */
    // useEffect(() => {
    //     const unloadCallback = (event) => {
    //         // event.preventDefault();
    //         // event.returnValue = "";
    //         window.onbeforeunload = function () {
    //             window.onUnload();
    //             return "";
    //         }

    //         return "";
    //     };

    //     window.addEventListener("beforeunload", unloadCallback);
    //     return () => window.removeEventListener("beforeunload", unloadCallback);
    // }, []);
    // useEffect(() => {
    //     const onClose = (event) => {
    //         // Do something when the tab is closed.
    //         // event.stopPropagation();
    //         const navigationEntries = window.performance.getEntriesByType('navigation');
    //         let data = {
    //             type: navigationEntries.length > 0 && navigationEntries[0].type !== 'reload' ? checkType.LOGOUT : "RELOAD",
    //             hospitalId: props.hospitalData.hospitalId,
    //             logoutDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //             username: props.hospitalData.username
    //         }
    //         loginTrack(data).then((res) => {
    //             deleteCookieCustom("accessToken");
    //             console.log("sucess!!!", res);

    //         }).catch((err) => {
    //             console.log("err in API call", err);
    //         })
    //         // const navigationEntries = window.performance.getEntriesByType('navigation');
    //         // console.log(window.performance);
    //         // // console.log("nav entries", navigationEntries);
    //         // if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
    //         //     console.log("Page was reloaded");
    //         // } else {

    //         // }
    //         return "";

    //     };

    //     window.addEventListener('beforeunload', onClose);

    //     return () => {
    //         // Remove the event listener when the component unmounts.
    //         window.removeEventListener('beforeunload', onClose);
    //     };
    // }, []);

    // useEffect(() => {
    //     const navigationEntries = window.performance.getEntriesByType('navigation');
    //     console.log("nav entries", navigationEntries);
    //     if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
    //         console.log("Page was reloaded");
    //     } else {

    //     }
    // }, []);

    // const handleTabClosing = (event) => {
    //     // event.preventDefault()

    //     // api call for logout again
    //     let data = {
    //         type: checkType.LOGOUT,
    //         hospitalId: props.hospitalData.hospitalId,
    //         logoutDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //         username: props.hospitalData.username
    //     }
    //     loginTrack(data).then((res) => {
    //         deleteCookieCustom("accessToken");
    //         console.log("sucess!!!", res);

    //     }).catch((err) => {
    //         console.log("err in API call", err);
    //     })

    //     // alert("tab was closed!!!");
    // }

    useEffect(() => {
        setSelectedWard(props.selectedWard);
        setActionForSocket();
        openSocketConnection(props.hospitalData);
        return () => {
            closeSocketConnection();
        }

    }, []);

    useEffect(() => {

        try {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = function () {
                window.history.go(1);
            };
        } catch (err) {
            console.log("issue in blocking back navigation", err);
        }
    }, [])

    useEffect(() => {
        let wardOption = [];
        let bedIdArray = [];
        let tempWard = null;

        // console.log(" use effect called !!");
        getHospitalDetails().then((res) => {

            setHospitalName(res.data.hospitalName);
            props.hospitalDataAction({ hospitalName: res.data.hospitalName, hospitalId: res.data.hospitalId, username: res.data.name });

            if (!res || !res.data && !res.data.wards) return;

            /**
             * 
             * create bed card, add patient Data, 
             * 
             * create entry in ward
             *      - create ward
             *      - set ward to bed mapping
             * create patient details
             *  - create bed- patient mapping
             */
            let beds = res.data.wards.map((ward) => {
                // return [...ward.beds];

                /** patient Details */
                let bedIdList = ward.beds.map((bed) => {
                    let data = {
                        bedId: bed.id,
                        data: bed.patientDetails
                    }
                    let alarmData = {
                        bedId: bed.id,
                        alarms: bed.alarmDetails,
                    }
                    let bedData = {
                        bedId: bed.id,
                        bedName: bed.name
                    }
                    props.bedDetailAction(bedData);
                    props.alarmDataAction(alarmData);
                    props.bedIdAction({ bedId: bed.id });
                    props.patientDataAction(data);
                    return bed.id;
                })

                /** Ward Details */
                let wardData = {
                    id: ward.id,
                    data: {
                        id: ward.id,
                        wardName: ward.name,
                        bedList: bedIdList
                    }
                }
                props.wardDetailsAction(wardData);

                /** for dropdown */
                let wardObj = {
                    id: ward.id,
                    name: ward.name,
                    label: ward.name
                };
                if (!tempWard && !(props && props.selectedWard && props.selectedWard.id)) {
                    tempWard = wardObj;
                    handleWardChange(wardObj);
                    // if (!selectedWard.id) setSelectedWard(tempWard)
                }
                wardOption.push(wardObj);
            });
            setWardOption(wardOption);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {

        let mConfig = props.config.modalConfig;
        setModalConfig(mConfig);
        // props.viewTypeAction({ viewType: VIEW_TYPES.GRID_VIEW });

        // test();
    }, [props.config])

    const setActionForSocket = () => {
        setBedDetailsAction(props.bedDetailAction);
        setBedIdAction(props.bedIdAction);
        setPatientDataAction(props.patientDataAction);
        setDeviceVitalDataAction(props.deviceVitalDataAction);
        setAlarmDataAction(props.alarmDataAction);
        setNotificationDataAction(props.notificationDataAction);
        setWardDetailAction(props.wardDetailAction);
        setDeviceEcgWaveformDataAction(props.deviceEcgWaveformDataAction);
        setDeviceHrWaveformDataAction(props.deviceHrWaveformDataAction);
    }



    // const onToggleChange = (checked) => {

    //     let viewType;
    //     setToggle(checked);
    //     if (checked) viewType = VIEW_TYPES.LIST_VIEW;
    //     else viewType = VIEW_TYPES.GRID_VIEW;

    //     props.viewTypeAction({ viewType: viewType });
    //     setViewType(viewType);
    // }

    const toggleAddColumn = (toggle) => {
        console.log(toggle);
        setShowAddColumnOption(toggle);
    }

    const handleAddColumnButtonClick = () => {

        let sac = showAddColumnOption;

        /* verify and check */
        // if (!sac) {
        //     document.getElementById("add-columnn-container").addEventListener('click', function (event) {
        //         event.stopPropagation();
        //         // toggleAddColumn(true);
        //         console.log("inside click !!");
        //     });

        //     document.addEventListener('click', function () {
        //         console.log("outside click !!");
        //         toggleAddColumn(false);
        //     });
        // } else {
        //     // document.removeEventListener('click', null)
        //     // document.getElementById("add-columnn-container").removeEventListener('click', null);
        // }
        setShowAddColumnOption(!sac);
    }

    const handleAddColumnItemClick = (event, paramObj) => {

        let value = event && event.target && event.target.checked;

        if (value) {
            props.addColumnBedListAction(paramObj);
        } else {
            props.removeColumnBedListAction(paramObj);
        }
    }

    const ifAddColumnOptionChecked = (paramObj) => {
        let listViewParameters = (props.config && props.config.listViewParameters) || [];

        let index = listViewParameters.findIndex((param) => param.name === paramObj.name);

        if (index && index > -1) return true;

        return false;
    }
    const handleHelpClick = (event) => {
        // setSignoutModal(!signoutModal)


        if (!signoutModal) {
            event.stopPropagation();
            setSignoutModal(true);

            console.log("clicked signout in if");
            let element = document.getElementById('signout-modal-container');

            window.addEventListener('click', function (e) {
                if (element && element.contains(e.target)) {
                    // let crossIconElement = this.document.getElementById("cross-item-icon");
                    // if (crossIconElement && crossIconElement.contains(e.target)) {
                    //     setIsMoreItemsVisibe(false);
                    // }
                } else {
                    setSignoutModal(false);
                }
            });
            return;
        }

    }
    const handleSignoutCancel = () => {
        setSignoutModal(!signoutModal)
    }

    const handleSigoutClick = () => {

        // api call for logout track goes here
        let data = {
            type: checkType.LOGOUT,
            hospitalId: props.hospitalData.hospitalId,
            logoutDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            username: props.hospitalData.username
        }
        loginTrack(data).then((res) => {
            console.log("sucess!!!", res);
            redirect(navigate, PAGE_CONST.LOGIN)
            deleteCookieCustom("accessToken");
        }).catch((err) => {
            console.log("err in API call", err);
            redirect(navigate, PAGE_CONST.LOGIN)
        })
    }

    const handleSigoutContainerClick = (event) => {
        if (!signoutModal) {
            event.stopPropagation();
            setSignoutModal(true);

            // console.log("clicked signout in if");
            let element = document.getElementById('signout-modal-container');

            window.addEventListener('click', function (e) {

                // console.log("inside event listener");
                if (element && element.contains(e.target)) {


                    // console.log("inside click");
                    // let crossIconElement = this.document.getElementById("cross-item-icon");
                    // if (crossIconElement && crossIconElement.contains(e.target)) {
                    //     setIsMoreItemsVisibe(false);
                    // }
                } else {
                    // console.log("outside click");
                    setSignoutModal(false);
                }
            });
            return;
        }
    }
    const handleWardChange = (item) => {
        setSelectedWard(item);
        props.selectedWardAction(item);
    }

    return (
        <>
            <div className={modalConfig && modalConfig.isOpen ? 'home-page blur' : 'home-page'}>
                {/* {console.log("rerendering HomePage!!!!")} */}
                <Header
                    handleWardChange={(item) => handleWardChange(item)}
                    wardOption={wardOption}
                    selectedWard={selectedWard}
                    hospitalName={hospitalName}
                    isDisabled={props.config.viewType === VIEW_TYPES.LIST_VIEW}
                />
                <SideBar handleHelpClick={handleHelpClick} />
                <div className='home-page-body-container'>
                    {/* <SubHeader data={props.wardDetails} /> */}
                    {/*<div className='toggle-filter-container'>
                        <div className='toggle-switch-container'>
                            <span className='grid-view-text'>Grid View</span>
                            <ToggleSwitch id="newsletter" checked={toggle} optionLabels={["", ""]} small={true} onChange={onToggleChange} />
                            <span className='list-view-text'>List View</span>
                        </div>
                        <div className='filter-container'>
                        <img src={filterSvg} alt="filter svg" onClick={() => console.log("filter clicked !!")} />
                        </div>
                        {toggle && <div className='add-column-container' id='add-columnn-container'>
                            <div className='add-column-button' onClick={(event) => handleAddColumnButtonClick(event)}>
                                +&ensp;Add Column
                            </div>
                            {showAddColumnOption && <div className='add-column-option-container'>
                                <div className='option-heading'>Patient Monitor</div>
                                {HOME_PAGE.ADD_COLUMN_CONSTANT.MONITOR.map((paramObj) => {
                                    return (
                                        <div className='add-column-option' key={paramObj.name}>
                                            <input
                                                type="checkbox"
                                                checked={ifAddColumnOptionChecked(paramObj)}
                                                onChange={(event) => handleAddColumnItemClick(event, paramObj)} />
                                            <div className='option-label'>{paramObj.label}</div>
                                        </div>);
                                })}
                                <div className='option-heading'>Ventilator</div>
                                {HOME_PAGE.ADD_COLUMN_CONSTANT.VENTILATOR.map((paramObj) => {
                                    return (
                                        <div className='add-column-option' key={paramObj.name}>
                                            <input
                                                type="checkbox"
                                                checked={ifAddColumnOptionChecked(paramObj)}
                                                onChange={(event) => handleAddColumnItemClick(event, paramObj)} />
                                            <div className='option-label'>{paramObj.label}</div>
                                        </div>);
                                })}
                            </div>}
                        </div>}
                            </div>*/}
                    {selectedWard && <BedContainer selectedWard={selectedWard} />}
                </div>
            </div>

            {signoutModal &&
                <div className='signout-modal-container'
                    id="signout-modal-container">
                    <div className='modal-content-container'>
                        <span className='signout-text'>Sign Out ?</span>
                        <span className='signout-confirm-text'>Are you sure you want to Sign Out?</span>
                        <span className='signout-final-statement'>
                            You can Sign In with the credentials provided to access the dashboard.
                        </span>
                        <div className='signout-btn-container'>
                            <div className='common-signout-btn' onClick={handleSignoutCancel}>Cancel</div>
                            <div className='common-signout-btn active-signout-btn' onClick={handleSigoutClick}>SignOut</div>
                        </div>
                    </div>
                </div>
            }


            {modalConfig && modalConfig.isOpen && <PatientModal optionType={modalConfig.optionType} bedId={modalConfig.bedId} />}
        </>
    );
}

const mapStateToProps = state => {
    return {
        // bedDetails: state.bedDetailsReducer.bedDetails,
        wardDetails: state.wardDetailsReducer.wardDetails,
        config: state.configReducer.config,
        hospitalData: state.hospitalDataReducer.hospitalData,
        selectedWard: state.wardDetailsReducer.selectedWard,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hospitalDataAction: (payload) => dispatch(hospitalDataAction(payload)),
        bedDetailAction: (payload) => dispatch(bedDetailAction(payload)),
        selectedWardAction: (payload) => dispatch(selectedWardAction(payload)),
        bedIdAction: (payload) => dispatch(bedIdAction(payload)),
        wardDetailsAction: (payload) => dispatch(wardDetailsAction(payload)),
        patientDataAction: (payload) => dispatch(patientDataAction(payload)),
        deviceVitalDataAction: (payload) => dispatch(deviceVitalDataAction(payload)),
        deviceEcgWaveformDataAction: (payload) => dispatch(deviceEcgWaveformDataAction(payload)),
        deviceHrWaveformDataAction: (payload) => dispatch(deviceHrWaveformDataAction(payload)),
        alarmDataAction: (payload) => dispatch(alarmDataAction(payload)),
        notificationDataAction: (payload) => dispatch(notificationDataAction(payload)),
        filterAction: (payload) => dispatch(filterAction(payload)),
        addColumnBedListAction: (payload) => dispatch(addColumnBedListAction(payload)),
        removeColumnBedListAction: (payload) => dispatch(removeColumnBedListAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);