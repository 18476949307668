import React, { useEffect, useState } from "react";
import { Notes } from "../../components/Notes/Notes";
import "./NotesContainer.scss";

import attach_svg from "../../common/icons/svg/attach.svg";
import voice_svg from "../../common/icons/svg/voice.svg";
import moment from "moment";
import { NOTES_TYPE, PAGE_PARENT } from "../../common/constant/common-constants";
import { getNotes, postNotes } from "../../helper/notes-helper";
import { connect } from "react-redux";
import { NOTES_CONSTANT } from "../../common/constant/page-constants";
import { StatefulEditor } from "../../components/StatefulEditor/StatefulEditor";
import { DraftEditor } from "../../components/StatefulEditor/DraftEditor";
import { handleScrollToBottom } from "../../common/utils/CommonUtils";

function NotesContainer(props) {

    const [notesData, setNotesData] = useState([]);
    const [notesType, setNotesType] = useState();
    const [activeNote, setActiveNote] = useState(notesData && notesData[0]);
    const [isAddNote, setIsAddNote] = useState(false);
    const [newNote, setNewNote] = useState(null);

    console.log("props of notes container", props)

    useEffect(() => {
        setNotesType(props.notesType);
    }, [props.notesType]);

    useEffect(() => {
        handleScrollToBottom(NOTES_CONSTANT.NOTES_DATA_ID)
    }, [notesData]);

    useEffect(() => {
        updateNotesData();

    }, []);

    const updateNotesData = () => {
        let data = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            type: props.notesType
        }

        getNotes(data).then((res) => {
            // setNotesData(notes);

            if (res.notes) {
                setNotesData(res.notes);
                setActiveNote(res.notes[0]);
            }

        });
    }

    const postNotesData = (value) => {

        let data = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            type: props.notesType,
            message: value
        }

        postNotes(data).then((res) => {
            console.log(res);
            updateNotesData();
        });
    }

    const handleKeyDown = (event) => {
        const isEnterKey = (event.keyCode === 13);
        if (isEnterKey && !event.shiftKey) {
            let value = event && event.target && event.target.value;
            if (!value) return;
            postNotesData(value);
            event.target.value = "";
            return false;
        }
    }

    const handleNoteChange = (note) => {
        setNewNote(note);
    }

    const handleNoteCancel = () => {

        setIsAddNote(false);
    }

    const handleNoteSave = () => {

        setIsAddNote(false);
        postNotesData(newNote);
        console.log(newNote);
    }

    return (
        <>
            {(props.parent === PAGE_PARENT.PATIENT_MODAL) && <div className="notes-container">
                <div className="notes-header-container">
                    <div className="notes-text">{notesType === NOTES_TYPE.NURSE ? 'Nurse Notes' : 'Doctor Notes'}</div>
                    <div className="notes-count-text">{`(${notesData && notesData.length})`}</div>
                </div>
                <div className="notes-data-container" id={NOTES_CONSTANT.NOTES_DATA_ID}>
                    {
                        notesData.map((note) => {
                            return (
                                <Notes
                                    key={note.id}
                                    data={note}
                                    notesType={props.notesType}
                                    type={NOTES_CONSTANT.MEDIUM} />)
                        })
                    }
                </div>
                <div className="input-container">
                    <div className="logo input-logo">
                        <img src={attach_svg} alt="attach svg" onClick={() => console.log("logo clicked !! ")} />
                        <img src={voice_svg} alt="voice svg" onClick={() => console.log("logo clicked !! ")} />
                    </div>
                    <textarea className="notes-input" placeholder="Type here" onKeyDown={(e) => handleKeyDown(e)}></textarea>
                    {/* <input type="text" className="notes-input" placeholder="Type here" onKeyDown={(e) => handleKeyDown(e)} /> */}
                </div>
            </div>}
            {(props.parent === PAGE_PARENT.BED_DETAIL) && <div className="bed-details-notes-container">
                <div className="notes-left-panel">
                    <div className="notes-header-container">
                        <div className="notes-text">{notesType === NOTES_TYPE.NURSE ? 'Nurse Notes' : 'Doctor Notes'}</div>
                        <div className="notes-count-text">{`(${notesData && notesData.length})`}</div>
                    </div>
                    <div className="notes-data-container">
                        {
                            notesData.map((note) => {
                                return (
                                    <Notes
                                        key={note.id}
                                        data={note}
                                        notesType={props.notesType}
                                        type={NOTES_CONSTANT.SMALL}
                                        active={activeNote.id === note.id}
                                        handleClick={(note) => {
                                            setIsAddNote(false)
                                            setActiveNote(note)
                                        }}
                                    />);
                            })
                        }
                    </div>
                    <div className="add-note-button-container">
                        <div className="add-note-button" onClick={() => setIsAddNote(true)}>
                            Add a Note
                        </div>
                    </div>
                </div>
                <div className="notes-right-panel">
                    {activeNote && !isAddNote && <Notes
                        key={activeNote.id}
                        data={activeNote}
                        notesType={props.notesType}
                        type={NOTES_CONSTANT.LARGE}
                    />}
                    {isAddNote &&
                        <div className="add-note-container">
                            <div className="add-note-header">
                                <div className="add-note-text">
                                    New Note
                                </div>
                                <div className="add-note-cta-container">
                                    <div
                                        className="add-note-cta cancel"
                                        onClick={() => handleNoteCancel()}>Cancel</div>
                                    <div
                                        className="add-note-cta save"
                                        onClick={() => handleNoteSave()}>Save</div>
                                </div>
                            </div>
                            <div className="editor-container">
                                <StatefulEditor onChange={(data) => handleNoteChange(data)} />
                            </div>
                        </div>}
                    {/* {isAddNote && <DraftEditor />} */}
                </div>
            </div>}
        </>
    );
}

const mapStateToProps = (state, ownProps) => {

    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;
    return {
        patientSessionId: patientSessionId,
        config: state.configReducer.config
    };
};

export default connect(mapStateToProps, null)(NotesContainer);