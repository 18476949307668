import moment from "moment";
import React from "react";
import parse from 'html-react-parser';
import { NOTES_CONSTANT } from "../../common/constant/page-constants";
import "./Notes.scss";

export function Notes(props) {

    const type = props.type || NOTES_CONSTANT.MEDIUM;

    return (
        // #666666
        <div
            className={props.active ? `notes ${type} active` : `notes ${type}`}
            onClick={() => { if (props.handleClick) props.handleClick(props.data) }}>
            <div className="notes-header">
                <span className="notes-date">{moment(props.data.createdAt).format("DD MMMM YYYY")}</span>
                {/* <span className="pipe">|</span> */}
                <span className="notes-time">{moment(props.data.createdAt).format("HH:mm a")}</span>
                {type !== NOTES_CONSTANT.SMALL && <span className="notes-user">
                    {`${props.notesType}: ${props.data.createdByUserName} (${props.data.createdByUserId})`}
                </span>}
                {/* <div className="notes-date-time">
                    {`${props.data.date}   |   ${props.data.time}`}
                </div> */}
            </div>
            <div className="notes-content">
                {parse(props.data.message)}
            </div>
        </div>
    );
}