import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PAGE_PARENT, PRESCRIPTION_OPTIONS } from "../../common/constant/common-constants";
import { AddPrescription } from "../../components/AddPrescription/AddPrescription";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { PrescriptionTable } from "../../components/Table/Prescription/PrescriptionTable";

import plusVoiletSvg from "../../common/icons/svg/plus-voilet.svg";
import minusRedSvg from "../../common/icons/svg/minus-red.svg";

import "./PrescriptionContainer.scss";
import DatePicker from "react-date-picker";
import moment from "moment";
import {
    addPrescriptionHelper,
    getPrescriptionHelper,
    updatePrescriptionHelper
} from "../../helper/prescription-helper";

function PrescriptionContainer(props) {
    const dateFormat = "YYYY/MM/DD";
    const routeOptions = [
        { name: 'oral', label: 'Oral' },
        { name: 'iv', label: 'Intravenous (IV)' },
        { name: 'intrathecal', label: 'Intrathecal' },
        { name: 'sc', label: 'Subcutaneous (SC)' },
        { name: 'ng', label: 'Nasogastric (NG)' },
        { name: 'im', label: 'Intramuscular (IM)' },
        { name: 'sl', label: 'Sublingual (SL)' },
        { name: 'pr', label: 'Per Rectum (PR)' },
        { name: 'top', label: 'Topical (TOP)' },
        { name: 'pv', label: 'Per Vagina (PV)' },
    ];
    const drugNameOptions = [
        { name: 'dolo_365', label: 'Dolo 650 Tablet' },
        { name: 'pantop_d_capsule', label: 'Pantop-D Capsule' },
    ];
    const frequencyOptions = [
        { name: 'oid', label: 'OiD' },
        { name: 'bid', label: 'BiD' },
        { name: 'tid', label: 'TiD' },
        { name: 'qid', label: 'QiD' },
        { name: 'q4h', label: 'Q4H' },
        { name: 'q6h', label: 'Q6H' },
        { name: 'q8h', label: 'Q8H' },
    ];
    const durationOptions = [
        { name: '0.5', label: '0.5' },
        { name: '1', label: '1' },
        { name: '2', label: '2' },
        { name: '3', label: '3' },
        { name: '4', label: '4' }
    ]

    const infusionOptions = [
        { name: 'Normal Saline', label: 'Normal Saline' },
        { name: 'Half Normal Saline', label: 'Half Normal Saline' },
        { name: 'Lactated Ringers', label: 'Lactated Ringers' },
        { name: 'Dextrose', label: 'Dextrose' },
    ];

    const additiveListOptions = [
        { name: "0.9% NaCl", label: "0.9% NaCl" },
        { name: "Dextrose 5%", label: "Dextrose 5%" },
        { name: "0.45% NaCl", label: "0.45% NaCl" },
    ]

    const [isAddDrugOpen, setIsAddDrugOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(PRESCRIPTION_OPTIONS.DRUGS);
    const [drugName, setDrugName] = useState("");
    const [dosage, setDosage] = useState("");
    const [route, setRoute] = useState("");
    const [frequency, setFrequency] = useState("");
    const [remark, setRemark] = useState("");
    const [infusionName, setInfusionName] = useState("");
    const [volume, setVolume] = useState("");
    const [additive, setAdditive] = useState("");
    const [additiveList, setAdditiveList] = useState([]);
    const [rate, setRate] = useState("");
    const [duration, setDuration] = useState("");
    const [prescriptionDate, setPrescriptionDate] = useState(new Date());
    const [drugData, setDrugData] = useState([]);
    const [infusionData, setInfusionData] = useState([]);
    const [statOrderData, setStatOrderData] = useState([]);

    useEffect(() => {
        prescriptionDataHandler(selectedOption);
    }, [])

    useEffect(() => {
        prescriptionDataHandler(selectedOption);
    }, [selectedOption, prescriptionDate])

    const prescriptionDataHandler = (option) => {
        let reqData = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId,
            date: moment(prescriptionDate).format(dateFormat)
        }

        getPrescriptionHelper(reqData, option).then((data) => {

            if (option === PRESCRIPTION_OPTIONS.DRUGS) setDrugData(data);
            else if (option === PRESCRIPTION_OPTIONS.STAT_ORDERS) setStatOrderData(data);
            else if (option === PRESCRIPTION_OPTIONS.INFUSION) setInfusionData(data);
        }).catch((err) => {
            console.log("err in getting prescription", err);
        })
    }

    const handleAddPrescription = (data) => {
        let reqData = getAddPrescriptionData();

        console.log("prescription req data:: ", reqData);
        addPrescriptionHelper(reqData, selectedOption).then((res) => {

            prescriptionDataHandler(selectedOption);
            console.log(res);
            setDataEmpty();
        }).catch((err) => {
            console.log(err);
        });
    }

    const setDataEmpty = () => {
        setDrugName("");
        setDosage("");
        setRoute("");
        setFrequency("");
        setRemark("");
        setInfusionName("");
        setVolume("");
        setAdditiveList([]);
        setAdditive("");
        setDuration("");
        setRate("");
    }

    const getAddPrescriptionData = () => {
        let reqObj = {
            patientSessionId: props.patientSessionId,
            bedId: props.bedId,
            date: moment(prescriptionDate).format(dateFormat)
        }

        if (selectedOption === PRESCRIPTION_OPTIONS.DRUGS) {
            reqObj = {
                ...reqObj,
                name: drugName.name,
                dosage: dosage,
                route: route.name,
                frequency: frequency.name,
                remarks: remark
            }
        } else if (selectedOption === PRESCRIPTION_OPTIONS.STAT_ORDERS) {
            reqObj = {
                ...reqObj,
                name: drugName.name,
                dosage: dosage,
                route: route.name,
                frequency: frequency.name,
                remarks: remark
            }
        } else if (selectedOption === PRESCRIPTION_OPTIONS.INFUSION) {

            let al = additiveList;

            if (additive && dosage) {
                al.push({
                    name: additive.name,
                    dosage: dosage
                });
            }

            reqObj = {
                ...reqObj,
                name: infusionName.name,
                volume: volume,
                additives: al,
                duration: duration.name,
                frequency: frequency.name,
                rate: rate,
                remarks: remark
            }
        }

        return reqObj;
    }

    const handleUpdatePrescription = (data) => {

        let reqData = {
            prescriptionId: data.id,
            action: data.action
        }

        console.log(reqData);

        updatePrescriptionHelper(reqData, selectedOption)
            .then((res) => {
                console.log(res);
                prescriptionDataHandler(selectedOption);
            }).catch((err) => {
                console.log(err);
            })
    }

    const handleCancelPrescription = () => {
        setIsAddDrugOpen(false);
    }

    const handleOptionClick = (option) => {
        setDataEmpty();
        setSelectedOption(option);
    }

    const handleAddDrugClick = () => {

        setIsAddDrugOpen(!isAddDrugOpen);
    }

    const getPrescriptionData = () => {

        switch (selectedOption) {
            case PRESCRIPTION_OPTIONS.DRUGS:
                {
                    return drugData;
                }
            case PRESCRIPTION_OPTIONS.INFUSION:
                {
                    return infusionData;
                }
            case PRESCRIPTION_OPTIONS.STAT_ORDERS:
                {
                    return statOrderData;
                }
        }
    }

    const handleAddAdditiveClick = () => {

        if (additive && dosage) {
            let al = additiveList;
            al.push({
                name: additive.name,
                dosage: dosage
            });

            setAdditiveList([...al]);
            setAdditive("");
            setDosage("");
        }
    }

    const handleRemoveAdditiveClick = (additiveName) => {

        let al = additiveList;
        if (al && al.length > 0) {
            let index = -1;
            al.forEach((element, count) => {
                if (element.name === additiveName) index = count;
            });

            al.splice(index, 1);
            setAdditiveList([...al]);
        }
    }

    return (
        <div className="prescription-container">
            <div className="prescription-header-container">
                {props.parent === PAGE_PARENT.PATIENT_MODAL && <div className="prescription-text">
                    Prescription
                </div>}
                {/**  for bed detail page */}
                {props.parent === PAGE_PARENT.BED_DETAIL && <div className="prescription-option-container">
                    <div
                        className={selectedOption === PRESCRIPTION_OPTIONS.DRUGS ?
                            "prescription-option selected-option" : "prescription-option"}
                        onClick={() => handleOptionClick(PRESCRIPTION_OPTIONS.DRUGS)}
                    >{PRESCRIPTION_OPTIONS.DRUGS}</div>
                    <div
                        className={selectedOption === PRESCRIPTION_OPTIONS.INFUSION ?
                            "prescription-option selected-option" : "prescription-option"}
                        onClick={() => handleOptionClick(PRESCRIPTION_OPTIONS.INFUSION)}
                    >{PRESCRIPTION_OPTIONS.INFUSION}</div>
                    <div
                        className={selectedOption === PRESCRIPTION_OPTIONS.STAT_ORDERS ?
                            "prescription-option selected-option" : "prescription-option"}
                        onClick={() => handleOptionClick(PRESCRIPTION_OPTIONS.STAT_ORDERS)}
                    >{PRESCRIPTION_OPTIONS.STAT_ORDERS}</div>
                </div>}
                <div className="date-container">
                    {/* <div className="date-text">Date: </div> */}
                    <DatePicker
                        className="prescription-date"
                        clearIcon={null}
                        onChange={setPrescriptionDate}
                        value={prescriptionDate}
                        openCalendarOnFocus={true}
                        format="dd-MM-yyyy"
                    />
                </div>
            </div>
            <div className="prescription-option-container">
                {props.parent === PAGE_PARENT.BED_DETAIL && isAddDrugOpen &&
                    <div className="new-medication-text">New Medication</div>}

                {/*** for homepage modal */}
                {props.parent === PAGE_PARENT.PATIENT_MODAL && <div className="option-container">
                    <div
                        className={selectedOption === PRESCRIPTION_OPTIONS.DRUGS ?
                            "prescription-option selected-option" : "prescription-option"}
                        onClick={() => handleOptionClick(PRESCRIPTION_OPTIONS.DRUGS)}
                    >{PRESCRIPTION_OPTIONS.DRUGS}</div>
                    <div
                        className={selectedOption === PRESCRIPTION_OPTIONS.INFUSION ?
                            "prescription-option selected-option" : "prescription-option"}
                        onClick={() => handleOptionClick(PRESCRIPTION_OPTIONS.INFUSION)}
                    >{PRESCRIPTION_OPTIONS.INFUSION}</div>
                    <div
                        className={selectedOption === PRESCRIPTION_OPTIONS.STAT_ORDERS ?
                            "prescription-option selected-option" : "prescription-option"}
                        onClick={() => handleOptionClick(PRESCRIPTION_OPTIONS.STAT_ORDERS)}
                    >{PRESCRIPTION_OPTIONS.STAT_ORDERS}</div>
                </div>}
                <div className="add-drug-container">
                    {!isAddDrugOpen &&
                        <div className="add-drug-button-container" onClick={() => handleAddDrugClick()}>
                            <div className="add-drug-button">Add Drug</div>
                        </div>}
                    {isAddDrugOpen &&
                        <div className="submit-container">
                            <div
                                className="cancel-button"
                                onClick={() => handleCancelPrescription()}>
                                Cancel
                            </div>
                            <div
                                className="submit-button"
                                onClick={(event) => handleAddPrescription(event.target.value)}>
                                Save
                            </div>
                        </div>}
                </div>
            </div>
            {isAddDrugOpen && <div className="add-prescription-container">
                {
                    props.parent === PAGE_PARENT.PATIENT_MODAL &&
                    <div className="new-medication-text">New Medication</div>
                }
                {(
                    [PRESCRIPTION_OPTIONS.DRUGS, PRESCRIPTION_OPTIONS.STAT_ORDERS]
                        .includes(selectedOption)
                ) &&
                    <div className="add-prescrition-form drug-form">
                        <div className="drug-name-container">
                            <label>Drug Name</label>
                            <CustomSelect
                                id="drug-name"
                                handleChange={(item) => setDrugName(item)}
                                label='label'
                                value={drugName}
                                options={drugNameOptions}
                                placeholder='Drug Name'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="dosage-container">
                            <label>Dosage (in mg)</label>
                            <input
                                className="prescription-input dosage"
                                placeholder="Specify the dose in mg"
                                value={dosage}
                                onChange={(event) => setDosage(event.target.value)} />
                        </div>
                        <div className="route-container">
                            <label>Route</label>
                            <CustomSelect
                                id="route"
                                handleChange={(item) => setRoute(item)}
                                label='label'
                                value={route}
                                options={routeOptions}
                                placeholder='Route'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="frequency-container">
                            <label>Frequency</label>
                            <CustomSelect
                                id="frequency"
                                handleChange={(item) => setFrequency(item)}
                                label='label'
                                value={frequency}
                                options={frequencyOptions}
                                placeholder='Frequency'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="remarks-container">
                            <label>Remarks</label>
                            <input
                                className="prescription-input remarks"
                                placeholder="Remarks"
                                value={remark}
                                onChange={(event) => setRemark(event.target.value)} />
                        </div>
                    </div>}
                {(selectedOption === PRESCRIPTION_OPTIONS.INFUSION) &&
                    <div className="add-prescrition-form infusion-form">
                        <div className="infusion-name-container">
                            <label>Diluent</label>
                            <CustomSelect
                                id="infusion-name"
                                handleChange={(item) => setInfusionName(item)}
                                options={infusionOptions}
                                placeholder='Diluent name'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="volume-container">
                            <label>Volume (in ml)</label>
                            <input
                                className="prescription-input volume"
                                placeholder="Specify volume in ml"
                                onChange={(event) => setVolume(event.target.value)}
                            />
                        </div>
                        {
                            additiveList && additiveList.map((additiveData) => {
                                return <>
                                    <div className="additive-container" key={additiveData.name}>
                                        <label>Drug</label>
                                        <input
                                            className="prescription-input additive-name"
                                            value={additiveData.name}
                                            // handleChange={(item) => setAdditive(item.name)}
                                            // label='Volume'
                                            // options={[{ name: 'Additive-1', label: 'Additive 1' }, { name: 'Additive-2', label: 'Additive 2' }]}
                                            placeholder='Additive'
                                            readOnly
                                        />
                                    </div>
                                    <div className="infusion-dosage-container">
                                        <label>Infusion Dosage (in mg)</label>
                                        <input
                                            className="prescription-input infusion-dosage"
                                            placeholder="Dosage (mg)"
                                            value={additiveData.dosage}
                                            readOnly
                                        // onChange={(event) => setDosage(event.target.value)} 
                                        />
                                    </div>
                                    <div className="remove-additive-container" onClick={() => handleRemoveAdditiveClick(additiveData.name)}>
                                        <img src={minusRedSvg} className="remove-icon" />
                                    </div>
                                </>
                            })
                        }
                        <div className="additive-container">
                            <label>Drug</label>
                            <CustomSelect
                                id="additive"
                                handleChange={(item) => setAdditive(item)}
                                value={additive}
                                options={additiveListOptions}
                                placeholder='Drug'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="infusion-dosage-container">
                            <label>Infusion Dosage (in mg)</label>
                            <input
                                className="prescription-input infusion-dosage"
                                value={dosage}
                                placeholder="Dosage (mg)"
                                onChange={(event) => setDosage(event.target.value)} />
                        </div>
                        <div className="add-additive-container" onClick={() => handleAddAdditiveClick()}>
                            <img src={plusVoiletSvg} className="add-icon" />
                            <div className="add-additive-text">Additive</div>
                        </div>
                        <div className="rate-container">
                            <label >Rate</label>
                            <input
                                className="prescription-input rate"
                                value={rate}
                                placeholder="Specify rate in ml/hr"
                                onChange={(event) => setRate(event.target.value)}
                            />
                            {/* <CustomSelect
                            id="rate"
                            handleChange={(item) => setRate(item.name)}
                            // label='Volume'
                            options={[{ name: 'Rate-1', label: 'Rate 1' }, { name: 'Rate-2', label: 'Rate 2' }]}
                            placeholder='Rate'
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        /> */}
                        </div>
                        <div className="duration-container">
                            <label>Duration</label>
                            <CustomSelect
                                id="duration"
                                handleChange={(item) => setDuration(item)}
                                value={duration}
                                // label='Volume'
                                options={durationOptions}
                                placeholder='Duration'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="frequency-container">
                            <label >Frequency</label>
                            <CustomSelect
                                id="frequency"
                                handleChange={(item) => setFrequency(item)}
                                value={frequency}
                                options={frequencyOptions}
                                placeholder='Frequency'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                className="input-select-class"
                            />
                        </div>
                        <div className="infusion-remarks-container">
                            <label>Remarks</label>
                            <input
                                className="prescription-input infusion-remarks"
                                placeholder="Remarks"
                                value={remark}
                                onChange={(event) => setRemark(event.target.value)} />
                        </div>
                    </div>}
            </div>}
            <div className="prescription-history-table">
                <PrescriptionTable
                    data={getPrescriptionData()}
                    date={prescriptionDate}
                    type={selectedOption}
                    handleUpdatePrescription={(data) => handleUpdatePrescription(data)} />
            </div>
        </div>)
}

const mapStateToProps = (state, ownProps) => {

    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;

    return {
        patientSessionId: patientSessionId,
        config: state.configReducer.config
    };
};

export default connect(mapStateToProps, null)(PrescriptionContainer);