import axios from "axios";
import "../common/axiosInterceptor/axios-interceptor";

// const BASE_URL = "http://localhost:8080/cms/api/";
const BASE_URL = "https://backend-cms.noccarc-web.com/cms/api/";

export const loginAPI = async (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}users/signin`,
        data: data
    })
}

export const loginTrackAPI = async (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}hospitals/cmsUsageTracking`,
        data: data,
        authRequired: true
    })
}

export const generateEcgChartAPI = async (data) => {

    return axios({
        method: 'post',
        url: `${BASE_URL}hospitals/generateEcgReport`,
        data: data,
        authRequired: true
    })
}

export const generateVitalsPdfAPI = async (data) => {
    return axios({
        method: 'post',
        url: `${BASE_URL}hospitals/generateReportVitalPdf`,
        data: data,
        authRequired: true
    })
}

export const getHospitalDetailsAPI = (data) => {

    return axios({
        method: "get",
        url: `${BASE_URL}hospitals/details`,
        data: data,
        authRequired: true
    })
}

export const getNotesAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/getNotes/`,
        data: data,
        authRequired: true
    })
}

export const postNotesAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/notes`,
        data: data,
        authRequired: true
    })
}

export const getChartVitalsAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getVitals`,
        data: data,
        authRequired: true
    })
}

export const getChartRSAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getRS`,
        data: data,
        authRequired: true
    })
}

export const getChartCVSAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getCVS`,
        data: data,
        authRequired: true
    })
}

export const getChartCNSAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getCNS`,
        data: data,
        authRequired: true
    })
}

export const getChartNursingCheckAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getNurseChecks`,
        data: data,
        authRequired: true
    })
}

export const getChartDrugAdministration = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getDrugAdministrations`,
        data: data,
        authRequired: true
    })
}

export const updateChartVitalsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateVitals`,
        data: data,
        authRequired: true
    })
}

export const updateChartRSAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateRS`,
        data: data,
        authRequired: true
    })
}

export const updateChartCVSAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateCVS`,
        data: data,
        authRequired: true
    })
}

export const updateChartCNSAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateCNS`,
        data: data,
        authRequired: true
    })
}

export const updateChartNurseCheckAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateNurseChecks`,
        data: data,
        authRequired: true
    })
}

export const updateChartDrugAdministration = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateDrugAdministrations`,
        data: data,
        authRequired: true
    })
}

export const addDrugAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/prescription/addDrug`,
        data: data,
        authRequired: true
    })
}

export const getDrugsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/prescription/getDrugs`,
        data: data,
        authRequired: true
    })
}

export const updateDrugAPI = (data) => {
    return axios({
        method: "put",
        url: `${BASE_URL}hospitals/prescription/updateDrug`,
        data: data,
        authRequired: true
    })
}

export const addStatOrderAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/prescription/addStatOrder`,
        data: data,
        authRequired: true
    })
}

export const getStatOrdersAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/prescription/getStatOrders`,
        data: data,
        authRequired: true
    })
}

export const updateStatOrderAPI = (data) => {
    return axios({
        method: "put",
        url: `${BASE_URL}hospitals/prescription/updateStatOrder`,
        data: data,
        authRequired: true
    })
}

export const addInfusionAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/prescription/addInfusion`,
        data: data,
        authRequired: true
    })
}

export const getInfusionsAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/prescription/getInfusions`,
        data: data,
        authRequired: true
    })
}

export const updateInfusionAPI = (data) => {
    return axios({
        method: "put",
        url: `${BASE_URL}hospitals/prescription/updateInfusion`,
        data: data,
        authRequired: true
    })
}

export const getChartTimelineAPI = (data) => {
    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getTimeline`,
        data: data,
        authRequired: true
    })
}

export const getAlarmHistoryAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/alarmHistory/getAlarms`,
        data: data,
        authRequired: true
    })
}

export const getIOBalanceAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getIOBalance`,
        data: data,
        authRequired: true
    })
}

export const updateIOBalanceAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateIOBalance`,
        data: data,
        authRequired: true
    })
}
export const updateClinicalScoreAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/updateClinicalScores`,
        data: data,
        authRequired: true
    })
}

export const getClinicalScoreAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/charting/getClinicalScores`,
        data: data,
        authRequired: true
    })
}

export const admitPatientAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/admitPatient`,
        data: data,
        authRequired: true
    })
}

export const dischargePatientAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/dischargePatient`,
        data: data,
        authRequired: true
    })
}

export const getTrendDataAPI = (data) => {

    return axios({
        method: "post",
        url: `${BASE_URL}hospitals/vitalTrends`,
        data: data,
        authRequired: true
    })
}
