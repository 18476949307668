import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Header } from "../../components/Header/Header";
import PatientDetails from "../../components/PatientDetails/PatientDetails";
import { SideBar } from "../../components/SideBar/Sidebar";
import AlarmHistoryContainer from "../../container/AlarmHistoryContainer/AlarmHistoryContainer";
import ChartContainer from "../../container/ChartContainer/ChartContainer";
import MonitorDetail from "../../container/MonitorDetail/MonitorDetail";
import NotesContainer from "../../container/NotesContainer/NotesContainer";
import PrescriptionContainer from "../../container/PrescriptionContainer/PrescriptionContainer";
import TrendContainer from "../../container/TrendContainer/TrendContainer";
import VentilatorDataContainer from "../../container/VentilatorDataContainer/VentilatorDataContainer";

import { BED_DETAILS_MODULE, NOTES_TYPE, PAGE_PARENT } from "../../common/constant/common-constants";
import { PAGE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";

import cross_svg from "../../common/icons/svg/cross.svg";

import "./BedDetails.scss";
import { bedDetailConfigAction } from "../../modules/actions/action";
import { closeSocketConnection, openSocketConnection } from "../../socket/socket";

function BedDetails(props) {
    const navigate = useNavigate();
    const params = useParams();

    const [activeModule, setActiveModule] = useState(BED_DETAILS_MODULE.PATIENT_DETAILS);
    const [ward, setWard] = useState(null);
    const [bedId, setBedId] = useState(null);

    useEffect(() => {
        // setActionForSocket();
        openSocketConnection();
        return () => {
            closeSocketConnection();
        }

    }, []);

    useEffect(() => {
        let id = params.id;
        setBedId(id);
    }, []);

    useEffect(() => {

        if (props.bedDetailConfig) setActiveModule(props.bedDetailConfig.activeModule);
    }, [props.bedDetailConfig])

    const handleModuleHeadingClick = (item) => {

        props.bedDetailConfigAction({
            activeModule: item
        });

        // setActiveModule(item);
    }

    const handleCrossClick = () => {
        redirect(navigate, PAGE_CONST.HOME_PAGE, null);
    }

    return (
        <div className="bed-details">
            <Header handleWardChange={(item) => setWard(item)} />
            <SideBar />
            <div className="bed-details-body-container">
                <div className="bed-header">
                    <div className="bed-id">{`Bed ${bedId}`}</div>
                    <div className="cross-img-container" onClick={() => handleCrossClick()}>
                        <img src={cross_svg} alt="cross icon" />
                    </div>
                </div>
                <div className="module-heading-container">
                    <div
                        className={activeModule === BED_DETAILS_MODULE.PATIENT_DETAILS ?
                            "patient-details module-heading selected" : "patient-details module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.PATIENT_DETAILS)}
                    >
                        {BED_DETAILS_MODULE.PATIENT_DETAILS}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.MONITOR ?
                            "monitor module-heading selected" : "monitor module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.MONITOR)}
                    >
                        {BED_DETAILS_MODULE.MONITOR}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.VENTILATOR ?
                            "ventilator module-heading selected" : "ventilator module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.VENTILATOR)}
                    >
                        {BED_DETAILS_MODULE.VENTILATOR}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.ALARMS ?
                            "alarms module-heading selected" : "alarms module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.ALARMS)}
                    >
                        {BED_DETAILS_MODULE.ALARMS}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.TRENDS_REPORTS ?
                            "trends-reports module-heading selected" : "trends-reports module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.TRENDS_REPORTS)}
                    >
                        {BED_DETAILS_MODULE.TRENDS_REPORTS}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.NURSE_NOTES ?
                            "nurse-notes module-heading selected" : "nurse-notes module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.NURSE_NOTES)}
                    >
                        {BED_DETAILS_MODULE.NURSE_NOTES}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.DOCTOR_NOTES ?
                            "doctor-notes module-heading selected" : "doctor-notes module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.DOCTOR_NOTES)}
                    >
                        {BED_DETAILS_MODULE.DOCTOR_NOTES}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.CHARTING ?
                            "charting module-heading selected" : "charting module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.CHARTING)}
                    >
                        {BED_DETAILS_MODULE.CHARTING}
                    </div>
                    <div
                        className={activeModule === BED_DETAILS_MODULE.PRESCRIPTION ?
                            "prescription module-heading selected" : "prescription module-heading"}
                        onClick={() => handleModuleHeadingClick(BED_DETAILS_MODULE.PRESCRIPTION)}
                    >
                        {BED_DETAILS_MODULE.PRESCRIPTION}
                    </div>
                </div>
                <div className="module-container">
                    {activeModule === BED_DETAILS_MODULE.PATIENT_DETAILS &&
                        <PatientDetails
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                    {activeModule === BED_DETAILS_MODULE.MONITOR &&
                        <MonitorDetail
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                    {activeModule === BED_DETAILS_MODULE.VENTILATOR &&
                        <VentilatorDataContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                    {activeModule === BED_DETAILS_MODULE.ALARMS &&
                        <AlarmHistoryContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                    {activeModule === BED_DETAILS_MODULE.CHARTING &&
                        <ChartContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                    {activeModule === BED_DETAILS_MODULE.NURSE_NOTES &&
                        <NotesContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                            notesType={NOTES_TYPE.NURSE}
                        />}
                    {activeModule === BED_DETAILS_MODULE.DOCTOR_NOTES &&
                        <NotesContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                            notesType={NOTES_TYPE.DOCTOR}
                        />}
                    {activeModule === BED_DETAILS_MODULE.TRENDS_REPORTS &&
                        <TrendContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                    {activeModule === BED_DETAILS_MODULE.PRESCRIPTION &&
                        <PrescriptionContainer
                            parent={PAGE_PARENT.BED_DETAIL}
                            bedId={bedId}
                        />}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        bedDetailConfig: state.configReducer.config.bedDetailConfig
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        bedDetailConfigAction: (payload) => dispatch(bedDetailConfigAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BedDetails);