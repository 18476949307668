import moment from "moment";
import { useEffect, useState } from "react";
import { CHART_OPTIONS } from "../../../common/constant/common-constants";
import { CHART_CONST } from "../../../common/constant/page-constants";
import "./ChartTable.scss";

export function ChartTable(props) {
    const [headerList, setHeaderList] = useState(props.headerList);
    const [parameterList, setParameterList] = useState(props.parameterList);

    const [data, setData] = useState();

    useEffect(() => {
        if (props.headerList) setHeaderList([...props.headerList]);
        setParameterList([...props.parameterList]);
        setData({ ...props.data });
    }, [props]);

    const handleChange = (type, id, key, event) => {

        let header = id;
        if (headerList.indexOf(id) > -1) {
            header = id;
            id = CHART_CONST.NEW_ID_PRED + id;
            console.log(id);
        }
        let value = event && event.target && event.target.value;

        if (value === "on") value = event.target.checked;

        if (value === undefined) return;

        props.handleDataChange(id, header, key, value);
    }

    const getHtml = () => {

        let html = headerList && headerList.map((header, count) => {
            let headerTime = moment(header).format("HH:mm");

            /* for rendering parameters in first column */
            if (count === 0) {
                return (
                    <div className="column parameters" key={header}>
                        <div className="cell heading param">{header}</div>
                        {parameterList.map((param) => {
                            return (<div className="cell body param" key={param.label}>{param.label}</div>)
                        })}
                    </div>);
            } else {
                /** rendering data from the data array **/

                // console.log(data && data.data);
                let col = (
                    data && data.data &&
                    data.data.filter(
                        (obj) => {

                            return moment(obj.time).format("HH:mm") == headerTime;
                            console.log("obj.time:: " + obj.time +
                                " headerTime:: " + headerTime, "flag-> " + moment(obj.time, "HH:mm:ss").isSame(headerTime));
                            return moment(obj.time, "HH:mm:ss", true).isSame(headerTime);
                        }
                    )[0]
                ) || null;

                // console.log("", , "headerTime: ", headerTime, "col: ", col);
                return (
                    <div className="column" key={header}>
                        <div className="cell heading param">{moment(header).format("HH:mm")}</div>
                        {parameterList.map((param) => {
                            return (
                                <div className="cell body" key={param.name}>
                                    {col ? col[param.name] : ""}
                                </div>);
                        })}
                    </div>);
            }
        });

        return html;
    }

    const getNursingCheckHtml = () => {
        let html = headerList && headerList.map((header, count) => {
            // console.log("count", count);

            /* for rendering parameters in first column */
            if (count === 0) {
                return (
                    <div className="column parameters" key={count}>
                        <div className="cell heading param">{header}</div>
                        {parameterList.map((param) => {
                            return (<div className="cell body param" key={param.label}>{param.label}</div>)
                        })}
                    </div>);
            } else {
                /** rendering data from the data array **/
                let col = data && data.data && data.data[count - 1] &&
                    data.data.filter((obj) => obj.shift === header)[0];

                if (col) {
                    return (<div className="column" key={count}>
                        <div className="cell heading">{header}</div>
                        {parameterList.map((param) => {
                            if (param.type === "text") { return <div className="cell body" key={param.name}>{col[param.name]}</div> }
                            else if (param.type === "checkbox") {
                                return (
                                    <div className="cell body" key={param.name}>
                                        <input
                                            className={col["isUpdated"] || !props.isEditable ? "chart-checkbox disabled" : "chart-checkbox"}
                                            checked={col[param.name]}
                                            type="checkbox"
                                            aria-disabled={col["isUpdated"] || !props.isEditable}
                                            onChange={(event) => handleChange(CHART_CONST.UPDATE, col["id"], param.name, event)} />
                                    </div>);
                            }
                            else if (param.type === "double-checkbox") {
                                return (
                                    <div className="cell body double-checkbox-container" key={param.name}>
                                        <div className="first-checkbox double-checkbox-item">
                                            <input
                                                className="chart-radio"
                                                checked={col[param.name] === "PRESENT"}
                                                id={`col - present - ${param.name}${header} `}
                                                name={`${header} -${param.name} `}
                                                type="radio"
                                                value={'PRESENT'}
                                                aria-disabled={col["isUpdated"] || !props.isEditable}
                                                onChange={(event) => {
                                                    event.target.value = event.target.checked ? "PRESENT" : "ABSENT";
                                                    // if (this.checked) event.target.value = "PRESENT";
                                                    // else event.target.value = "";
                                                    handleChange(CHART_CONST.UPDATE, col["id"], param.name, event)
                                                }} />
                                            <label htmlFor={`col - present - ${param.name}${header} `} className="label">Present</label>
                                        </div>
                                        <div className="second-checkbox double-checkbox-item">
                                            <input
                                                className="chart-radio"
                                                checked={col[param.name] === "ABSENT"}
                                                id={`col - absent - ${param.name}${header} `}
                                                name={`${header} -${param.name} `}
                                                type="radio"
                                                value={'ABSENT'}
                                                aria-disabled={col["isUpdated"] || !props.isEditable}
                                                onChange={(event) => {
                                                    event.target.value = event.target.checked ? "ABSENT" : "PRESENT";
                                                    handleChange(CHART_CONST.UPDATE, col["id"], param.name, event)
                                                }} />
                                            <label htmlFor={`col - absent - ${param.name}${header} `} className="label">Absent</label>
                                        </div>
                                    </div>);
                            }
                        })}
                    </div>);
                } else {
                    return (<div className="column" key={count}>
                        <div className="cell heading">{header}</div>
                        {
                            parameterList.map((param, count) => {
                                if (param.type === "text") { return <div className="cell body" key={count}></div> }
                                else if (param.type === "checkbox") {
                                    return (<div className="cell body" key={param.name} >
                                        <input
                                            type="checkbox"
                                            className="chart-checkbox"
                                            aria-disabled={!props.isEditable ? true : false}
                                            onChange={(event) => handleChange(CHART_CONST.ADD, header, param.name, event)} />
                                    </div>);
                                }
                                else if (param.type === "double-checkbox") {
                                    return (
                                        <div className="cell body double-checkbox-container" key={param.name}>
                                            <div className="first-checkbox  double-checkbox-item">
                                                <input
                                                    className="chart-radio"
                                                    name={`${header} -${param.name} `}
                                                    id={`present - ${param.name}${header} `}
                                                    type="radio"
                                                    aria-disabled={!props.isEditable}
                                                    onChange={(event) => {
                                                        event.target.value = event.target.checked ? "PRESENT" : "ABSENT";
                                                        handleChange(CHART_CONST.ADD, header, param.name, event)
                                                    }} />
                                                <label htmlFor={`present - ${param.name}${header} `} className="label">Present</label>
                                            </div>
                                            <div className="second-checkbox  double-checkbox-item">
                                                <input
                                                    className="chart-radio"
                                                    id={`absent - ${param.name}${header} `}
                                                    name={`${header} -${param.name} `}
                                                    type="radio"
                                                    aria-disabled={!props.isEditable}
                                                    onChange={(event) => {
                                                        event.target.value = event.target.checked ? "ABSENT" : "PRESENT";
                                                        handleChange(CHART_CONST.ADD, header, param.name, event)
                                                    }} />
                                                <label htmlFor={`absent - ${param.name}${header} `} className="label">Absent</label>
                                            </div>
                                        </div>);
                                }
                            })
                        }
                    </div >);
                }
            }

        });

        return html;
    }

    return <>
        <div className="table">

            {
                (props.chartType === CHART_OPTIONS.NURSING_CHECKS) ?
                    getNursingCheckHtml() : getHtml()
            }
        </div>
    </>
}