import "./BedCardMonitor.scss"
import React, { forwardRef, memo, useState, useEffect, useMemo } from "react";
// import bellAlert from "../../common/icons/svg/bell-alert.svg";
import favoriteSvg from "../../common/icons/svg/favorite.svg";
import favoriteSelectedSvg from "../../common/icons/svg/favorite-selected.svg";
import rightArrowSvgSvg from "../../common/icons/svg/right-arrow-solid.svg";
// import blackDownArrow from "../../common/icons/svg/black-down-arrow.svg";
import whiteUpArrow from "../../common/icons/svg/white-up-arrow.svg";
import whiteDownArrow from "../../common/icons/svg/white-down-arrow.svg";
import noPatientInfoIcon from "../../common/icons/svg/no-patient-info-icon.svg";
import humanSvg from "../../common/icons/svg/human.svg";
import {
    ADD,
    ALARM_PRIORITY,
    MODAL_ITEMS,
    REMOVE,
    VIEW_TYPES,
    PATIENT_VITALS,
    MORE_GRAPH_OPTIONS,
    BED_CARD_VITALS,
    GRAPH_TYPE
} from "../../common/constant/common-constants";
import moment from "moment";
import ApexChart from "../Chart/ApexChart";
import { connect } from "react-redux";
import { viewTypeAction } from "../../modules/actions/action";
import { handleOutsideClick } from "../../common/utils/CommonUtils";
import usePrevious from '../../common/utils/hooks/usePrevious';


const BedCardMonitor = forwardRef((props, ref) => {
    const [showMoreAlarms, setShowMoreAlarms] = useState(null)
    const [selectMoreVitalGraph, setSelectMoreVitalGraph] = useState(null);
    const [ecgDataObj, setEcgDataObj] = useState([]);
    const [plethDataObj, setPlethDataObj] = useState([]);
    const [vitalGraphName, setVitalGraphName] = useState(MORE_GRAPH_OPTIONS.MONITOR[0]);
    const [lastAlarm, setLastAlarm] = useState();
    const [lastPushedPlethTime, setLastPushedPlethTime] = useState(null);
    const [lastPushedEcgTime, setLastPushedEcgTime] = useState(null);
    const [showAlarmData, setShowAlarmData] = useState(false);     //this is false for all the beds and turns on when the monitor is active
    const prevLastPushedEcgTime = usePrevious(lastPushedEcgTime);

    useEffect(() => {
        // when monitor is not connected to a, lastPushedEcgTime are null
        // so this runs after the monitor is disconnected. showAlarmData becomes true in this case.
        const interval = setInterval(() => {
            if (lastPushedEcgTime && prevLastPushedEcgTime) {
                // console.log("time", props.bedId, lastPushedEcgTime, prevLastPushedEcgTime)
                if (lastPushedEcgTime === prevLastPushedEcgTime) props.handleClearBedAlarm(props.bedId)
                else setShowAlarmData(true);
            } else {
                // props.handleClearBedAlarm(props.bedId);
                setShowAlarmData(false);
            }

        }, 500);
        return () => clearInterval(interval);

    }, [lastPushedEcgTime])

    useEffect(() => {

        if (props.alarmData && props.alarmData.length > 0) {
            let alarm = props.alarmData[0];

            let alarmClass = getAlarmPriorityClass(alarm);

            // console.log("alarm: ", props.alarmData[0]);
            // console.log("lastAlarm: ", lastAlarm);

            if (alarmClass === ALARM_PRIORITY.HIGH_PRIORITY &&
                (!lastAlarm || !(lastAlarm.name === alarm.name && lastAlarm.value === alarm.value))) {
                let snd = new Audio("https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3");
                snd.play();

                setLastAlarm(alarm);
            }
        }
    }, [props.alarmData]);

    const handleShowMoreAlarmsClick = (event) => {
        event.stopPropagation();
        setShowMoreAlarms(!showMoreAlarms);
        if (!showMoreAlarms) {
            handleOutsideClick(event, 'alarm-display-bar', setShowMoreAlarms);
        }
    }

    const handleSelectMoreVital = (event) => {
        event.stopPropagation();
        setSelectMoreVitalGraph(!selectMoreVitalGraph);
        if (!selectMoreVitalGraph) {
            handleOutsideClick(event, 'vital-graph-monitor', setSelectMoreVitalGraph);
        }

    }

    const handleVitalGraphSelect = (event, option) => {
        event.stopPropagation();
        setVitalGraphName(option);
        setSelectMoreVitalGraph(false);
    }


    const getAlarmPriorityClass = (alarmData) => {

        // let id = bedId && bedId.toString();

        switch (alarmData.priority) {
            case "HIGH":
                return ALARM_PRIORITY.HIGH_PRIORITY;
            case "MEDIUM":
                return ALARM_PRIORITY.MEDIUM_PRIORITY;
            case "LOW":
                return ALARM_PRIORITY.LOW_PRIORITY;
            default:
                return ALARM_PRIORITY.LOW_PRIORITY;
        }
    }

    useEffect(() => {

        if (props.isSelected) return;
        if ([GRAPH_TYPE.ECG].indexOf(vitalGraphName && vitalGraphName.type) < 0) return;

        let ecgDataList = [];
        let ecgWaveformData = props.monitorEcgWaveFormData
            && props.monitorEcgWaveFormData.waveform
            && props.monitorEcgWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (vitalGraphName && vitalGraphName.name)
                }
            )[0];

        if (ecgWaveformData && ecgWaveformData.time) {
            if (lastPushedEcgTime ===
                ecgWaveformData.time) {
                // console.log("last pushed ecg time  is same");
                return;
            } else {

                if (props.bedId === 8) {
                    // console.log("pushing ecg first page data !!", ecgWaveformData.time)
                }
                setLastPushedEcgTime(ecgWaveformData.time);
            }

            let rawData = ecgWaveformData.data;
            let time = ecgWaveformData.time;

            // console.log("rawData: ", rawData);
            let formattedData = rawData.split("^");
            // console.log("formattedData: ", formattedData);
            // console.log("formatted time: ", time, " formattedData: ", formattedData);

            let ecgGraphData = [];
            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    ecgGraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            ecgDataList = (ecgDataObj && ecgDataObj.concat(ecgGraphData)) || [];
            if (ecgDataList && ecgDataList.length > 3000) {
                ecgDataList.splice(0, 600);
            }

            setEcgDataObj(ecgDataList);
        }

    }, [props.monitorEcgWaveFormData])

    useEffect(() => {

        if (props.isSelected) return;
        if ([GRAPH_TYPE.RESP, GRAPH_TYPE.PLETH].indexOf(vitalGraphName && vitalGraphName.type) < 0) return;

        let plethDataList;

        let plethWaveformData = props.monitorHrWaveFormData
            && props.monitorHrWaveFormData.waveform
            && props.monitorHrWaveFormData.waveform.filter(
                (mvital) => {
                    return mvital.name && mvital.name.toUpperCase() === (vitalGraphName && vitalGraphName.name)
                }
            )[0];

        if (plethWaveformData && plethWaveformData.time) {
            if (lastPushedPlethTime ===
                plethWaveformData.time) {
                console.log("last pushed pleth time  is same");
                return;
            } else {
                setLastPushedPlethTime(plethWaveformData.time);
            }

            let rawData = plethWaveformData.data;
            let time = plethWaveformData.time;
            let formattedData = rawData.split("^");
            // console.log("formatted time: ", time, " formattedData: ", formattedData);
            let plethGraphData = [];

            if (formattedData && formattedData.length > 0) {
                let gap = 1000 / formattedData.length;
                let currentMilli = 0;

                formattedData.map((val) => {
                    let tempTime = moment(time).millisecond(currentMilli);
                    plethGraphData.push({
                        x: moment(tempTime).format("YYYY-MM-DDTHH:mm:ss:SSSZ"),
                        y: val
                    });
                    currentMilli = currentMilli + gap;
                })
            }

            plethDataList = (plethDataObj && plethDataObj.concat(plethGraphData)) || [];
            if (plethDataList && plethDataList.length > 3500) {
                // if (props.bedId === 5) {
                //     console.log("deleting data !!", ecg1DataList.length)
                // }
                plethDataList.splice(0, 600);
                // if (props.bedId === 5) {
                //     console.log("deletedddddd data !!", ecg1DataList.length)
                // }
            }

            setPlethDataObj(plethDataList);
        }

    }, [props.monitorHrWaveFormData])

    return (
        <div
            className={props.config.viewType === VIEW_TYPES.LIST_VIEW ?
                "bedcard-monitor-container left-panel-card" : "bedcard-monitor-container"}
            key={props.bedId}
            ref={props.myForwardedRef}>
            <div
                className={props.isSelected === props.bedId ?
                    "bedcard-monitor selected-card" : "bedcard-monitor"}
                onClick={() => props.handleBedClick(props.bedId)}>
                <div className="bed-patient-details">
                    <div className="bed-header">
                        <div className="bed-number">
                            Bed: {props.data.bedName}
                        </div>
                        {props.alarmData && props.alarmData.length > 0 && showAlarmData &&
                            <div className={`alarm-container ${getAlarmPriorityClass(props.alarmData[0])}`}
                                onClick={(event) => handleShowMoreAlarmsClick(event)}
                                id="alarm-display-bar"
                            >
                                <div className="top-alarm-container">
                                    {`${props.alarmData[0].name}: ${props.alarmData[0].value != null ? props.alarmData[0].value : ''}`}
                                </div>
                                <div className="alarm-number-container">
                                    {props.alarmData.length}
                                    {!showMoreAlarms && <img src={whiteDownArrow} className="arrow-icon" alt="white down arrow" />}
                                    {showMoreAlarms && <img src={whiteUpArrow} className="arrow-icon" alt="white up arrow" />}
                                </div>
                                {showMoreAlarms && <div className="more-alarm-container">
                                    {
                                        props.alarmData.map((alarm, count) => {
                                            if (count === 0 || count > 7) return;
                                            return (
                                                <div className="alarm more-alarm" key={count}>
                                                    {`${alarm.name}: ${alarm.value != null ? alarm.value : ''}`}
                                                    <span className="alarm-time">{alarm.time && moment(alarm.time).format("HH:mm:ss")}</span>
                                                </div>);
                                        })
                                    }
                                    <div className="see-all-container" onClick={() => props.handleAlarmMoreClick()}>
                                        See all
                                    </div>
                                </div>}

                            </div>}

                        {/* <div className={getEwsScoreClassName(props.data.data.ewsScore)}>
                                {(props.data.data && props.data.data.ewsScore) || "No Risk"}
                            </div> */}
                        {/* <div className="notification-container bell-container">
                            {false && <div className="icon-container bell-icon">
                                <img src={bellSvg} alt="notification icon" onClick={() => props.handleNotificationClick(props.bedId)} />
                            </div>}
                            {true && <div className="icon-container bell-icon">
                                <img
                                    src={bellSolidSvg}
                                    alt="notification icon"
                                    onClick={(event) => handleNotificationIconClick(event)}
                                />
                            </div>}
                            {showNotificationDetails && <div className="notification-details">
                                <div className="notification">
                                    <div className="notification-name">
                                        Tab Dolo given at 16:00
                                    </div>
                                    <div className="ack-button">
                                        Acknowledge
                                    </div>
                                </div>
                                <div className="notification">
                                    <div className="notification-name">
                                        Tab Dolo given at 16:00
                                    </div>
                                    <div className="ack-button">
                                        Acknowledge
                                    </div>
                                </div>
                                <div className="notification">
                                    <div className="notification-name">
                                        Tab Dolo given at 16:00
                                    </div>
                                    <div className="ack-button">
                                        Acknowledge
                                    </div>
                                </div>
                            </div>}
                        </div> */}
                        {!props.isFavorite && <div className="icon-container favorite-icon">
                            <img src={favoriteSvg} alt="notification icon" onClick={(event) => props.handleFavoriteClick(event, ADD, props.bedId)} />
                        </div>}
                        {props.isFavorite && <div className="icon-container favorite-icon">
                            <img src={favoriteSelectedSvg} alt="favorite icon" onClick={(event) => props.handleFavoriteClick(event, REMOVE, props.bedId)} />
                        </div>}
                    </div>
                    <div className="patient-details">
                        {(!props.patientData || !props.patientData.name) &&
                            <div className="no-patient-detail-container">
                                <img src={noPatientInfoIcon} />
                                <span>Patient Details not entered.</span>
                                <span
                                    className="add-patient-btn"
                                    onClick={
                                        (event) => { event.stopPropagation(); props.handleAddPatientClick(event) }
                                    }
                                >Add Patient</span>
                            </div>}
                        {props.patientData && props.patientData.name && <>
                            <div className="patient-name">
                                {(props.patientData && props.patientData.name) || 'Dummy Name'}
                            </div>
                            <div className="pipe">
                                |
                            </div>
                            <div className="patient-age">
                                {(props.patientData && props.patientData.age) || 'Age'}
                            </div>
                            <div className="pipe">
                                |
                            </div>
                            <div className="patient-gender">
                                {(props.patientData && props.patientData.gender) || 'Gender'}
                            </div>
                        </>}
                        {/* <div className="pipe">
                            |
                        </div> */}
                        {/* { <div className="patient-uhid">
                            {(props.patientData && props.patientData.uhid) ?
                                (`UHID : ${props.patientData.uhid}`) : ('UHID : -')}
                        </div> */}
                    </div>
                </div>
                {/* <hr className="hr-line" /> */}
                <div className="vital-details">
                    <div className="device monitor">
                        <div className="device-data">
                            {
                                BED_CARD_VITALS.MONITOR.filter(function (vital) {
                                    if (props.config.viewType === VIEW_TYPES.GRID_VIEW && vital.name === PATIENT_VITALS.HR) return false;
                                    return true;
                                }).map((vital) => {
                                    let vitalData = props.monitorData && props.monitorData.vitals.filter(
                                        (mvital) => mvital.name && mvital.name.toUpperCase() === vital.name
                                    )[0];

                                    let backup;
                                    if (vital.backup) {
                                        backup = props.monitorData && props.monitorData.vitals.filter(
                                            (mvital) => mvital.name && mvital.name.toUpperCase() === vital.backup
                                        )[0];
                                    }

                                    return (<div className=
                                        {vitalData && vitalData.data[0] && vitalData.data[0] !== "null/null"
                                            && vitalData.data[0].value ?
                                            `${vitalData.data[0].status.toLowerCase()} vital-data-detail` :
                                            (
                                                backup && backup.data[0] && backup.data[0].status ?
                                                    `${backup.data[0].status.toLowerCase()} vital-data-detail` : `vital-data-detail`
                                            )
                                        }
                                        key={vital.name}>
                                        <div className="vital-name">
                                            {vital.label}
                                        </div>
                                        <div className="vital-data">
                                            {(vitalData && vitalData.data[0] &&
                                                vitalData && vitalData.data[0] !== "null/null" &&
                                                vitalData.data[0].value
                                            ) ||
                                                (backup && backup.data[0] &&
                                                    backup.data[0].value) ||
                                                '-'}
                                            {vitalData && vitalData.unit && <span className="unit">{vitalData.unit || 'unit'}</span>}
                                        </div>
                                    </div>)
                                })}
                        </div>
                    </div>

                    {/* graph will come over here */}
                    {props.config.viewType === VIEW_TYPES.GRID_VIEW && < div className="select-vital-graph-container" onClick={(event) => handleSelectMoreVital(event)}>
                        <div className="selected-vital-name">
                            {vitalGraphName.label}
                        </div>
                        {selectMoreVitalGraph && <img src={whiteDownArrow} />}
                        {!selectMoreVitalGraph && <img src={whiteUpArrow} />}
                        {selectMoreVitalGraph && <div className="vital-graph-options" id="vital-graph-monitor">
                            {
                                MORE_GRAPH_OPTIONS.MONITOR.map((option) => {
                                    return (<div className={vitalGraphName.name === option.name ? `common-vital-option vital-graph-active` : `common-vital-option`}
                                        onClick={(event) => handleVitalGraphSelect(event, option)}>{option.label}</div>)
                                })
                            }
                        </div>}
                    </div>}
                    {props.config.viewType === VIEW_TYPES.GRID_VIEW && <div className="graph-container">
                        <ApexChart className="chart"
                            dataList={vitalGraphName.type === GRAPH_TYPE.ECG ? ecgDataObj : plethDataObj}
                            range={4000}
                            // yMin={vitalGraphName.min}
                            // yMax={vitalGraphName.max}
                            yTickPoint={4}
                            showYAxis={false}
                            showXAxis={false}
                            width={"90%"}
                            height={"80px"}
                            lineColor={'#299842'}
                            yTitle={['Pressure', '(cmH20)']}
                            yColor={'#20BBC1'}
                            name={'apex-ecg-1' + props.bedId}
                        />
                        <div className="graph-rate-container">
                            {BED_CARD_VITALS.MONITOR.filter((vital) => vital.name === PATIENT_VITALS.HR).map((hrVital) => {
                                let vitalData = props.monitorData && props.monitorData.vitals.filter(
                                    (mvital) => mvital.name && mvital.name.toUpperCase() === hrVital.name
                                )[0];
                                return (
                                    <div key={hrVital.name}>
                                        <span className="rate-title">{vitalData && vitalData.data[0] && vitalData.data[0].value || '-'} </span>
                                        <span className="rate-unit">b/min</span>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>}
                </div>
            </div>
            {/* {
                props.isSelected && (props.viewType === VIEW_TYPES.LIST_VIEW) && <div className="arrow-container">
                    <img src={rightArrowSvgSvg} alt="arrow icon" onClick={() => console.log("notification icon clicked")} />
                </div>
            } */}
        </div >
    );

})
const mapStateToProps = (state, ownProps) => {

    return {
        config: state.configReducer.config,
    };
};
// const mapDispatchToProps = (dispatch) => {
//     return {
//         clearBedAlarm: (payload) => dispatch(clearBedAlarm(payload)),
//     }
// }

const ConnectedBedCardMonitor = connect(
    mapStateToProps, null
)(BedCardMonitor);

const BedCardMonitorRef = forwardRef((props, ref) =>
    <ConnectedBedCardMonitor key={props.bedId} {...props} myForwardedRef={ref} />
);
export default BedCardMonitorRef;