import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import logo_svg from "../../common/icons/svg/logo.svg";
import workflow_illustration_svg from "../../common/icons/svg/workflow-illustration.svg";
import hippa_svg from "../../common/icons/svg/hippa.svg";
import connected_devices_svg from "../../common/icons/svg/connected-device.svg";
import accessible_svg from "../../common/icons/svg/accessible.svg";
import login_circle_vector from "../../common/icons/svg/login-circle-vector.svg";
import parabola_axis_login2_vector from "../../common/icons/svg/parabola-axis-login2-vector.svg";
import parabola_login_vector from "../../common/icons/svg/parabola-login-vector.svg";
import login2_line_vector from "../../common/icons/svg/login2-line-vector.svg";
import rectangle_login2 from "../../common/icons/svg/rectangle_login2.svg";
import small_cir_login_2 from "../../common/icons/svg/small-cir-login-2.svg"
import "./Login.scss";
import { login, loginTrack } from '../../helper/login-helper';
import { redirect } from '../../common/utils/RedirectionUtils';
import { LOGIN_CONST, PAGE_CONST } from '../../common/constant/page-constants';
import { clearAlarms, clearStore, hospitalDataAction } from '../../modules/actions/action';
import { setLocalStorageCustom } from '../../common/utils/StorageUItils';
import { checkType } from '../../common/constant/common-constants';
import moment from 'moment';
import { hashGenerator } from '../../common/utils/helper/hashGenerator'


function Login(props) {
    const CONTENT_LIST = [{
        heading: "Workflow Automation",
        body: `1. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Quam mi tortor cursus
            tincidunt purus tortor cursus tincidunt purus.`,
        src: workflow_illustration_svg
    }, {
        heading: "HIPAA Compliant-Data Security and Privacy",
        body: `2. Lorem ipsum dolor sit amet, \
        consectetur adipiscing elit. Quam mi tortor cursus \
        tincidunt purus tortor cursus tincidunt purus.`,
        src: hippa_svg
    }, {
        heading: "Connected Medical Devices",
        body: `3. Lorem ipsum dolor sit amet, \
        consectetur adipiscing elit. Quam mi tortor cursus \
        tincidunt purus tortor cursus tincidunt purus.`,
        src: connected_devices_svg
    }, {
        heading: "Accessible from App and Web",
        body: `4. Lorem ipsum dolor sit amet, \
        consectetur adipiscing elit. Quam mi tortor cursus \
        tincidunt purus tortor cursus tincidunt purus.`,
        src: accessible_svg
    }];

    const navigate = useNavigate();

    const [selectedContentIndex, setSelectedContentIndex] = useState(0);
    const [contentLength] = useState(CONTENT_LIST.length);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleNavigation = (type, count) => {

        let sci = selectedContentIndex;
        if (type === LOGIN_CONST.LEFT_ARROW) {
            if (sci === 0) setSelectedContentIndex(contentLength - 1);
            else setSelectedContentIndex(sci - 1);
        } else if (type === LOGIN_CONST.RIGHT_ARROW) {
            if (sci === (contentLength - 1)) setSelectedContentIndex(0);
            else setSelectedContentIndex(sci + 1);
        } else if (type === LOGIN_CONST.RADIO_BUTTON) {
            setSelectedContentIndex(count);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            let sci = selectedContentIndex;
            if (sci === contentLength - 1) setSelectedContentIndex(0);
            else setSelectedContentIndex(sci + 1);
        }, 2000);
        return () => clearTimeout(timer);
    }, [selectedContentIndex]);

    const handleSubmit = () => {
        if (isSubmitDisabled) return;

        setErrorMsg(null);

        let data = {
            email: email,
            password: password
        }
        let hashedEmail = hashGenerator(email);
        try {
            window.clarity("identify", hashedEmail);
        } catch (e){
            console.log('error', e);
        }
        login(data).then((res) => {
            props.hospitalDataAction(res);
            props.clearStore();
            setLocalStorageCustom('hospitalId', res.hospitalId);
            // props.clearAlarms();
            let trackingData = {
                type: checkType.LOGIN,
                hospitalId: props.hospitalData.hospitalId,
                loginDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                username: props.hospitalData.username
            }
            loginTrack(trackingData).then((trackRes) => {
                console.log("sucess!!!", trackRes);
                redirect(navigate, PAGE_CONST.HOME_PAGE);

            }).catch((err) => {
                console.log("err in tracking API", err);
                redirect(navigate, PAGE_CONST.HOME_PAGE);
            })

        }).catch((err) => {
            setErrorMsg("Error Occurred !!!");
            console.log(err);
        })
    }

    const handleChange = (type, event) => {

        let value = (event.target && event.target.value) || '';
        setIsSubmitDisabled(true);
        setErrorMsg(null);
        switch (type) {

            case LOGIN_CONST.EMAIL:
                setEmail(value);
                if (value && password) setIsSubmitDisabled(false);
                break;
            case LOGIN_CONST.PASSWORD:
                setPassword(value);
                if (email && value) setIsSubmitDisabled(false);
                break;
            default:
                break;
        }
    }

    return (

        <div className='login-container'>
            <div className="background-logo-container">
                <img src={login_circle_vector} className="login-circle-vector" />
                <img src={parabola_axis_login2_vector} className="parabola-axis-login2-vector" />
                <img src={parabola_login_vector} className="parabola-login-vector" />
                <img src={login2_line_vector} className="login2-line-vector" />
                <img src={rectangle_login2} className="rec-1" />
                <img src={rectangle_login2} className="rec-2" />
                <img src={small_cir_login_2} className="cir-1" />
                <img src={small_cir_login_2} className="cir-2" />
                <img src={small_cir_login_2} className="cir-3" />

            </div>
            <div className='left-panel'>
                {/* <div className='logo-container'>
                    <img className='login-logo' src={logo_white_svg} alt="logo" />
                    noccarc
                </div> */}
                <div className='content-container'>
                    <div className='logo-container'>
                        <img className='login-logo' src={logo_svg} alt="logo" />
                    </div>
                    <div className='sign-in-text-container'>
                        Sign In
                    </div>
                    <div className='login-form-container'>
                        <div className='email-container'>
                            {/* <label className='login-label'>Email Id</label> */}
                            <input
                                className='login-input email'
                                type='email'
                                placeholder='Email ID'
                                onChange={(event) => handleChange(LOGIN_CONST.EMAIL, event)} />
                        </div>
                        <div className='password-container'>
                            {/* <label className='login-label'>Password</label> */}
                            <input
                                className='login-input password'
                                type="password"
                                placeholder='Password'
                                onChange={(event) => handleChange(LOGIN_CONST.PASSWORD, event)}
                            />
                        </div>
                        <div className='error-msg'>
                            {errorMsg}
                        </div>
                        <div className='sign-in-botton-container'>
                            <button
                                className={'sign-in sign-in-botton'}
                                onClick={() => handleSubmit()}
                            >
                                Sign In</button>
                        </div>
                    </div>
                </div>
                {/* <div className='footer-container'>
                    <img className="login-logo-vector" src={logo_vector} alt="logo-vector" />
                </div> */}
            </div>
            {/* <div className='right-panel'>
                <div className='content-container'>
                    <div className='illustration-container'>
                        <img
                            className='illustration'
                            src={CONTENT_LIST[selectedContentIndex] && CONTENT_LIST[selectedContentIndex].src} />
                    </div>
                    <div className='below-illustration-container'>
                        <div className='logo-container'>
                            <img className='login-logo' src={logo_svg} alt="logo" />
                        </div>
                        <div className='content'>
                            <div className='heading'>
                                {CONTENT_LIST[selectedContentIndex] && CONTENT_LIST[selectedContentIndex].heading}
                            </div>
                            {/* <div className='body'>
                                {CONTENT_LIST[selectedContentIndex] && CONTENT_LIST[selectedContentIndex].body}
                            </div> */}
        </div>
        // <div className='content-navigation-container'>
        //     <img
        //         src={left_arrow_empty}
        //         alt="left arrow icon"
        //         className="left-arrow-empty arrow"
        //         onClick={() => handleNavigation(LOGIN_CONST.LEFT_ARROW)} />
        //     <div className='radio-button-container'>
        //         {CONTENT_LIST.map((content, count) => {
        //             return (
        //                 <input
        //                     key={count}
        //                     type="radio"
        //                     name="navigation"
        //                     className={selectedContentIndex === count ?
        //                         "navigate-input checked" : "navigate-input"}
        //                     onChange={() => handleNavigation(LOGIN_CONST.RADIO_BUTTON, count)}
        //                     checked={selectedContentIndex === count ? true : false}
        //                 />);
        //         })}
        //     </div>
        //     <img
        //         src={right_arrow_empty}
        //         alt="righht arrow icon"
        //         className="right-arrow-empty arrow"
        //         onClick={() => handleNavigation(LOGIN_CONST.RIGHT_ARROW)} />
        // </div>
        //         </div>
        //     </div>
        // </div>
        // </div >
    );
}

const mapStateToProps = (state) => {

    return {
        hospitalData: state.hospitalDataReducer && state.hospitalDataReducer.hospitalData,
    };
}

const mapDispatchToProps = (dispatch) => {

    return {
        hospitalDataAction: (payload) => dispatch(hospitalDataAction(payload)),
        clearAlarms: (payload) => dispatch(clearAlarms(payload)),
        clearStore: (payload) => dispatch(clearStore(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);