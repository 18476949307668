import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { BED_DETAILS_MODULE, MODAL_ITEMS, NOTES_TYPE, PAGE_PARENT } from "../../common/constant/common-constants";
import ChartContainer from "../ChartContainer/ChartContainer";
import NotesContainer from "../NotesContainer/NotesContainer";
import AlarmHistoryContainer from "../AlarmHistoryContainer/AlarmHistoryContainer";
import PrescriptionContainer from "../PrescriptionContainer/PrescriptionContainer";
import { bedDetailConfigAction, modalAction } from "../../modules/actions/action";
import expand_svg from "../../common/icons/svg/expand.svg";
import cross_svg from "../../common/icons/svg/cross.svg";
import "./PatientModal.scss";
import { redirect } from "../../common/utils/RedirectionUtils";
import { PAGE_CONST } from "../../common/constant/page-constants";
import { getActiveModuleForBedDetails } from "../../common/utils/CommonUtils";


function PatientModal(props) {
    const navigate = useNavigate();

    const [activeItem, setActiveItem] = useState(props.optionType || MODAL_ITEMS.NURSE_NOTES);

    const handleBackgroundBlur = () => {
        console.log("blurring !!!");
        // const appEle = document.getElementById("App");
    }

    const handleItemClick = (item) => {

        setActiveItem(item);
        props.modalAction({
            isOpen: true,
            optionType: item
        });
    }

    const handleCrossClick = () => {
        props.modalAction({
            isOpen: false,
            optionType: null
        });
    }

    const handleExpandClick = () => {

        props.modalAction({
            isOpen: false,
            optionType: null
        });

        props.bedDetailConfigAction({
            activeModule: getActiveModuleForBedDetails(activeItem)
        });

        let data = {
            bedId: props.bedId
        }
        redirect(navigate, PAGE_CONST.BED_DETAILS, data);
        console.log("expand Clicked");
    }

    return (
        <div className="patient-modal">
            <div className="left-menu-bar">
                <div className="menu-items">
                    <div
                        className={activeItem === MODAL_ITEMS.CHARTING ?
                            "charting menu-item selected" : "charting menu-item"}
                        onClick={() => handleItemClick(MODAL_ITEMS.CHARTING)}>
                        Charting
                    </div>
                    <div
                        className={activeItem === MODAL_ITEMS.NURSE_NOTES ?
                            "charting menu-item selected" : "charting menu-item"}
                        onClick={() => handleItemClick(MODAL_ITEMS.NURSE_NOTES)}>
                        Nurse Notes
                    </div>
                    <div
                        className={activeItem === MODAL_ITEMS.DOCTOR_NOTES ?
                            "charting menu-item selected" : "charting menu-item"}
                        onClick={() => handleItemClick(MODAL_ITEMS.DOCTOR_NOTES)}>
                        Doctor Notes
                    </div>
                    <div
                        className={activeItem === MODAL_ITEMS.PRESCRIPTION ?
                            "charting menu-item selected" : "charting menu-item"}
                        onClick={() => handleItemClick(MODAL_ITEMS.PRESCRIPTION)}>
                        Prescription
                    </div>
                    <div
                        className={activeItem === MODAL_ITEMS.ALARM_HISTORY ?
                            "charting menu-item selected" : "charting menu-item"}
                        onClick={() => handleItemClick(MODAL_ITEMS.ALARM_HISTORY)}>
                        Alarm History
                    </div>
                </div>
            </div>
            <div className="right-menu-bar">
                <div className="modal-right-header">
                    <div className="modal-left">
                        <span className="bed-id">{`Bed ${props.bedId}`}</span>
                        <span className="bed-id">{'>'}</span>
                        <span className="bed-id">{activeItem}</span>
                    </div>
                    <div className="modal-right">
                        <div className="modal-expand logo">
                            <img src={expand_svg} alt="expand svg" onClick={() => handleExpandClick()} />
                        </div>
                        <div className="modal-cross logo">
                            <img src={cross_svg} alt="cross svg" onClick={() => handleCrossClick()} />
                        </div>
                    </div>
                </div>
                {activeItem === MODAL_ITEMS.CHARTING &&
                    <ChartContainer
                        bedId={props.bedId}
                        parent={PAGE_PARENT.PATIENT_MODAL}
                    />}
                {activeItem === MODAL_ITEMS.NURSE_NOTES &&
                    <NotesContainer
                        notesType={NOTES_TYPE.NURSE}
                        bedId={props.bedId}
                        parent={PAGE_PARENT.PATIENT_MODAL}
                    />}
                {activeItem === MODAL_ITEMS.DOCTOR_NOTES &&
                    <NotesContainer
                        notesType={NOTES_TYPE.DOCTOR}
                        bedId={props.bedId}
                        parent={PAGE_PARENT.PATIENT_MODAL}
                    />}
                {activeItem === MODAL_ITEMS.PRESCRIPTION &&
                    <PrescriptionContainer
                        bedId={props.bedId}
                        parent={PAGE_PARENT.PATIENT_MODAL}
                    />}
                {activeItem === MODAL_ITEMS.ALARM_HISTORY &&
                    <AlarmHistoryContainer
                        bedId={props.bedId}
                        parent={PAGE_PARENT.PATIENT_MODAL}
                    />}
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {

    return {};
    // let bds = state.bedDetailsReducer.bedDetails && state.bedDetailsReducer.bedDetails.filter((bed) => bed.id === ownProps.bedId)[0];
    // return {
    //     bedDetails: bds,
    //     config: state.configReducer.config
    // };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalAction: (payload) => dispatch(modalAction(payload)),
        bedDetailConfigAction: (payload) => dispatch(bedDetailConfigAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientModal);