import React, { forwardRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BED_DETAILS_MODULE, DEVICE_TYPES } from "../../common/constant/common-constants";
import { PAGE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";
// import BedCard from "../../components/BedCard/BedCard";
import BedCardMonitor from "../../components/BedCardMonitor/BedCardMonitor";
// import BedCardNew from "../../components/BedCardNew/BedCardNew";
import { bedDetailConfigAction, clearBedAlarm, modalAction } from "../../modules/actions/action";

import "./BedDataContainer.scss";

function BedDataContainer(props) {
    // const navigate = useNavigate();

    // const BedDataContainer = forwardRef((props, ref) => {
    // const [bedData, setBedData] = useState(props.bedDetails);

    // useEffect(() => {
    //     setBedData(props.bedDetails);
    // }, [props.bedDetails])

    // console.log("props in bedData", props);
    const handleFavoriteClick = (event, type, bedId) => {

        event.stopPropagation();
        props.handleFavoriteClick(event, type, bedId);
    }

    const handleNotificationClick = (bedId) => {
        // console.log(`notification icon clicked for ${bedId}`);
        props.handleNotificationClick();
    }

    const handleClearBedAlarm = (bedId) => {
        let data = {
            bedId: bedId,
        }
        props.clearBedAlarm(data)

    }
    // const handlePatientCTA = (type) => {

    //     props.modalAction({
    //         isOpen: true,
    //         optionType: type,
    //         bedId: props.bedId
    //     });
    // }

    // const handleBedHeaderClick = (props) => {

    //     let data = {
    //         bedId: props.bedId
    //     }
    // console.log("props on header click", props)

    // redirect(navigate, PAGE_CONST.BED_DETAILS, data);
    // }

    // const handleAlarmMoreClick = () => {

    //     // props.bedDetailConfigAction({
    //     //     activeModule: BED_DETAILS_MODULE.ALARMS
    //     // });

    //     // let data = {
    //     //     bedId: props.bedId
    //     // }


    //     console.log("props.isSelected", props.isSelected)

    //     // redirect(navigate, PAGE_CONST.BED_DETAILS, data);
    // }

    return (
        // <BedCardNew
        //     key={"test-" + props.bedId}
        //     bedId={props.bedId}
        //     patientData={props.patientData}
        //     data={bedData}
        //     monitorData={props.monitorData}
        //     alarmData={props.alarmData}
        //     notificationData={props.notificationData}
        //     ventilatorData={props.ventilatorData}
        //     isFavorite={props.isFavorite}
        //     isSelected={props.isSelected}
        //     viewType={props.config.viewType}
        //     handleFavoriteClick={(event, type, bedId) => handleFavoriteClick(event, type, bedId)}
        //     handleBedClick={() => handleBedClick(props.bedId)}
        //     handleNotificationClick={(bedId) => handleNotificationClick(bedId)}
        //     handlePatientCTA={(type) => handlePatientCTA(type)}
        //     handleBedHeaderClick={() => handleBedHeaderClick()}
        //     handleAlarmMoreClick={() => handleAlarmMoreClick()}
        //     ref={props.myForwardedRef}
        // />
        <BedCardMonitor
            key={"test-" + props.bedId}
            bedId={props.bedId}
            patientData={props.patientData}
            data={props.bedDetails}
            monitorData={props.monitorData}
            monitorEcgWaveFormData={props.monitorEcgWaveFormData}
            monitorHrWaveFormData={props.monitorHrWaveFormData}
            alarmData={props.alarmData}
            // notificationData={props.notificationData}
            // ventilatorData={props.ventilatorData}
            isFavorite={props.isFavorite}
            isSelected={props.isSelected}
            handleFavoriteClick={(event, type, bedId) => handleFavoriteClick(event, type, bedId)}
            handleBedClick={props.handleBedClick}
            handleNotificationClick={(bedId) => handleNotificationClick(bedId)}
            handleAlarmMoreClick={props.handleAlarmMoreClick}
            ref={props.myForwardedRef}
            handleAddPatientClick={() => props.handleAddPatientClick(props.bedId)}
            handleClearBedAlarm={(bedId) => handleClearBedAlarm(bedId)}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    // console.log("ownprops", ownProps)
    let bds = state.bedDetailsReducer.bedDetails
        && state.bedDetailsReducer.bedDetails[ownProps.bedId];
    // .filter((bed) => bed.id === ownProps.bedId)[0];

    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];

    let mData = state.deviceVitalDataReducer.deviceVitalData
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId][DEVICE_TYPES.MONITOR];

    let mEcgwData = state.deviceVitalDataReducer.deviceEcgWaveform
        && state.deviceVitalDataReducer.deviceEcgWaveform[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceEcgWaveform[ownProps.bedId][DEVICE_TYPES.MONITOR];

    let mHrwData = state.deviceVitalDataReducer.deviceHrWaveform
        && state.deviceVitalDataReducer.deviceHrWaveform[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceHrWaveform[ownProps.bedId][DEVICE_TYPES.MONITOR];

    let vData = state.deviceVitalDataReducer.deviceVitalData
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId]
        && state.deviceVitalDataReducer.deviceVitalData[ownProps.bedId][DEVICE_TYPES.VENTILATOR];

    let notificationData = state.notificationDataReducer.notificationData
        && state.notificationDataReducer.notificationData[ownProps.bedId]
        && state.notificationDataReducer.notificationData[ownProps.bedId].notifications;

    let alarmData = state.alarmDataReducer.alarmData
        && state.alarmDataReducer.alarmData[ownProps.bedId]
        && state.alarmDataReducer.alarmData[ownProps.bedId].alarms;

    return {
        bedDetails: bds,
        patientData: pData,
        monitorData: mData,
        monitorEcgWaveFormData: mEcgwData,
        monitorHrWaveFormData: mHrwData,
        ventilatorData: vData,
        notificationData: notificationData,
        alarmData: alarmData && [...alarmData],
        config: state.configReducer.config
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalAction: (payload) => dispatch(modalAction(payload)),
        bedDetailConfigAction: (payload) => dispatch(bedDetailConfigAction(payload)),
        clearBedAlarm: (payload) => dispatch(clearBedAlarm(payload)),
    }
}

const ConnectedBedDataContainer = connect(
    mapStateToProps, mapDispatchToProps
)(BedDataContainer);

const BedDataContainerRef = forwardRef((props, ref) =>
    <ConnectedBedDataContainer key={props.bedId} {...props} myForwardedRef={ref} />
);
export default BedDataContainerRef;