import { DEVICE_TYPES } from "./common-constants"

export const PAGE_CONST = {

    LOGIN: "LOGIN",
    HOME_PAGE: "HOME_PAGE",
    BED_DETAILS: "BED_DETAILS",
}

export const LOGIN_CONST = {
    ACCESS_TOKEN: "accessToken",
    REFRESH_TOKEN: "accessToken",
    LEFT_ARROW: "LEFT_ARROW",
    RIGHT_ARROW: "RIGHT_ARROW",
    RADIO_BUTTON: "RADIO_BUTTON",
    EMAIL: "email",
    PASSWORD: "password"
}

export const CHART_CONST = {
    ADD: "ADD",
    UPDATE: "UPDATE",
    NEW_ID_PRED: "new-"
}

export const HOME_PAGE = {

    ADD_COLUMN_CONSTANT: {
        MONITOR: [
            {
                name: "BPHIGH",
                label: "BP High",
                device: DEVICE_TYPES.MONITOR
            }
        ],
        VENTILATOR: [
            {
                name: "MVE",
                label: "MVe",
                device: DEVICE_TYPES.VENTILATOR
            },
            {
                name: "PRESSURE",
                label: "Pressure",
                device: DEVICE_TYPES.VENTILATOR
            },
            {
                name: "FIO2",
                label: "Fio2",
                device: DEVICE_TYPES.VENTILATOR
            },
            {
                name: "PEEP",
                label: "Peep",
                device: DEVICE_TYPES.VENTILATOR
            },
            {
                name: "TIDALVOLUME",
                label: "Tidal Vol",
                device: DEVICE_TYPES.VENTILATOR
            },
        ]
    }
}

export const NOTES_CONSTANT = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
    NOTES_DATA_ID: "notes-data-id"
}

export const CLINICAL_SCORE = {
    GCS_SCORE: "GCS_SCORE",
    SOFA_SCORE: "SOFA_SCORE"
}