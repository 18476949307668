import {
    getChartCNSAPI,
    getChartCVSAPI,
    getChartDrugAdministration,
    getChartNursingCheckAPI,
    getChartRSAPI,
    getChartTimelineAPI,
    getChartVitalsAPI,
    getIOBalanceAPI,
    getClinicalScoreAPI,
    updateChartCNSAPI,
    updateChartCVSAPI,
    updateChartDrugAdministration,
    updateChartNurseCheckAPI,
    updateChartRSAPI,
    updateChartVitalsAPI,
    updateClinicalScoreAPI,
    updateIOBalanceAPI,
    generateEcgChartAPI,
    generateVitalsPdfAPI
} from "../adapter/cms-adapter";
import { CHART_OPTIONS, getData } from "../common/constant/common-constants";

export const getChartData = (data) => {
    let reqData = {
        bedId: data.bedId,
        date: data.date,
        patientSessionId: data.patientSessionId
    }

    let promise = new Promise(function (resolve, reject) {

        switch (data.chartType) {
            case CHART_OPTIONS.VITALS:
                getChartVitalsAPI(reqData).then((res) => {
                    // console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("vitals error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.RS:
                getChartRSAPI(reqData).then((res) => {
                    // console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("rs error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.CVS:
                getChartCVSAPI(reqData).then((res) => {
                    // console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("cvs error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.CNS:
                getChartCNSAPI(reqData).then((res) => {
                    // console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("cns error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.NURSING_CHECKS:
                getChartNursingCheckAPI(reqData).then((res) => {
                    // console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("cns error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.DRUG_ADMINISTRATION:
                getChartDrugAdministration(reqData).then((res) => {
                    // console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject('drug administration error');
                })
                break;
            case CHART_OPTIONS.I_O_BAL:
                getIOBalanceAPI(reqData).then((res) => {

                    if (res.data.data) resolve(res.data.data.data);
                    else reject('io error');
                })
                break;
            case CHART_OPTIONS.CLINICAL_SCORES:
                getClinicalScoreAPI(reqData).then((res) => {
                    console.log("clinical scores api result", res);

                    if (res.data.data) resolve(res.data.data.data);
                    else reject('io error');
                })
                break;

            default:
                resolve(getChartVitalData(data.chartType));
                break;
        }
    })

    return promise;
}

export const updateChartData = (data) => {

    console.log("arg in updateChartData :: " + JSON.stringify(data));

    let reqData = {
        bedId: data.bedId,
        date: data.date,
        patientSessionId: data.patientSessionId,
        data: data.data
    }

    let promise = new Promise(function (resolve, reject) {

        switch (data.chartType) {
            case CHART_OPTIONS.VITALS:
                updateChartVitalsAPI(reqData).then((res) => {
                    console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("update vitals error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.RS:
                updateChartRSAPI(reqData).then((res) => {
                    console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("update rs error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.CVS:
                updateChartCVSAPI(reqData).then((res) => {
                    console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("update cvs error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.CNS:
                updateChartCNSAPI(reqData).then((res) => {
                    console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("update cns error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.NURSING_CHECKS:
                updateChartNurseCheckAPI(reqData).then((res) => {
                    console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("update nursing check error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.DRUG_ADMINISTRATION:
                updateChartDrugAdministration(reqData).then((res) => {
                    console.log(res);

                    if (res.data.data) resolve(res.data.data);
                    else reject("update drug adminstration error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.I_O_BAL:
                updateIOBalanceAPI(reqData).then((res) => {

                    if (res.data.data) resolve(res.data.data);
                    else reject("update io error error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            case CHART_OPTIONS.CLINICAL_SCORES:
                updateClinicalScoreAPI(reqData).then((res) => {

                    if (res.data.data) resolve(res.data.data);
                    else reject("update io error error !!");
                }).catch((err) => {
                    console.log(err);
                })
                break;
            default:
                resolve(getChartVitalData(reqData.chartType));
        }
    })

    return promise;
}

const getChartVitalData = (type) => {

    let data = null;

    switch (type) {
        case CHART_OPTIONS.VITALS:
            data = {
                "bedId": "test-bed",
                "date": "14/06/2022",
                data: [{
                    "id": 1,
                    "time": "06:00",
                    "hr": "72",
                    "spo2": "73",
                    "temp": "73",
                    "rr": "76",
                    "bp_s": "75",
                    "bp_d": "73",
                    "bp_mean": "74",
                    "isReadOnly": true
                }, {
                    "id": 2,
                    "time": "07:00",
                    "hr": "72",
                    "spo2": "73",
                    "temp": "73",
                    "rr": "76",
                    "bp_s": "75",
                    "bp_d": "73",
                    "bp_mean": "74"
                }]
            }
            break;

        case CHART_OPTIONS.RS:
            data = {
                "bedId": "test-bed",
                "date": "14/06/2022",
                data: [{
                    "id": 1,
                    "time": "06:00",
                    "mode": "VC-CMV",
                    "fio2": "21",
                    "peep": "5",
                    "pInsp": "8",
                    "rr": "15",
                    "vti": "350",
                    "vte": "345",
                    "i:e": "1:2",
                    "isReadOnly": true
                }, {
                    "id": 3,
                    "time": "08:00",
                    "mode": "VC-CMV",
                    "fio2": "21",
                    "peep": "5",
                    "p_insp": "8",
                    "rr": "15",
                    "vti": "350",
                    "vte": "345",
                    "i:e": "1:2"
                }]
            }
            break;
        case CHART_OPTIONS.CVS:
            data = {
                "bedId": "test-bed",
                "date": "14/06/2022",
                data: [{
                    "id": 1,
                    "time": "06:00",
                    "rhythm": "98",
                    "map": "98",
                    "cvp": 32,
                    "isReadOnly": true
                }, {
                    "id": 2,
                    "time": "07:00",
                    "rhythm": "98",
                    "map": "96",
                    "cvp": 32,
                    "isReadOnly": true
                }, {
                    "id": 3,
                    "time": "08:00",
                    "rhythm": "98",
                    "map": "97",
                    "cvp": 32
                }]
            }
            break;
        case CHART_OPTIONS.CNS:
            data = {
                "bedId": "test-bed",
                "date": "14/06/2022",
                data: [{
                    "id": 1,
                    "time": "06:00",
                    "eor": "98",
                    "verbal": "98",
                    "motor": "78",
                    "gcs": "98",
                    "pr-s": "90",
                    "pl-r": "90",
                    "ss": "92",
                    "ps": "80",
                    "isReadOnly": true
                }, {
                    "id": 2,
                    "time": "07:00",
                    "eor": "98",
                    "verbal": "98",
                    "motor": "78",
                    "gcs": "98",
                    "pr-s": "90",
                    "pl-r": "90",
                    "ss": "92",
                    "ps": "80",
                    "isReadOnly": true
                }, {
                    "id": 3,
                    "time": "08:00",
                    "eor": "98",
                    "verbal": "98",
                    "motor": "78",
                    "gcs": "98",
                    "pr-s": "90",
                    "pl-r": "90",
                    "ss": "92",
                    "ps": "80"
                }]
            }
            break;
        case CHART_OPTIONS.NURSING_CHECKS:
            data = {
                "bedId": "test-bed",
                "date": "14/06/2022",
                data: [{
                    "id": 1,
                    "time": "Morning",
                    "eor": "98",
                    "verbal": "98",
                    "motor": "78",
                    "gcs": "98",
                    "pr-s": "90",
                    "pl-r": "90",
                    "ss": "92",
                    "ps": "80",
                    "isReadOnly": true
                }]
            }
            break;
    }

    return data;
}


export const generateECGChartData = (data) => {
    let promise = new Promise(function (resolve, reject) {
        generateEcgChartAPI(data).then((res) => {
            if (res) resolve(res)
            else reject("Error occured in generation api");

        }).catch((err) => {
            console.log(err);
        })
    })
    return promise;
}

export const generateVitalsPdf = (data) => {
    let promise = new Promise(function (resolve, reject) {
        generateVitalsPdfAPI(data).then((res) => {
            if (res.data) resolve(res);
            else reject("Error occured in generating vital pdf!");
        }).catch((err) => {
            console.log(err);
        })
    })
    return promise;
}

export const getChartTimeline = (data) => {
    let reqData = {
        bedId: data.bedId,
        patientSessionId: data.patientSessionId,
        date: data.date
    }

    let promise = new Promise(function (resolve, reject) {

        getChartTimelineAPI(reqData).then((res) => {

            if (res && res.data && res.data.data) resolve(res.data.data);
            else reject("some error message getChartTimeline");
        })
    })

    return promise;
}