import {
    addDrugAPI,
    addInfusionAPI,
    addStatOrderAPI,
    getDrugsAPI,
    getInfusionsAPI,
    getStatOrdersAPI,
    updateDrugAPI,
    updateInfusionAPI,
    updateStatOrderAPI
} from "../adapter/cms-adapter";
import { PRESCRIPTION_OPTIONS } from "../common/constant/common-constants";

export const getPrescriptionHelper = (data, type) => {
    let prescriptionType = type;

    let requestData = data;

    let promise = new Promise((resolve, reject) => {

        if (prescriptionType === PRESCRIPTION_OPTIONS.DRUGS) {
            return getDrugsAPI(requestData).then((res) => {

                if (res && res.data && res.data.data) resolve(res.data.data.drugPrescriptions);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        }
        else if (prescriptionType === PRESCRIPTION_OPTIONS.STAT_ORDERS) {
            return getStatOrdersAPI(requestData).then((res) => {
                if (res && res.data && res.data.data) resolve(res.data.data.statOrderPrescriptions);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        } else if (prescriptionType === PRESCRIPTION_OPTIONS.INFUSION) {
            return getInfusionsAPI(requestData).then((res) => {
                if (res && res.data && res.data.data) resolve(res.data.data.infusionPrescriptions);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        }
    });

    return promise;
}

export const addPrescriptionHelper = (data, type) => {
    let prescriptionType = type;

    let requestData = data;

    let promise = new Promise((resolve, reject) => {

        if (prescriptionType === PRESCRIPTION_OPTIONS.DRUGS) {
            return addDrugAPI(requestData).then((res) => {

                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        }
        else if (prescriptionType === PRESCRIPTION_OPTIONS.STAT_ORDERS) {
            return addStatOrderAPI(requestData).then((res) => {
                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        } else if (prescriptionType === PRESCRIPTION_OPTIONS.INFUSION) {
            return addInfusionAPI(requestData).then((res) => {
                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        }
    });

    return promise;
}

export const updatePrescriptionHelper = (data, type) => {
    let prescriptionType = type;

    let requestData = data;

    let promise = new Promise((resolve, reject) => {

        if (prescriptionType === PRESCRIPTION_OPTIONS.DRUGS) {
            return updateDrugAPI(requestData).then((res) => {

                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        }
        else if (prescriptionType === PRESCRIPTION_OPTIONS.STAT_ORDERS) {
            return updateStatOrderAPI(requestData).then((res) => {
                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        } else if (prescriptionType === PRESCRIPTION_OPTIONS.INFUSION) {
            return updateInfusionAPI(requestData).then((res) => {
                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("error !!")
            }).catch((err) => {
                reject("some error occured !!!");
            });
        }
    });

    return promise;
}