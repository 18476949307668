import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { generateECGChartData, generateVitalsPdf } from '../../helper/chart-helper';
import './ReportContainer.scss'
import { CHART_HEADER_CONST, VITAL_OPTION, parameterSorting, parameterOptionList, durationOptionList, patientGenderList, BLOOD_GROUP_OPTION, REPORT_TYPE } from "../../common/constant/common-constants";
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import ModalBackdrop from '../../components/ModalBackdrop/ModalBackdrop';
import TimePicker from "react-time-picker/dist/TimePicker";

function ReportContainer(props) {
    const [selectedParamList, setSelectedParamList] = useState([]);
    const [trendStartDate, setTrendStartDate] = useState(new Date());
    const [patientDetailInfoDialogActive, setPatientDetailInfoDialogActive] = useState("");
    const [patientName, setPatientName] = useState("");
    const [patientId, setPatientId] = useState("");
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [patientData, setPatientData] = useState(props.patientData);
    const [isLoading, setIsLoading] = useState(false);
    const [diagnosis, setDiagnosis] = useState("");
    const [physician, setPhysician] = useState("");
    const [downloadFileName, setDownloadFileName] = useState("");

    const [downloadFileURL, setDownloadFileURL] = useState('');
    const refDownload = useRef(null);

    useEffect(() => {
        setPatientData(props.patientData)
        if (patientData) {
            setPatientName(patientData.name || "");
            setPatientId(patientData.patientId || "");
            setGender(patientData.gender || "");
            setAge(patientData.age || "");
            setBloodGroup(patientData.bloodGroup || "");
            setHeight(patientData.height || "");
            setWeight(patientData.weight || "");
            setDiagnosis(patientData.diagnosis);
            setPhysician(patientData.physician);
        }
    }, [])

    // useEffect(() => {
    //     refDownload.current?.click();
    //     setIsLoading(false);
    // }, [downloadFileURL])

    const handleInfoDialogCancel = () => {
        setPatientDetailInfoDialogActive("");
    }
    const handleInfoDialogConfirm = () => {
        handleReportGeneration();
    }
    const handleEcgReportGenerateClick = () => {
        setPatientDetailInfoDialogActive(REPORT_TYPE.ECG_REPORT)
    }
    const handleVitalReportGenerateClick = () => {
        setPatientDetailInfoDialogActive(REPORT_TYPE.VITAL_CMS_REPORT)
    }
    const handleUrlDownload = (url, name) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    }
    const handleReportGeneration = () => {
        let presentPatientData = {
            patientName: patientName,
            uhid: patientId || "-",
            age: age || "-",
            gender: gender || "-",
            height: height || "-",
            weight: weight || "-",
            bloodGroup: bloodGroup || '-',
            admissionDate: moment(patientData.admissionDate).format("YYYY/MM/DD") || "-",
            diagnosis: patientData.diagnosis || "-",
            physician: patientData.physician || "-",
            admissionTime: patientData.admissionTime || "-",
        }
        setIsLoading(true)
        if (patientDetailInfoDialogActive === REPORT_TYPE.ECG_REPORT) {
            const data = {
                bedId: props.bedId,
                hospitalData: props.hospitalData,
                patientData: presentPatientData,
                selectedWardData: props.selectedWardData
            }
            generateECGChartData(data).then((res) => {
                setDownloadFileURL(res.data.data.url);
                handleUrlDownload(res.data.data.url, "ecggraph")
                setIsLoading(false)
                console.log(res);
                setPatientDetailInfoDialogActive("");
            }).catch((err) => {
                console.log(err);

            })
        } else if (patientDetailInfoDialogActive === REPORT_TYPE.VITAL_CMS_REPORT) {
            const data = {
                startDate: moment(trendStartDate).format("YYYY/MM/DD"),
                selectedParamList: selectedParamList,
                bedId: props.bedId,
                patientSessionId: props.patientSessionId,
                hospitalData: props.hospitalData,
                patientData: presentPatientData,
                selectedWardData: props.selectedWardData
            }
            generateVitalsPdf(data).then((res) => {
                setDownloadFileURL(res.data.data.url);
                handleUrlDownload(res.data.data.url, "vitalgraph")
                setIsLoading(false)
                console.log("result ", res)
                setPatientDetailInfoDialogActive("");

            }).catch((err) => {
                console.log("error in generating vital pdf", err);
            })
        }
    }

    console.log("url", downloadFileURL);

    const handleSetParameter = (data) => {
        data.sort(function (a, b) {
            return parameterSorting.indexOf(a.id) - parameterSorting.indexOf(b.id);
        });
        console.log("trend container param select :: " + JSON.stringify(data));
        setSelectedParamList(data);
    }

    return (
        <div className='report-container'>
            {patientDetailInfoDialogActive && <ModalBackdrop />}
            {patientDetailInfoDialogActive &&
                <div className='patient-detail-info-container'>
                    <div className="report-input-container">
                        <div className="input-label">
                            Patient Name
                        </div>
                        <input
                            className="patient-name pd-input"
                            placeholder="Enter Patient Name"
                            defaultValue={patientData ? patientData.name : patientName}
                            onChange={(event) => {
                                setPatientName(event.target.value);
                            }}
                        />
                    </div>
                    <div className="report-input-container">
                        <div className="input-label patient-id-label">Patient ID</div>
                        <input
                            className="patient-id pd-input"
                            placeholder="Enter Patient Id"
                            defaultValue={patientData ? patientData.patientId : patientId}
                            onChange={(event) => {
                                setPatientId(event.target.value);
                            }}
                        />
                    </div>
                    <div className="report-input-container">
                        <div className="input-label">Gender</div>
                        <CustomSelect
                            className="gender-select"
                            handleChange={(data) => {
                                setGender(data.name);
                            }}
                            value={
                                patientData ? (
                                    patientData.gender ?
                                        (patientGenderList.filter((option) => patientData.gender === option.name)) : patientGenderList.filter((option) => gender === option.name))
                                    : patientGenderList.filter((option) => gender === option.name)}
                            options={patientGenderList}
                            placeholder='Gender'
                            backgroundColor='#333'
                            isClearable={false}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className="report-input-container">
                        <div className="input-label">Age</div>
                        <input
                            className="pd-input"
                            placeholder="Enter Age"
                            defaultValue={patientData ? patientData.age : age}
                            onChange={(event) => {
                                setAge(event.target.value);
                            }}
                        />
                    </div>
                    <div className="report-input-container">
                        <div className="input-label">Height(cm)</div>
                        <input
                            className="pd-input"
                            placeholder="Enter Height"
                            defaultValue={patientData ? patientData.height : height}
                            onChange={(event) => {
                                setHeight(event.target.value);
                            }}
                        />
                    </div>
                    <div className="report-input-container">
                        <div className="input-label patient-id-label">Weight(kg)</div>
                        <input
                            className="pd-input"
                            placeholder="Enter Weight"
                            defaultValue={patientData ? patientData.weight : weight}
                            onChange={(event) => {
                                setWeight(event.target.value);
                            }}
                        />
                    </div>
                    <div className="report-input-container">
                        <div className="input-label">Blood Group</div>
                        <CustomSelect
                            className="blood-group"
                            handleChange={(data) => setBloodGroup(data.name)}
                            value={
                                patientData ? (
                                    patientData.bloodGroup ?
                                        (BLOOD_GROUP_OPTION.filter((option) => patientData.bloodGroup === option.name)) : BLOOD_GROUP_OPTION.filter((option) => bloodGroup === option.name))
                                    : BLOOD_GROUP_OPTION.filter((option) => bloodGroup === option.name)}
                            label='PARAMETER NAME'
                            options={BLOOD_GROUP_OPTION}
                            placeholder='Blood Group'
                            backgroundColor='#333'
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        />
                    </div>
                    <div className='info-btn-container'>
                        <div className='common-info-container-btn' onClick={handleInfoDialogCancel}>Cancel</div>

                        {isLoading ?
                            <div className='disabled-btn'>
                                <div>Generating</div>
                                <div className='spinner'></div>
                            </div> :
                            <div className='common-info-container-btn confirm-info-btn' onClick={handleInfoDialogConfirm}>
                                Confirm & Print
                            </div>}
                    </div>
                </div>
            }
            <div className='vitals-report-container'>
                <div className="form-input">
                    <div className="parameter label">Parameters</div>
                    <CustomSelect
                        className="parameter-name trend-input"
                        handleChange={(data) => handleSetParameter(data)}
                        value={selectedParamList}
                        label='label'
                        options={parameterOptionList}
                        placeholder='Parameter Name'
                        isMulti={true}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        backgroundColor='#1f1f1f'
                    />
                </div>
                <div className='start-date-report-generate-container'>
                    <div className="form-input">
                        <div className="date label">Start Date</div>
                        <div className="date-container trend-input">
                            <DatePicker
                                className="trend-date"
                                onChange={(date) => setTrendStartDate(date)}
                                value={trendStartDate}
                                clearIcon={false}
                            />

                        </div>
                    </div>
                    <div className='report-btn generate-btn' onClick={handleVitalReportGenerateClick}>
                        Generate Report
                    </div>
                </div>
            </div>
            <div className='ecg-report-container'>
                <div className='report-type-header'>ECG Report</div>
                <div className='ecg-report-info'>
                    The ECG report will be generated for 8 seconds.
                </div>
                <div className='report-btn generate-btn' onClick={handleEcgReportGenerateClick}>
                    Generate  ECG Report
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    let hospitalData = state.hospitalDataReducer.hospitalData;
    let pData = state.patientDataReducer.patientData && state.patientDataReducer.patientData[ownProps.bedId];
    let selectedWardData = state.wardDetailsReducer && state.wardDetailsReducer.selectedWard;
    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;

    return {
        patientData: pData,
        patientSessionId: patientSessionId,
        hospitalData: hospitalData,
        selectedWardData: selectedWardData
    };
};
export default connect(mapStateToProps, null)(ReportContainer);;