import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAlarmHeader, PAGE_PARENT } from "../../common/constant/common-constants";
import { AlarmTable } from "../../components/Table/Alarm/AlarmTable";
import { getAlarmHistory } from "../../helper/alarm-helper";

import "./AlarmHistoryContainer.scss";

function AlarmHistoryContainer(props) {
    const [header] = useState(getAlarmHeader());
    const [data, setData] = useState([]);

    useEffect(() => {

        let reqData = {
            bedId: props.bedId,
            patientSessionId: props.patientSessionId
        }

        getAlarmHistory(reqData)
            .then((res) => {
                if (res.alarms) setData(res.alarms);
                else setData([]);
                // console.log("res: ", res);
            })
            .catch((err) => {
                setData([]);
                console.log("alarm err: ", err);
            })
    }, [props.bedId]);

    return (
        <div className="alarm-history-container">
            {props.parent === PAGE_PARENT.PATIENT_MODAL && <div className="alarm-history-header-container">
                <div className="alarm-history-text">Alarm History</div>
            </div>}
            <div className="alarm-history-data-container">
                {data && <AlarmTable data={data} header={header} />}
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {

    let alarmData = state.alarmDataReducer.alarmData
        && state.alarmDataReducer.alarmData[ownProps.bedId]
        && state.alarmDataReducer.alarmData[ownProps.bedId].alarms;

    let patientSessionId = state.patientDataReducer.patientData
        && state.patientDataReducer.patientData[ownProps.bedId]
        && state.patientDataReducer.patientData[ownProps.bedId].session_id;

    return {
        patientSessionId: patientSessionId,
        alarmData: alarmData && [...alarmData]
    };
};

export default connect(mapStateToProps, null)(AlarmHistoryContainer);