import "./ClinicalScores.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import { CHART_OPTIONS } from "../../../common/constant/common-constants";
import { CHART_CONST } from "../../../common/constant/page-constants";
import clinicalScoreInfoIcon from "../../../common/icons/svg/clinical-scores-info.svg";
import redRating from "../../../common/icons/svg/red-rating.svg";
import yellowRating from "../../../common/icons/svg/yellow-rating.svg";
import greenRating from "../../../common/icons/svg/green-rating.svg";

export function ClinicalScores(props) {
    const [headerList, setHeaderList] = useState(props.headerList);
    const [parameterList, setParameterList] = useState(props.parameterList);
    const [infoModalType, setInfoModalType] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        setHeaderList([...props.headerList]);
        setParameterList([...props.parameterList]);
        setData({ ...props.data });
    }, [props]);

    const handleChange = (type, id, key, event) => {

        let header = id;
        if (headerList.indexOf(id) > -1) {
            header = id;
            id = CHART_CONST.NEW_ID_PRED + id;
            console.log(id);
        }
        let value = event && event.target && event.target.value;

        if (value === "on") value = event.target.checked;

        if (value === undefined) return;

        // props.handleDataChange(id, header, key, value);
    }
    const handleInfoClick = (label) => {
        setInfoModalType(label);
        if (infoModalType) {
            setInfoModalType("");
        }
    }

    const getHtml = () => {

        let html = headerList && headerList.map((header, count) => {
            let headerTime = moment(header, "HH:mm");
            let currentTime = moment(new Date(), "HH:mm");
            // console.log("header", header)

            if (count === 0) {
                return (
                    <div className="column parameters" key={header}>
                        <div className="first-column-width heading param">{header}</div>
                        {parameterList.map((param) => {
                            // console.log("parameter list", param);
                            return (
                                <div className="clinical-score-cell param" key={param.label}>
                                    <div className="cell-param-header" key={param.label}>
                                        <div className="cell-label">{param.label}</div>
                                        <img src={clinicalScoreInfoIcon} className="info-icon" onClick={() => handleInfoClick(param.label)} />
                                        {infoModalType === "SOFA" &&
                                            <div className="sofa-info-score-container default-container">
                                                <div className="common-info-content">
                                                    <div className="common-modal">
                                                        <img src={redRating} />
                                                        <span>High Risk:</span>
                                                    </div>
                                                    <div className="common-modal">
                                                        <img src={yellowRating} />
                                                        <span>Mid Risk: </span>
                                                    </div>
                                                    <div className="common-modal">
                                                        <img src={greenRating} />
                                                        <span>Low Risk:</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {infoModalType === "GCS" &&
                                            <div className="gcs-info-score-container default-container">
                                                <div className="common-info-content">
                                                    <div className="common-modal">
                                                        <img src={redRating} />
                                                        <span>High Risk: 0 - 8</span>
                                                    </div>
                                                    <div className="common-modal">
                                                        <img src={yellowRating} />
                                                        <span>Mid Risk: 9 - 12</span>
                                                    </div>
                                                    <div className="common-modal">
                                                        <img src={greenRating} />
                                                        <span>Low Risk: 13 - 15</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className={`show-score`}
                                        // ${totalSOFAScore <= 3 ? "red-score" : ""}
                                        // ${(totalSOFAScore <= 8) && (totalSOFAScore > 3) ? "yellow-score" : ""}
                                        // ${totalSOFAScore > 8 ? "green-score" : ""}`}
                                        >
                                            Score
                                        </div>
                                    </div>
                                    <span className="update-info">Updated at 4:30 PM by Nurse. XYZ (N15)</span>
                                </div>
                            )
                        })
                        }
                    </div >
                )
            } else {

                let col = data && data.data &&
                    data.data.filter((obj) => moment(obj.time, "HH:mm").isSame(headerTime))[0];
                // console.log("data", data)

                // if (col) {
                //     console.log("col", col)
                return (
                    <div className="column" key={header}>
                        <div className=" cell-width heading" key={header}>{header}</div>
                        {parameterList.map((param) => {
                            // console.log("param", param);
                            return (
                                <div className="clinical-score-cell body" key={param.name}>
                                    <input
                                        className="chart-input"
                                        // value={col[param.name]}
                                        readOnly={true}
                                    // onChange={(event) => handleChange(CHART_CONST.UPDATE, col["id"], param.name, event)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
                // }
            }
        })
        return html;
    }


    return (
        <div className="table-clinical-score">
            {/* {console.log("retsfasdasd")} */}
            {getHtml()}
        </div>
    );
}

// export default ClinicalScores;