import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

// const initialState = {
//     deviceVitalData: {},
//     deviceEcgWaveform: {},
//     deviceHrWaveform: {}
// }

// action.payload = {
//     "bedId": "b-1234",
//     "dongleId": "d-35678",
//     "deviceType": "Monitor",
//     "deviceId": "m-1234",
//     "vitals": [{
//         "name": "spo2",
//         "id": "1234",
//         "unit": "b/min",
//         "data": [{
//             "value": "90",
//             "time": "7594684345234254"
//         }, {
//             "value": "92",
//             "time": "7594684345234254"
//         }]
//     }, {
//         "name": "temp",
//         "id": "1234",
//         "unit": "b/min",
//         "data": [{
//             "value": "90",
//             "time": "7594684345234254"
//         }, {
//             "value": "92",
//             "time": "7594684345234254"
//         }]
//     }],
// }

// let stateData = {
//     "bed1": {
//         "Monitor": {
//             "id": "1234",
//             "vitals": [{

//             }]
//         },
//         "Ventilator": {
//             ...data
//         }
//     },
//     "bed2": {
//         "monitor": {

//         },
//     }
// }

export const deviceVitalDataReducer = (state = initialState.deviceVitalDataReducer, action) => {

    switch (action.type) {
        case Types.DEVICE_VITAL_DATA: {

            let bedId = action.payload.bedId;
            let patientMonitorId = action.payload.deviceId;
            let dongleId = action.payload.dongleId;
            let deviceType = action.payload.deviceType && action.payload.deviceType.toUpperCase();
            let vitals = action.payload.vitals;

            if (!bedId || !patientMonitorId || !vitals) {
                return {
                    ...state
                }
            };

            let deviceVitalData = state.deviceVitalData;

            deviceVitalData[bedId] = {
                ...deviceVitalData[bedId],
                dongleId: dongleId,
                bedId: bedId,
                [deviceType]: {
                    patientMonitorId: patientMonitorId,
                    deviceType: deviceType,
                    vitals: vitals
                }
            }

            return {
                ...state,
                deviceVitalData: { ...deviceVitalData }
            }
        }
        case Types.DEVICE_ECG_WAVEFORM_DATA: {

            if (!action.payload) return state;
            // console.log(action.payload);
            let bedId = action.payload.bedId;
            let patientMonitorId = action.payload.deviceId;
            let dongleId = action.payload.dongleId;
            let deviceType = action.payload.deviceType && action.payload.deviceType.toUpperCase();
            let waveform = action.payload.waveform;

            if (!bedId || !patientMonitorId || !waveform) return;
            let deviceEcgWaveform = state.deviceEcgWaveform || {};

            // console.log(deviceWaveform);
            let stateWaveform = (deviceEcgWaveform
                && deviceEcgWaveform[bedId]
                && deviceEcgWaveform[bedId][deviceType]
                && deviceEcgWaveform[bedId][deviceType]['waveform']);

            deviceEcgWaveform[bedId] = {
                ...deviceEcgWaveform[bedId],
                dongleId: dongleId,
                bedId: bedId,
                [deviceType]: {
                    patientMonitorId: patientMonitorId,
                    deviceType: deviceType,
                    waveform: [...waveform]
                }
            }
            // if (stateWaveform) {
            //     if (waveform) {
            //         waveform.map((vitalWaveform) => {

            //             let name = vitalWaveform.name;
            //             let stateIndex = stateWaveform.findIndex((element) => element.name === name);

            //             if (stateIndex < 0) {
            //                 stateWaveform.push(vitalWaveform);
            //             } else {
            //                 stateWaveform[stateIndex] = vitalWaveform;
            //             }

            //             deviceEcgWaveform[bedId] = {
            //                 ...deviceEcgWaveform[bedId],
            //                 dongleId: dongleId,
            //                 bedId: bedId,
            //                 [deviceType]: {
            //                     patientMonitorId: patientMonitorId,
            //                     deviceType: deviceType,
            //                     waveform: stateWaveform
            //                 }
            //             }
            //         })
            //     }
            // } else {
            //     deviceEcgWaveform[bedId] = {
            //         ...deviceEcgWaveform[bedId],
            //         dongleId: dongleId,
            //         bedId: bedId,
            //         [deviceType]: {
            //             patientMonitorId: patientMonitorId,
            //             deviceType: deviceType,
            //             waveform: waveform
            //         }
            //     }
            // }

            return {
                ...state,
                deviceEcgWaveform: { ...deviceEcgWaveform }
            }
        }
        case Types.DEVICE_HR_WAVEFORM_DATA: {

            if (!action.payload) return state;
            // console.log(action.payload);
            let bedId = action.payload.bedId;
            let patientMonitorId = action.payload.deviceId;
            let dongleId = action.payload.dongleId;
            let deviceType = action.payload.deviceType && action.payload.deviceType.toUpperCase();
            let waveform = action.payload.waveform;

            if (!bedId || !patientMonitorId || !waveform) return;
            let deviceHrWaveform = state.deviceHrWaveform || {};

            // console.log(deviceWaveform);
            let stateWaveform = (deviceHrWaveform
                && deviceHrWaveform[bedId]
                && deviceHrWaveform[bedId][deviceType]
                && deviceHrWaveform[bedId][deviceType]['waveform']);

            deviceHrWaveform[bedId] = {
                ...deviceHrWaveform[bedId],
                dongleId: dongleId,
                bedId: bedId,
                [deviceType]: {
                    patientMonitorId: patientMonitorId,
                    deviceType: deviceType,
                    waveform: [...waveform]
                }
            }
            // if (stateWaveform) {
            //     // if (waveform) {
            //     //     waveform.map((vitalWaveform) => {

            //     //         let name = vitalWaveform.name;
            //     //         let stateIndex = stateWaveform.findIndex((element) => element.name === name);

            //     //         if (stateIndex < 0) {
            //     //             stateWaveform.push(vitalWaveform);
            //     //         } else {
            //     //             stateWaveform[stateIndex] = vitalWaveform;
            //     //         }

            //     //         deviceHrWaveform[bedId] = {
            //     //             ...deviceHrWaveform[bedId],
            //     //             dongleId: dongleId,
            //     //             bedId: bedId,
            //     //             [deviceType]: {
            //     //                 patientMonitorId: patientMonitorId,
            //     //                 deviceType: deviceType,
            //     //                 waveform: stateWaveform
            //     //             }
            //     //         }
            //     //     })
            //     // }

            //     deviceHrWaveform[bedId] = {
            //         ...deviceHrWaveform[bedId],
            //         dongleId: dongleId,
            //         bedId: bedId,
            //         [deviceType]: {
            //             patientMonitorId: patientMonitorId,
            //             deviceType: deviceType,
            //             waveform: waveform
            //         }
            //     }
            // } else {
            //     deviceHrWaveform[bedId] = {
            //         ...deviceHrWaveform[bedId],
            //         dongleId: dongleId,
            //         bedId: bedId,
            //         [deviceType]: {
            //             patientMonitorId: patientMonitorId,
            //             deviceType: deviceType,
            //             waveform: waveform
            //         }
            //     }
            // }

            return {
                ...state,
                deviceHrWaveform: { ...deviceHrWaveform }
            }
        }
        default:
            return state;
    }
}

