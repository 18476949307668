import { getAlarmHistoryAPI } from "../adapter/cms-adapter";

export const getAlarmHistory = (data) => {

    let reqData = {
        bedId: data.bedId,
        patientSessionId: data.patientSessionId,
    }

    let promise = new Promise(function (resolve, reject) {

        getAlarmHistoryAPI(reqData)
            .then((res) => {

                if (res && res.data && res.data.data) resolve(res.data.data);
                else reject("some error message getAlarmHistory");
            }).catch((err) => {
                reject("Error in Alarms");
            })
    })

    return promise;
}