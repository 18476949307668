import moment from "moment";

// export const isBetweenTime = (time, startTime, endTime) => {

//     let timeFormat = 'HH:mm:ss';

//     let formattedTime = moment(time, format);
//     let beforeTime = moment(startTime, format);
//     let afterTime = moment(endTime, format);

//     if (time.isBetween(beforeTime, afterTime)) {

//         console.log('is between')

//     }
// }

export const getDateForChart = () => {

    let dateFormat = "YYYY-MM-DD";
    let timeFormat = 'HH:mm:ss';

    let date = moment(new Date(), dateFormat);

    let time = moment(new Date(), timeFormat);
    var beginningTime = moment('07:00:00', timeFormat);

    if (time.isBefore(beginningTime)) {
        // date = date -1;
        date = moment().subtract(1, "days");
    }

    return moment(date, dateFormat).toDate();
}