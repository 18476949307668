
import { Types } from "./actionTypes";

export const bedDetailAction = (task) => {

    return {
        type: Types.BED_DETAILS,
        payload: task
    }
}

export const bedIdAction = (task) => {

    return {
        type: Types.BED_ID_ARRAY,
        payload: task
    }
}

export const hospitalDataAction = (task) => {

    return {
        type: Types.HOSPITAL_DATA,
        payload: task
    }
}

export const wardDetailsAction = (task) => {

    return {
        type: Types.WARD_DATA,
        payload: task
    }
}

export const patientDataAction = (task) => {

    return {
        type: Types.PATIENT_DATA,
        payload: task
    }
}
export const patienDischargeAction = (task) => {
    return {
        type: Types.PATIENT_DISCHARGE,
        payload: task
    }
}

export const deviceVitalDataAction = (task) => {

    return {
        type: Types.DEVICE_VITAL_DATA,
        payload: task
    }
}


export const deviceEcgWaveformDataAction = (task) => {

    return {
        type: Types.DEVICE_ECG_WAVEFORM_DATA,
        payload: task
    }
}

export const deviceHrWaveformDataAction = (task) => {

    return {
        type: Types.DEVICE_HR_WAVEFORM_DATA,
        payload: task
    }
}

export const alarmDataAction = (task) => {

    return {
        type: Types.ALARM_DATA,
        payload: task
    }
}

export const notificationDataAction = (task) => {

    return {
        type: Types.NOTIFICATION_DATA,
        payload: task
    }
}

export const viewTypeAction = (task) => {

    return {
        type: Types.CONFIG_VIEW_TYPE,
        payload: task
    }
}

export const filterAction = (task) => {

    return {
        type: Types.CONFIG_FILTER_TYPE,
        payload: task
    }
}

export const modalAction = (task) => {

    return {
        type: Types.CONFIG_MODAL,
        payload: task
    }
}

export const favoriteBedAction = (task) => {

    return {
        type: Types.FAVORITE_BED_ID_ARRAY,
        payload: task
    }
}

export const bedDetailConfigAction = (task) => {

    return {
        type: Types.CONFIG_BED_DETAILS,
        payload: task
    }
}

export const addColumnBedListAction = (task) => {

    return {
        type: Types.CONFIG_ADD_COLUMN,
        payload: task
    }
}

export const removeColumnBedListAction = (task) => {

    return {
        type: Types.CONFIG_REMOVE_COLUMN,
        payload: task
    }
}

export const clearAlarms = () => {

    return {
        type: Types.CLEAR_ALARM,
        payload: null
    }
}

export const clearBedAlarm = (task) => {
    return {
        type: Types.CLEAR_BED_ALARM,
        payload: task,
    }
}

export const clearStore = () => {

    return {
        type: Types.CLEAR_STORE,
        payload: null
    }
}

export const selectedBedAction = (task) => {
    return {
        type: Types.SELECTED_BED,
        payload: task
    }
}

export const selectedWardAction = (task) => {
    return {
        type: Types.SELECTED_WARD,
        payload: task
    }
}