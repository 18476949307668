import { combineReducers } from 'redux';
import { alarmDataReducer } from './alarm-data-reducer';
import { bedDetailsReducer } from "./bed-details-reducer";
import { configReducer } from './config-reducer';
import { deviceVitalDataReducer } from './device-vital-data-reducer';
import { notificationDataReducer } from './notification-data-reducer';
import { patientDataReducer } from './patient-data-reducer';
import { wardDetailsReducer } from './ward-details-reducer';
import { hospitalDataReducer } from './hospital-data-reducer';
import storage from 'redux-persist/lib/storage';
import { Types } from '../actions/actionTypes';

const appReducer = combineReducers({

    bedDetailsReducer: bedDetailsReducer,
    wardDetailsReducer: wardDetailsReducer,
    configReducer: configReducer,
    patientDataReducer: patientDataReducer,
    deviceVitalDataReducer: deviceVitalDataReducer,
    alarmDataReducer: alarmDataReducer,
    notificationDataReducer: notificationDataReducer,
    hospitalDataReducer: hospitalDataReducer
});

export const rootReducer = (state, action) => {
    if (action.type === Types.CLEAR_STORE) {
        // for all keys defined in your persistConfig(s)

        localStorage.removeItem("hospitalId");
        storage.removeItem('persist:cms-root')

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};