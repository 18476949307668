import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

// let initialState = {
//     bedDetails: [],
//     bedIdArray: [],
//     favoriteBedIdArray: []
// }

export const bedDetailsReducer = (state = initialState.bedDetailsReducer, action) => {
    switch (action.type) {
        case Types.BED_DETAILS:
            {
                let bedId = action.payload.bedId;
                let data = action.payload;

                if (!bedId) return;

                let bedDetails = state.bedDetails || {};
                bedDetails[bedId] = data;

                return {
                    ...state,
                    bedDetails: { ...bedDetails }
                };
            }
        case Types.BED_ID_ARRAY:
            {
                const bedId = action.payload.bedId;
                let bedIdArray = state.bedIdArray || [];

                if (bedIdArray.indexOf(bedId) < 0) {
                    bedIdArray.push(bedId);
                    bedIdArray = [...bedIdArray]
                }

                return {
                    ...state,
                    bedIdArray
                }
            }
        case Types.FAVORITE_BED_ID_ARRAY:
            {
                const favBedArray = action.payload || state.favoriteBedIdArray;

                return {
                    ...state,
                    favoriteBedIdArray: favBedArray
                }
            }
        case Types.SELECTED_BED:
            {
                const selectedBed = action.payload;
                return {
                    ...state,
                    selectedBed: selectedBed,
                }
            }
        case Types.BED_DATA:
            {
                let bedId = action.payload.bedId;
                let bedName = action.payload.bedName;

                if (!bedId || !bedName) return;
                let bedData = state.bedData;
                bedData.push({ bedId: bedId, bedName: bedName });
                return {
                    ...state,
                    bedData: [...bedData],
                }

            }
        default:
            return state;
    }
}