import React, { memo } from "react";
import redCircleSvg from "../../common/icons/svg/red-circle-small.svg";
import greenCircleSvg from "../../common/icons/svg/green-circle-small.svg";
import yellowCircleSvg from "../../common/icons/svg/yellow-circle-small.svg";

import "./SubHeader.scss";

function SubHeader(props) {
    return (
        <div className='ward-detail-container'>
            {console.log("rerendering SubHeader !!!!")}
            <div className='ward-bed-details'>
                <div className='ward-name'>
                    {(props.data && props.data.wardName) || 'Ward Name'}
                </div>
                <div className='bed-details'>
                    <div className='total-bed-container bed-box'>
                        <div className='bed-heading'>Total Beds</div>
                        <div className='bed-data'>
                            {(props.data && props.data.data && props.data.data.bedDetails
                                && props.data.data.bedDetails.totalBeds)
                                || 'NA'}
                        </div>
                    </div>
                    <div className='occupied-bed-container bed-box'>
                        <div className='bed-heading'>Beds Occupied</div>
                        <div className='bed-data'>
                            {(props.data && props.data.data && props.data.data.bedDetails
                                && props.data.data.bedDetails.bedOccupied)
                                || 'NA'}
                        </div>
                    </div>
                    <div className='available-bed-container bed-box'>
                        <div className='bed-heading'>Beds Available</div>
                        <div className='bed-data'>
                            {(props.data && props.data.data && props.data.data.bedDetails
                                && props.data.data.bedDetails.bedAvailable)
                                || 'NA'}
                        </div>
                    </div>
                </div>
            </div>
            <div className='ward-ews-score'>
                <div className='ward-name'>
                    EWS
                </div>
                <div className='bed-details'>
                    <div className='total-bed-container bed-box'>
                        <div className='bed-heading'>
                            <img src={redCircleSvg} alt="critical circle" />
                            <span className='ews-type'>Critical</span>
                        </div>
                        <div className='bed-data'>
                            {(props.data && props.data.data && props.data.data.ewsDetails
                                && props.data.data.ewsDetails.critical)
                                || 'NA'}
                        </div>
                    </div>
                    <div className='occupied-bed-container bed-box'>
                        <div className='bed-heading'>
                            <img src={yellowCircleSvg} alt="yellow circle" />
                            <span className='ews-type'>Mild Risk</span>
                        </div>
                        <div className='bed-data'>
                            {(props.data && props.data.data && props.data.data.ewsDetails
                                && props.data.data.ewsDetails.mildRisk)
                                || 'NA'}
                        </div>
                    </div>
                    <div className='available-bed-container bed-box'>
                        <div className='bed-heading'>
                            <img src={greenCircleSvg} alt="green circle" />
                            <span className='ews-type'>No Risk</span>
                        </div>
                        <div className='bed-data'>
                            {(props.data && props.data.data && props.data.data.ewsDetails
                                && props.data.data.ewsDetails.noRisk)
                                || 'NA'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(SubHeader);