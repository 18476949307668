import { PAGE_CONST, CLINICAL_SCORE } from "../constant/page-constants";

export const redirect = (navigate, page, data = {}) => {

    switch (page) {
        case PAGE_CONST.LOGIN:
            navigate('/login');
            break;
        case PAGE_CONST.HOME_PAGE:
            navigate('/home-page');
            break;
        case PAGE_CONST.BED_DETAILS:
            navigate(`/bed-details/${data.bedId}`)
            break;
        case CLINICAL_SCORE.GCS_SCORE:
            navigate(`/gcs-score/${data.bedId}`)
            break;
        case CLINICAL_SCORE.SOFA_SCORE:
            navigate(`/sofa-score/${data.bedId}`)
            break;
    }
}